import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { Chip } from '../Chip';
import moment from 'moment';

interface LastUpdatedChipProps {
  reportStatus: ReportStatusEnum;
  reportDate?: string;
}

export const LastUpdatedChip: React.FC<LastUpdatedChipProps> = ({ reportStatus, reportDate }) => {
  if (reportStatus === ReportStatusEnum.COMPLETED && reportDate) {
    return (
      <div className='relative inline-block'>
        <div className='absolute inset-0 rounded-md bg-gradient-to-r from-[#89adfa] to-[#ffffff] to-70% shadow-sm'></div>
        <div className='relative m-[1px] bg-[#F8F9FC] rounded-md'>
          <Chip
            className='whitespace-nowrap text-[16px] !py-1.5 !px-4 font-medium text-[#1E2875]'
            name={`Last Updated Date: ${moment(reportDate)
              .format('DD MMMM YYYY')
              .replace(/(\d+)(?:st|nd|rd|th)/, '$1')}`}
          />
        </div>
      </div>
    );
  }
  return null;
};
