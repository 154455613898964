import React from 'react';
import { MonthlyDelayData } from '@app/types/highlights';
import { MONTHS } from '@components/utils/constants';
import {
  RiskBadge,
  getRiskTypeFromCategory,
} from '@components/lib-components/risk-badge/RiskBadge';

interface MonthlyDelayGridProps {
  monthData: MonthlyDelayData[];
}

export const MonthlyDelayGrid: React.FC<MonthlyDelayGridProps> = ({ monthData }) => {
  const sortedMonths = [...monthData].sort(
    (a, b) => MONTHS.indexOf(b.month) - MONTHS.indexOf(a.month),
  );

  return (
    <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4'>
      {sortedMonths.map((month) => (
        <div key={month.month} className='border rounded-lg p-3'>
          <div className='flex justify-between items-center mb-2'>
            <span className='font-medium'>{month.month}</span>
            <RiskBadge type={getRiskTypeFromCategory(month.risk_category)} className='!px-2' />
          </div>
          <div className='text-sm text-gray-600'>Delay: {month.delay_days} days</div>
        </div>
      ))}
    </div>
  );
};
