import React, { ReactNode, useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { useOutsideClick } from '@app/hooks/useOutSideClick';

type PlacesType =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

interface TooltipProps {
  content: string | ReactNode;
  children?: ReactNode;
  place?: PlacesType;
  className?: string;
}

export const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  place = 'top',
  className,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLSpanElement>(null);
  const containerRef = useOutsideClick<HTMLDivElement>(() => {
    if (isClicked) {
      setIsClicked(false);
      setIsVisible(false);
    }
  });

  useEffect(() => {
    const updateTooltipPosition = () => {
      if ((isVisible || isClicked) && tooltipRef.current && triggerRef.current) {
        const triggerRect = triggerRef.current.getBoundingClientRect();
        const tooltipRect = tooltipRef.current.getBoundingClientRect();

        let top = 0;
        let left = 0;

        switch (place) {
          case 'top':
            top = triggerRect.top - tooltipRect.height - 10;
            left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;
            break;
          case 'top-right':
            top = triggerRect.top - tooltipRect.height - 10;
            left = triggerRect.right - tooltipRect.width - 10;
            break;
          case 'top-left':
            top = triggerRect.top - tooltipRect.height - 10;
            left = triggerRect.left - tooltipRect.width - 10;
            break;
          case 'bottom':
            top = triggerRect.bottom + 10;
            left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;
            break;
          case 'bottom-left':
            top = triggerRect.bottom + 10;
            left = triggerRect.right - tooltipRect.width - 70;
            break;
          case 'bottom-right':
            top = triggerRect.bottom + 10;
            left = triggerRect.right + 10;
            break;
          case 'left':
            top = triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2;
            left = triggerRect.left - tooltipRect.width - 10;
            break;
          case 'right':
            top = triggerRect.top + triggerRect.height / 2 - tooltipRect.height / 2;
            left = triggerRect.right + 10;
            break;
        }

        tooltipRef.current.style.top = `${window.scrollY + top}px`;
        tooltipRef.current.style.left = `${window.scrollX + left}px`;
      }
    };

    if (isVisible || isClicked) {
      updateTooltipPosition();
      window.addEventListener('scroll', updateTooltipPosition);
      window.addEventListener('resize', updateTooltipPosition);
    }

    return () => {
      window.removeEventListener('scroll', updateTooltipPosition);
      window.removeEventListener('resize', updateTooltipPosition);
    };
  }, [isVisible, isClicked, place]);

  const handleTriggerClick = () => {
    if (className?.includes('!text-gray-700')) {
      setIsClicked(!isClicked);
      setIsVisible(!isClicked);
    }
  };

  const handleMouseEnter = () => {
    if (!isClicked) {
      setIsVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isClicked) {
      setIsVisible(false);
    }
  };

  return (
    <div ref={containerRef}>
      <span
        ref={triggerRef}
        onClick={handleTriggerClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </span>
      {(isVisible || isClicked) && (
        <div
          ref={tooltipRef}
          className={classNames(
            'fixed z-[9999] bg-gray-800 text-white p-2 rounded text-xs font-normal',
            className,
          )}
          style={{ pointerEvents: className?.includes('!text-gray-700') ? 'auto' : 'none' }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {content}
        </div>
      )}
    </div>
  );
};
