import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import { apiHandler } from '@app/config/apiHandler';

interface ErrorResponse {
  detail: string;
}

export interface AxiosCustomError {
  status: number | undefined;
  data: ErrorResponse;
  code: string | undefined;
  statusCode: number | undefined;
}

export function axiosBaseQuery(): BaseQueryFn<
  {
    url: string;
    method?: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
  },
  unknown,
  AxiosCustomError
> {
  return async function ({ url, method = 'get', data, params }) {
    try {
      const result = await apiHandler({ url, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      const errorData = (err.response?.data as ErrorResponse) || { detail: err.message };

      const customError: AxiosCustomError = {
        status: err.response?.status,
        data: errorData,
        code: err.code,
        statusCode: err.response?.status,
      };

      return { error: customError };
    }
  };
}
