import React, { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import Icon from '../icon/Icon';
import Badge from '../badge/Badge';
import { Status } from '@app/types/charges';
import CARE from '@app/assets/CareEdgeIcon.svg';
import ICRA from '@app/assets/IcraIcon.png';
import CRISIL from '@app/assets/CrisilIcon.png';
import INDIA from '@app/assets/IndianRatingsIcon.png';
import BRICKWORK from '@app/assets/brickworkrating-logo.jpg';
import INFOMERICS from '@app/assets/infomerics.png';

const iconMapping: Record<string, string> = {
  CARE,
  ICRA,
  CRISIL,
  INDIA,
  BRICKWORK,
  INFOMERICS,
};

interface AccordionProps {
  title: string | ReactElement;
  subtitle?: string;
  children: ReactNode | ReactElement;
  icon?: ReactElement | keyof typeof iconMapping | null;
  iconFlag?: ReactNode | null;
  isOpen: boolean;
  onToggle?: () => void;
  status?: Status | 'Active';
  count?: number;
  className?: string;
  childrenClassName?: string;
  titleClassName?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  subtitle,
  children,
  icon,
  iconFlag,
  isOpen,
  onToggle,
  status,
  count,
  className,
  childrenClassName,
  titleClassName,
}) => {
  const IconComponent = icon ? iconMapping[icon as keyof typeof iconMapping] : null;

  return (
    <div className={classNames('border rounded-md border-gray-200 w-full mt-3', className)}>
      <div
        className='flex items-center justify-between py-3 px-4 cursor-pointer border-b'
        onClick={onToggle}
      >
        <div className='flex items-center flex-grow'>
          <span className='mr-3'>
            <Icon
              icon={isOpen ? 'MinusCircleIcon' : 'PlusCircleIcon'}
              variant='outline'
              size={20}
              color='#98a2b2'
            />
          </span>
          <div className='flex items-center'>
            <h3
              className={classNames('text-md font-medium text-secondary-700 py-1', titleClassName)}
            >
              {title}
            </h3>
            {count !== undefined && count !== null && (
              <span className='ml-2 px-2 py-0.5 text-xs font-medium bg-white text-gray-600 rounded-md border border-gray-200'>
                {count}
              </span>
            )}
            {subtitle && <p className='text-sm text-gray-500 pl-2'>{subtitle}</p>}
          </div>
        </div>
        <div className='flex items-center'>
          {status && <Badge status={status} />}
          {IconComponent ? (
            <img src={IconComponent} width={70} alt={`${title} icon`} className='ml-2' />
          ) : (
            iconFlag
          )}
        </div>
      </div>
      {isOpen && (
        <div className={classNames('p-4 overflow-auto', childrenClassName)}>{children}</div>
      )}
    </div>
  );
};

export default Accordion;
