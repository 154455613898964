import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { Metadata } from '@app/types/financial';

export interface ShareholdingSummary {
  num_of_equity_shares_authorised_capital: number;
  num_of_equity_shares_issued_capital: number;
  num_of_equity_shares_subscribed_capital: number;
  num_of_equity_shares_paid_up_capital: number;

  amount_of_equity_shares_authorised_capital: number;
  amount_of_equity_shares_issued_capital: number;
  amount_of_equity_shares_subscribed_capital: number;
  amount_of_equity_shares_paid_up_capital: number;

  num_promoters_beginning_of_year: number;
  num_promoters_end_of_year: number;
  num_debenture_holders_beginning_of_year: number;
  num_debenture_holders_end_of_year: number;
  members_not_promoters_beginning_of_year: number;
  members_not_promoters_end_of_year: number;

  document_id: string;
  entity_id: string;
  financial_year: string;
  id: string;
  created_at: string;
  updated_at: string;

  total_promoter_equity_shares: number;
  total_public_equity_shares: number;
  total_equity_shares: number;
  total_preference_shares: number;

  COMPUTED_total_equity_shares: number;
  COMPUTED_percentage_promoter_equity_shares: number;
  COMPUTED_percentage_public_equity_shares: number;
  COMPUTED_value_per_share_authorised_capital: number;
  COMPUTED_value_per_share_paid_up_capital: number;
  COMPUTED_total_num_members: number;
}

interface BaseShareholding {
  id: string | null;
  year: string | null;
  financial_year: string | null;
  full_name: string | null;
  num_shares: number | null;
  percentage_held: number | null;
  notes: string | null;
  country: string | null;
}

export interface DirectorShareholding extends BaseShareholding {
  designation: string | null;
  din_pan: string | null;
  date_of_cessation: string | null;
}

export interface CompanyShareholding extends BaseShareholding {
  cin_llpin: string | null;
}

export interface IndividualShareholding extends BaseShareholding {
  cin_llpin: string | null;
  pan: string | null;
}

export interface OtherShareholding extends BaseShareholding {}

export interface ShareholdingResponse<T> {
  data: T | null;
  metadata: Metadata | null;
}

export type ShareholdingJVResponse = ShareholdingResponse<JointVenturesData>;
export type ShareholdingStructureResponse = ShareholdingResponse<ShareholdingStructureData>;
export type ShareholdingPatternSummaryResponse = ShareholdingResponse<ShareholdingSummary>;
export type CompanyShareholdingsResponse = ShareholdingResponse<CompanyShareholding[]>;
export type IndividualShareholdingsResponse = ShareholdingResponse<IndividualShareholding[]>;
export type OtherShareholdingsResponse = ShareholdingResponse<OtherShareholding[]>;
export type DirectorShareholdingsResponse = ShareholdingResponse<DirectorShareholding[]>;

export type ShareholdingTypes =
  | CompanyShareholdingsResponse
  | IndividualShareholdingsResponse
  | OtherShareholdingsResponse
  | DirectorShareholdingsResponse
  | ShareholdingStructureResponse
  | ShareholdingPatternSummaryResponse
  | ShareholdingJVResponse
  | SecuritiesAllotment;

export interface ShareholdingsSummaryResponse {
  joint_ventures: ShareholdingJVResponse | null;
  associate_entities: ShareholdingJVResponse | null;
  subsidiary_entities: ShareholdingJVResponse | null;
  holding_entities: ShareholdingJVResponse | null;
  shareholdings: ShareholdingStructureResponse | null;
  pattern_summary: ShareholdingPatternSummaryResponse | null;
  director_shareholdings: DirectorShareholdingsResponse | null;
  individual_shareholdings: IndividualShareholdingsResponse | null;
  company_shareholdings: CompanyShareholdingsResponse | null;
  other_shareholdings: OtherShareholdingsResponse | null;
  securities_allotment?: SecuritiesAllotment[];
}

export interface JointVentureCompany {
  cin_or_fcrn: string;
  jv_holding_subsidiary_company_name: string;
  percentage_held: number;
}

export interface JointVenturesData {
  llp: JointVentureCompany[] | null;
  company: JointVentureCompany[] | null;
  others: JointVentureCompany[] | null;
}

// New types for LLP Contribution Summary
export interface SummaryDesignatedPartners {
  category: string;
  number_of_partners: number;
  indian_designated_partners: number;
  other_designated_partners: number;
}

export interface IndividualPartner {
  partner_type: string;
  partner_id: string;
  id_type: string;
  name: string | null;
  designation: string;
  obligation_contribution: number;
  received_contribution: number;
  nominee_id: string | null;
  nominee_id_type: string | null;
  nominee_name: string | null;
  category: string | null;
  nationality: string;
  resident_indian: boolean;
  document_id: string;
  entity_id: string;
  id: string;
  created_at: string;
  updated_at: string;
}

export interface BodyCorporatePartner {
  partner_id: string | null;
  id_type: string | null;
  name: string | null;
  designation: string;
  obligation_contribution: number;
  received_contribution: number;
  nominee_id: string | null;
  nominee_id_type: string | null;
  nominee_name: string | null;
  category: string;
  resident_indian: boolean;
  nationality: string | null;
}

export interface ContributionResponse<T> {
  data: T;
  metadata: Metadata;
}

export interface ContributionSummary {
  individual_partners: ContributionResponse<IndividualPartner[]>;
  body_corporate_partners: ContributionResponse<BodyCorporatePartner[]>;
  summary_designated_partners: ContributionResponse<SummaryDesignatedPartners[]>;
}

export interface ShareholdingData {
  shareholdings_summary?: ShareholdingsSummaryResponse;
  contribution_summary?: ContributionSummary;
  status: ReportStatusEnum;
  report_date: string;
  refresh_available: boolean;
}

export interface ShareholdingStructure {
  num_of_shares_by_indian: number;
  num_of_shares_by_nri: number;
  num_of_shares_by_foreign: number;
  num_of_shares_by_central_government: number;
  num_of_shares_by_state_government: number;
  num_of_shares_by_government_companies: number;
  num_of_shares_by_insurance_companies: number;
  num_of_shares_by_banks: number;
  num_of_shares_by_financial_institutions: number;
  num_of_shares_by_foreign_investors: number;
  num_of_shares_by_mutual_funds: number;
  num_of_shares_by_vc: number;
  num_of_shares_by_body_corporate: number;
  num_of_shares_by_others: number;
  total_num_of_shares: number;
  perc_of_shares_by_indian: number;
  perc_of_shares_by_nri: number;
  perc_of_shares_by_foreign: number;
  perc_of_shares_by_central_government: number;
  perc_of_shares_by_state_government: number;
  perc_of_shares_by_government_companies: number;
  perc_of_shares_by_insurance_companies: number;
  perc_of_shares_by_banks: number;
  perc_of_shares_by_financial_institutions: number;
  perc_of_shares_by_foreign_investors: number;
  perc_of_shares_by_mutual_funds: number;
  perc_of_shares_by_vc: number;
  perc_of_shares_by_body_corporate: number;
  perc_of_shares_by_others: number;
  total_perc_shares: number;

  document_id: string;
  entity_id: string;
  financial_year: string;
  nature_of_owner: 'Promoter' | 'Public';
  type_of_shares: 'Equity' | 'Preference';
  id: string;
  created_at: string;
  updated_at: string;
}

export interface ShareholdingStructureData {
  shareholding_structure_promoter_equity: ShareholdingStructure;
  shareholding_structure_public_equity: ShareholdingStructure;
  shareholding_structure_promoter_preference: ShareholdingStructure;
  shareholding_structure_public_preference: ShareholdingStructure;
}

export enum ShareholdingSectionId {
  SUMMARY = 'summary',
  DIRECTOR = 'director-shareholdings',
  INDIVIDUAL = 'individual-shareholdings',
  COMPANY = 'company-shareholdings',
  OTHER = 'other-shareholdings',
  STRUCTURE = 'shareholding-structure',
  JOINT_VENTURES = 'joint-ventures',
  ASSOCIATE = 'associate-entities',
  SUBSIDIARY = 'subsidiary-entities',
  HOLDING = 'holding-entities',
  SECURITIES_ALLOTMENT = 'securities-allotment',
}

export interface SecuritiesAllotmentData {
  type: string;
  allotment_type: string;
  date_of_allotment: string;
  number_of_securities_allotted: number;
  nominal_amount_per_security: number;
  premium_amount_per_security_due_and_payable: number;
  total_amount: number;
}

export interface SecuritiesAllotment {
  data: SecuritiesAllotmentData;
  metadata: Metadata;
}
