import Icon from '@app/components/lib-components/icon/Icon';
import { ISpotlightSearchResult } from '../constants';
import { DynamicTextSlicer } from './DynamicTextSlicer';
import { RenderTabTag } from './renderTabTag';
import { useParams } from 'react-router-dom';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { ApiResponse } from '@app/types';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { useLocalNavigation } from '@app/hooks/useLocalNavigation';
import React from 'react';
import { OverviewResponseType } from '@app/types/overview';
import classNames from 'classnames';
import { CompanySuggestion } from './input.types';

interface SearchResultProps {
  result: CompanySuggestion[];
  localResult: ISpotlightSearchResult[];
  selectedIndex: number;
  onResultClick: () => void;
}

export const SearchResult: React.FC<SearchResultProps> = ({
  result,
  localResult,
  selectedIndex,
  onResultClick,
}) => {
  const { id: cinId } = useParams();
  const localNavigate = useLocalNavigation();
  const isLocalResultExist = localResult.length > 0;
  const identifierType = useSelector((state: RootState) => state.util.identifierType);

  const getCompanyOverviewData = useGetCompanyData<ApiResponse<OverviewResponseType>>();
  const { data: overviewData, isSuccess } = getCompanyOverviewData(
    {
      cinId: cinId as string,
      field_name: 'company',
      identifierType: identifierType,
    },
    {
      refetchOnMountOrArgChange: false,
      skip: !cinId,
    },
  );

  const handleResultClick = (index: number) => {
    if (index < localResult.length) {
      // Local result
      localNavigate(cinId as string, localResult[index].route);
    } else {
      // Global result
      const globalIndex = index - localResult.length;
      localNavigate(result[globalIndex].suggestion.cin, 'overview');
    }

    // Call the onResultClick callback to close/reset the list
    onResultClick();
  };

  return (
    <div className='bg-white border w-[calc(100%-40px)] absolute top-full border-t-0 border-gray-200 rounded-b-md mt-1 shadow-md z-10'>
      {isSuccess && (
        <div>
          {isLocalResultExist && (
            <div className='mt-2 mb-2'>
              <span className='text-gray-400 ml-3 mt-5 font-medium text-sm'>
                Within &quot;{overviewData?.response_data.company.legal_name}&quot;
              </span>
            </div>
          )}
          {isLocalResultExist &&
            localResult.map((item, index) => {
              const isSelected = selectedIndex === index;
              return (
                <div
                  key={item.key}
                  onClick={() => handleResultClick(index)}
                  className={classNames(
                    'p-2 text-sm cursor-pointer flex justify-between items-center whitespace-nowrap',
                    {
                      'bg-gray-100': isSelected,
                      'hover:bg-gray-100': !isSelected,
                    },
                  )}
                >
                  <div className='flex items-center font-semibold overflow-auto'>
                    <Icon icon={'SearchFlagIcon'} size={15} />
                    <span className='ml-2 w-[80%]'>
                      <DynamicTextSlicer text={item.field} className='ml-2' />
                    </span>
                  </div>
                  <div className='flex items-center ml-2 text-gray-400'>
                    <RenderTabTag tab={item.title} />
                  </div>
                </div>
              );
            })}
        </div>
      )}
      {isLocalResultExist && (
        <div className='border-t border-gray-200 mt-2 mb-2 pt-3'>
          <span className='text-gray-400 ml-3 pt-5 font-medium text-sm'>Other Results</span>
        </div>
      )}
      {result.map(({ suggestion: { cin, company_name } }, index) => {
        const globalIndex = localResult.length + index;
        const isSelected = selectedIndex === globalIndex;
        return (
          <div
            data-testid='suggestions-container'
            key={cin}
            onClick={() => handleResultClick(globalIndex)}
            className={classNames(
              'p-2 text-sm cursor-pointer flex whitespace-nowrap justify-between',
              {
                'bg-gray-100': isSelected,
                'hover:bg-gray-100': !isSelected,
              },
            )}
          >
            <div className='flex items-center font-semibold overflow-auto'>
              <Icon icon={'SearchFlagIcon'} size={15} />
              <span className='ml-2 w-[80%]'>
                <DynamicTextSlicer text={company_name} className='ml-2' />
              </span>
            </div>
            <div className='flex items-center ml-2 text-gray-400'>{cin}</div>
          </div>
        );
      })}
    </div>
  );
};
