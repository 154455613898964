import React, { useState } from 'react';
import InfoCard from '@app/components/lib-components/info-card/InfoCard';
import Accordion from '@app/components/lib-components/accordian/Accordian';
import { DocumentIconButton } from '@components/lib-components/DocumentIconButton';
import { JointVentureCompany, ShareholdingJVResponse } from './ShareholdingProps.types';
import { Metadata } from '@app/types/financial';

interface EntitySectionProps {
  title: string;
  data: ShareholdingJVResponse | null | undefined;
  metadata: Metadata | null | undefined;
  renderTable: (data: JointVentureCompany[]) => React.ReactNode;
  sectionId?: string;
}

const EntitySection: React.FC<EntitySectionProps> = ({
  title,
  data,
  metadata,
  renderTable,
  sectionId,
}) => {
  const [openAccordion, setOpenAccordion] = useState<string | null>('companies');

  const onToggle = (accordionId: string) => {
    setOpenAccordion((prevState) => (prevState === accordionId ? null : accordionId));
  };

  if (
    !data ||
    !data.data ||
    (!data.data.company && !data.data.llp && !data.data.others) ||
    (data.data.company?.length === 0 &&
      data.data.llp?.length === 0 &&
      data.data.others?.length === 0)
  )
    return null;

  return (
    <div id={sectionId}>
      <InfoCard className='w-full p-4'>
        <div className='flex items-start'>
          <p className='text-md font-semibold  text-blue-700'>{title}</p>
          {metadata && <DocumentIconButton metadata={metadata} documentData={data} />}
        </div>
        {data.data.company && data.data.company.length > 0 && (
          <Accordion
            icon={null}
            title='Companies'
            isOpen={openAccordion === 'companies'}
            onToggle={() => onToggle('companies')}
          >
            {renderTable(data.data.company)}
          </Accordion>
        )}
        {data.data.llp && data.data.llp.length > 0 && (
          <Accordion
            icon={null}
            title='LLPs'
            isOpen={openAccordion === 'llps'}
            onToggle={() => onToggle('llps')}
          >
            {renderTable(data.data.llp)}
          </Accordion>
        )}
        {data.data.others && data.data.others.length > 0 && (
          <Accordion
            icon={null}
            title='Others'
            isOpen={openAccordion === 'others'}
            onToggle={() => onToggle('others')}
          >
            {renderTable(data.data.others)}
          </Accordion>
        )}
      </InfoCard>
    </div>
  );
};

export default EntitySection;
