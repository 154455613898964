import { PvtCompanyOverview } from './components/PvtCompanyOverview';
import { LLPCompanyOverview } from './components/LLPCompanyOverview';
import { Company } from '@app/types/overview';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';
import { useGetContactDetailsQuery } from '@app/store/api/companyApi';

interface Props {
  companyOverviewData: Company;
}

export const Overview = ({ companyOverviewData }: Props) => {
  const companyType = useSelector((state: RootState) => state.util.companyType);
  const { data: contactDetailsResponse } = useGetContactDetailsQuery(
    { identifier_value: companyOverviewData.cin },
    { skip: !companyOverviewData.cin },
  );

  const contactDetails = contactDetailsResponse?.response_data.contact_details || null;

  return (
    <div className='py-4 h-full'>
      {companyType === COMPANY_TYPE.LimitedLiabilityPartnership ? (
        <LLPCompanyOverview companyOverview={companyOverviewData} contactDetails={contactDetails} />
      ) : (
        <PvtCompanyOverview companyOverview={companyOverviewData} contactDetails={contactDetails} />
      )}
    </div>
  );
};
