import React, { useState } from 'react';
import {
  RelatedParty,
  RelatedPartyTableProps,
  RelatedPartyCategory,
  RelatedPartyTableHeader,
} from '@app/types/financial';
import { numDifferentiation } from '@components/utils/commonUtils';
import Accordion from '@components/lib-components/accordian/Accordian';
import Table from '@components/lib-components/table/table/Table';
import Button from '@components/lib-components/button/Button';
import { PartyDetailsModal } from './PartyDetailsModal';
import { useLocalNavigation } from '@app/hooks/useLocalNavigation';

const headers: RelatedPartyTableHeader[] = [
  { name: 'Name', value: 'name', classes: 'p-4 text-sm text-left w-[10rem]' },
  {
    name: 'Nature of Related Party',
    value: 'nature_of_related_party',
    classes: 'p-4 text-sm text-left w-[12rem]',
  },
  {
    name: 'Nature of Transaction',
    value: 'nature_of_transaction',
    classes: 'p-4 text-sm text-left w-[12rem]',
  },
  { name: 'PAN', value: 'pan', classes: 'p-4 text-sm text-left w-[8rem]' },
  { name: 'CIN / LLPIN', value: 'cin', classes: 'p-4 text-sm text-left w-[12rem]' },
  {
    name: 'Amount Payable',
    value: 'amounts_payable',
    classes: 'p-4 !pl-8 text-sm text-left w-[9rem]',
  },
  {
    name: 'Transaction Expenses',
    value: 'transactions_expenses',
    classes: 'p-4 text-sm text-left w-[10rem]',
  },
  { name: ' ', value: 'actions', classes: 'p-4 text-sm text-left w-[9rem] ' },
];

const categories: RelatedPartyCategory[] = [
  { id: 'individuals', title: 'Individual Related Parties' },
  { id: 'companies', title: 'Company Related Parties' },
  { id: 'others', title: 'Other Related Parties' },
];

const RelatedPartyTable: React.FC<RelatedPartyTableProps> = ({ data, figureFormat }) => {
  const [openAccordions, setOpenAccordions] = useState<string[]>([]);
  const [selectedParty, setSelectedParty] = useState<RelatedParty | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const localNavigate = useLocalNavigation();
  const toggleAccordion = (accordionId: string) => {
    setOpenAccordions((prev) =>
      prev.includes(accordionId) ? prev.filter((id) => id !== accordionId) : [...prev, accordionId],
    );
  };
  const handleViewDetails = (party: RelatedParty) => {
    setSelectedParty(party);
    setIsModalOpen(true);
  };

  const formatType = (type: string): string => {
    if (!type) return '-';

    let formattedType = type.replace(/Member$/, '');

    formattedType = formattedType.split(/(?=[A-Z])/).join(' ');

    return formattedType
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const onCinRedirection = (cin: string) => {
    localNavigate(cin, 'overview');
  };

  const createRows = (parties: RelatedParty[], categoryId: string) => {
    return parties.map((party) => ({
      name: party.name_of_related_party || '-',
      nature_of_related_party: party.description_of_nature_of_related_party || '-',
      nature_of_transaction: party.description_of_nature_of_transaction_with_related_party || '-',
      pan: party.pan || '-',
      cin:
        categoryId === 'companies' && party.cin ? (
          <Button
            className='bg-transparent !text-utility-gray-blue-700 text-xs font-semibold !py-1 !px-2 rounded-md w-auto border-2 border-gray-200 cursor-pointer'
            onClick={() => onCinRedirection(party.cin || '')}
            text={party.cin || '-'}
          />
        ) : (
          '-'
        ),
      amounts_payable:
        party.amounts_payable !== null
          ? numDifferentiation(party.amounts_payable, figureFormat)
          : '-',
      transactions_expenses:
        party.transactions_expenses !== null
          ? numDifferentiation(party.transactions_expenses, figureFormat)
          : '-',
      actions: (
        <Button
          text='View Other Details'
          variant='text'
          className='text-utility-600 hover:text-brand-600 bg-transparent'
          onClick={() => handleViewDetails(party)}
        />
      ),
    }));
  };

  return (
    <div className='w-full'>
      {categories.map((category) => {
        const categoryCount = data.reduce((sum, yearData) => {
          if (yearData.data) {
            return sum + (yearData.data[category.id]?.length || 0);
          }
          return sum;
        }, 0);

        return (
          <Accordion
            key={category.id}
            title={category.title}
            isOpen={openAccordions.includes(category.id)}
            onToggle={() => toggleAccordion(category.id)}
            count={categoryCount}
          >
            {categoryCount > 0 ? (
              data.map((yearData, index) => {
                const parties = yearData.data[category.id] || [];

                // Skip if no parties
                if (parties.length === 0) {
                  return null;
                }

                const displayYear =
                  yearData.year || yearData.metadata?.financial_year?.split('-')[0] || 'N/A';
                const accordionId = `${category.id}-${displayYear}-${index}`;

                const rows = createRows(parties, category.id);
                return (
                  <Accordion
                    key={accordionId}
                    title={`FY-${displayYear}`}
                    isOpen={openAccordions.includes(accordionId)}
                    onToggle={() => toggleAccordion(accordionId)}
                    count={parties.length}
                  >
                    <Table
                      tableDivClassname='w-full border border-gray-200 !p-0 rounded mt-4'
                      tableHeaderClassname='align-top'
                      headers={headers.filter((header) =>
                        category.id === 'companies' ? true : header.value !== 'cin',
                      )}
                      rows={rows}
                    />
                  </Accordion>
                );
              })
            ) : (
              <div className='w-full p-4 text-center text-gray-600'>
                No data available for {category.title.toLowerCase()}
              </div>
            )}
          </Accordion>
        );
      })}

      {selectedParty && (
        <PartyDetailsModal
          party={selectedParty}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          figureFormat={figureFormat}
          formatType={formatType}
        />
      )}
    </div>
  );
};

export default RelatedPartyTable;
