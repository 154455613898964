import { useState } from 'react';
import { ApiResponse } from '@app/types';
import type {
  OutstandingDuesWrapper,
  OutstandingDuesItem,
  OutstandingDueListItem,
} from '@app/types/compliances';
import Table from '@components/lib-components/table/table/Table';
import { Header } from '@components/lib-components/table/table/table.types';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { formatCurrencyNumber, formatDate } from '@components/utils/commonUtils';
import Accordion from '@components/lib-components/accordian/Accordian';
import { DocumentIconButton } from '@components/lib-components/DocumentIconButton';
import Button from '@components/lib-components/button/Button';
import InfoCard from '@components/lib-components/info-card/InfoCard';

interface OutstandingDuesProps {
  data: ApiResponse<OutstandingDuesWrapper> | undefined;
  isReportFetching: boolean;
}

function OutstandingDues({ data, isReportFetching }: OutstandingDuesProps) {
  const outstandingDuesData = data?.response_data.outstanding_dues_msme || [];
  const [openAccordions, setOpenAccordions] = useState<number[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMainAccordionOpen, setIsMainAccordionOpen] = useState(true);
  const itemsPerPage = 10;
  const [tablePages, setTablePages] = useState<{ [key: number]: number }>({});
  const tableItemsPerPage = 5; // Number of items per page in the table

  const headers: Header[] = [
    { name: 'Name of Supplier', value: 'name_supplier', classes: 'p-4 text-sm' },
    { name: 'PAN', value: 'pan_supplier', classes: 'p-4 text-sm' },
    { name: 'Amount Due', value: 'amount_due', classes: 'p-4 text-sm' },
    { name: 'Due From', value: 'date_amount_due_from', classes: 'p-4 text-sm' },
  ];

  const toggleAccordion = (index: number) => {
    setOpenAccordions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setOpenAccordions([]);
  };

  const handleTablePageChange = (accordionIndex: number, page: number) => {
    setTablePages((prev) => ({ ...prev, [accordionIndex]: page }));
  };

  if (isReportFetching) {
    return <ErrorMessage message='Fetching outstanding dues data...' />;
  }

  // Sort the data to move "Initial Return" to the end
  const sortedData = [...outstandingDuesData];

  const paginatedData = sortedData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  return (
    <div className='flex flex-col gap-4 w-full mt-6'>
      {outstandingDuesData.length === 0 ? (
        <ErrorMessage message='No public outstanding dues data available for this company.' />
      ) : (
        <InfoCard className='py-1 flex flex-col gap-4 w-full my-6'>
          <div className='text-lg w-max text-heading font-semibold'>MSME Outstanding Dues</div>
          <Accordion
            title='MSME Outstanding Dues'
            isOpen={isMainAccordionOpen}
            onToggle={() => setIsMainAccordionOpen(!isMainAccordionOpen)}
            count={outstandingDuesData.length}
          >
            {paginatedData.map((item: OutstandingDuesItem, index: number) => {
              const currentTablePage = tablePages[index] || 1;
              const totalTablePages = Math.ceil(item.data.dues_list.length / tableItemsPerPage);
              const paginatedTableData = item.data.dues_list.slice(
                (currentTablePage - 1) * tableItemsPerPage,
                currentTablePage * tableItemsPerPage,
              );

              return (
                <Accordion
                  key={index}
                  title={
                    <div className='flex justify-between items-center w-full'>
                      <div className='flex'>
                        {item.data.period}
                        <DocumentIconButton
                          metadata={item?.metadata ?? null}
                          documentData={item ?? null}
                        />
                      </div>
                    </div>
                  }
                  isOpen={openAccordions.includes(index)}
                  onToggle={() => toggleAccordion(index)}
                >
                  <div>
                    <p className='text-sm font-normal text-tertiary-600'>Total Due Amount</p>
                    <p className='text-sm font-semibold text-secondary-700'>
                      {formatCurrencyNumber(item.data.total_outstanding_amount)}
                    </p>
                  </div>

                  <Table
                    headers={headers}
                    rows={paginatedTableData.map((due: OutstandingDueListItem) => ({
                      name_supplier: due.name_supplier,
                      pan_supplier: due.pan_supplier,
                      amount_due: formatCurrencyNumber(due.amount_due),
                      date_amount_due_from: formatDate(due.date_amount_due_from),
                    }))}
                    className='w-full'
                    tableDivClassname='w-full border border-gray-200 !p-0 rounded mt-4'
                  />

                  {totalTablePages > 1 && (
                    <div className='mt-4 flex justify-end items-center'>
                      <Button
                        onClick={() => handleTablePageChange(index, currentTablePage - 1)}
                        disabled={currentTablePage === 1}
                        className='px-4 py-2 mr-2 rounded disabled:opacity-50'
                        text='Previous'
                      />
                      <span className='px-4 py-2'>
                        Page {currentTablePage} of {totalTablePages}
                      </span>
                      <Button
                        onClick={() => handleTablePageChange(index, currentTablePage + 1)}
                        disabled={currentTablePage === totalTablePages}
                        className='px-4 py-2 ml-2 rounded disabled:opacity-50'
                        text='Next'
                      />
                    </div>
                  )}
                </Accordion>
              );
            })}

            <div className='mt-4 flex justify-between items-center'>
              <span className='text-sm text-gray-600'>Total Entries: {sortedData.length}</span>
              <div className='flex items-center'>
                <Button
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  className='px-4 py-2 mr-2 rounded disabled:opacity-50'
                  text='Previous'
                />
                <span className='px-4 py-2'>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className='px-4 py-2 ml-2 rounded disabled:opacity-50'
                  text='Next'
                />
              </div>
            </div>
          </Accordion>
        </InfoCard>
      )}
    </div>
  );
}

export default OutstandingDues;
