import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { CourtType, CourtCases, CaseWithFlags } from '@app/types/legal';
import Icon from '@components/lib-components/icon/Icon';
import Accordion from '@components/lib-components/accordian/Accordian';
import { useToggle } from '@app/hooks/useToggle';
import { CourtCasesModal } from './CourtCasesModal';
import { DocumentPreviewModal } from '../Compliances/DocumentPreviewModal';
import { ApiResponse } from '@app/types';
import { HighlightsData } from '@app/types/highlights';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import InfoCard from '@components/lib-components/info-card/InfoCard';

export const LegalHistory: React.FC<{ highlightsData: ApiResponse<HighlightsData> }> = ({
  highlightsData,
}) => {
  const [selectedCourt, setSelectedCourt] = useState<CourtType | null>(null);
  const [isPreviewOpen, togglePreviewModal] = useToggle();
  const [activeUrl, setActiveUrl] = useState<string | null>(null);
  const [isAccordionOpen, toggleAccordion] = useToggle(true);

  const legalHistoryData = highlightsData?.response_data?.highlights?.data?.legal_history_overview;
  const hasValidData =
    legalHistoryData?.data &&
    Object.keys(legalHistoryData.data).some(
      (courtType) =>
        Array.isArray(legalHistoryData.data[courtType as CourtType]) &&
        legalHistoryData.data[courtType as CourtType]?.some(
          (court) => Array.isArray(court.cases) && court.cases.length > 0,
        ),
    );

  const getCaseCount = (courtType: CourtType): number => {
    if (!legalHistoryData?.data?.[courtType]?.length) return 0;
    return legalHistoryData.data[courtType].reduce(
      (acc: number, court: CourtCases) => acc + (court.cases?.length ?? 0),
      0,
    );
  };

  const getHighRiskCaseCount = (courtType: CourtType): number => {
    if (!legalHistoryData?.data?.[courtType]?.length) return 0;
    return legalHistoryData.data[courtType].reduce(
      (acc: number, court: CourtCases) =>
        acc +
        (court.cases?.filter((caseItem: CaseWithFlags) => caseItem.case.risk_type === 'high_risk')
          ?.length ?? 0),
      0,
    );
  };

  const getMediumRiskCaseCount = (courtType: CourtType): number => {
    if (!legalHistoryData?.data?.[courtType]?.length) return 0;
    return legalHistoryData.data[courtType].reduce(
      (acc: number, court: CourtCases) =>
        acc +
        (court.cases?.filter((caseItem: CaseWithFlags) => caseItem.case.risk_type === 'medium_risk')
          ?.length ?? 0),
      0,
    );
  };

  const getCourtTitle = (courtType: CourtType): string => {
    const titles: Record<CourtType, string> = {
      high_court: 'High Court Cases',
      supreme_court: 'Supreme Court Cases',
      tribunals: 'Tribunal Cases',
      e_court: 'E-Court Cases',
      fir: 'FIR Cases',
      defaulters: 'Defaulter Cases',
      mca: 'MCA Cases',
      ibc: 'IBC Cases',
    };
    return titles[courtType];
  };

  const getRiskBadges = (courtType?: CourtType) => {
    const badges = [
      {
        type: 'low_risk',
        icon: 'CheckIcon' as const,
        count: courtType
          ? getCaseCount(courtType) -
            getMediumRiskCaseCount(courtType) -
            getHighRiskCaseCount(courtType)
          : availableCourtTypes.reduce(
              (acc, type) =>
                acc +
                (getCaseCount(type) - getMediumRiskCaseCount(type) - getHighRiskCaseCount(type)),
              0,
            ),
        bgColor: 'bg-green-50',
        textColor: 'text-green-500',
      },
      {
        type: 'medium_risk',
        icon: 'ExclamationTriangleIcon' as const,
        count: courtType
          ? getMediumRiskCaseCount(courtType)
          : availableCourtTypes.reduce((acc, type) => acc + getMediumRiskCaseCount(type), 0),
        bgColor: 'bg-yellow-50',
        textColor: 'text-yellow-500',
      },
      {
        type: 'high_risk',
        icon: 'FlagIcon' as const,
        count: courtType
          ? getHighRiskCaseCount(courtType)
          : availableCourtTypes.reduce((acc, type) => acc + getHighRiskCaseCount(type), 0),
        bgColor: 'bg-red-50',
        textColor: 'text-red-500',
      },
    ];

    return (
      <div className='flex items-center gap-2'>
        {badges
          .filter((badge) => badge.count > 0)
          .map((badge) => (
            <div
              key={badge.type}
              className={classNames(
                'rounded-full px-3 py-1 text-xs font-medium flex items-center gap-1',
                badge.bgColor,
                badge.textColor,
              )}
            >
              <Icon icon={badge.icon} size={18} />
              <span className='text-sm'>{badge.count}</span>
            </div>
          ))}
      </div>
    );
  };

  const isValidCourtType = (key: string): key is CourtType => {
    const validCourtTypes: CourtType[] = [
      'high_court',
      'supreme_court',
      'fir',
      'e_court',
      'defaulters',
      'mca',
      'ibc',
      'tribunals',
    ];
    return validCourtTypes.includes(key as CourtType);
  };

  const availableCourtTypes = legalHistoryData?.data
    ? Object.keys(legalHistoryData.data).filter(isValidCourtType)
    : [];

  return (
    <div className='mt-6'>
      {isPreviewOpen &&
        createPortal(
          <DocumentPreviewModal
            title='Legal Preview'
            isOpen={isPreviewOpen}
            onClose={togglePreviewModal.off}
            url={activeUrl ?? ''}
            isSanitize={true}
          />,
          document.body,
        )}
      {!hasValidData ? (
        <InfoCard>
          <ErrorMessage message='No legal history data available' />
        </InfoCard>
      ) : (
        <Accordion
          title='Legal History Overview'
          isOpen={isAccordionOpen}
          onToggle={toggleAccordion.toggle}
          count={availableCourtTypes.reduce((acc, type) => acc + getCaseCount(type), 0)}
          iconFlag={getRiskBadges()}
        >
          <div className='grid grid-cols-3 gap-4 mt-4'>
            {availableCourtTypes.map((courtType) => (
              <div
                key={courtType}
                className={classNames(
                  'bg-white rounded-lg p-4 shadow cursor-pointer transition-shadow',
                  'hover:shadow-md',
                )}
                onClick={() => setSelectedCourt(courtType)}
              >
                <div className='flex items-center justify-between mb-4'>
                  <h4 className='font-semibold'>{getCourtTitle(courtType)}</h4>
                  <Icon icon='ChevronRightIcon' className='text-gray-400' size={20} />
                </div>
                {getRiskBadges(courtType)}
              </div>
            ))}
          </div>
        </Accordion>
      )}

      {!isPreviewOpen && selectedCourt && legalHistoryData?.data[selectedCourt] && (
        <CourtCasesModal
          isOpen={!!selectedCourt}
          onClose={() => setSelectedCourt(null)}
          courtCases={legalHistoryData.data[selectedCourt]}
          title={getCourtTitle(selectedCourt)}
          onPreviewClick={(url: string) => {
            setActiveUrl(url);
            togglePreviewModal.on();
          }}
        />
      )}
    </div>
  );
};
