import { createApi } from '@reduxjs/toolkit/query/react';
import { useDispatch } from 'react-redux';
import { useCallback, useState } from 'react';
import type {
  Company,
  CompanySearchResultResponse,
  ContactDetail,
  SearchCompaniesFilters,
} from '@app/types/overview';
import { axiosBaseQuery } from '../axiosBase';
import { ReportStatusEnum, TokenRangeData } from './tokenLedger/types';
import { ApiResponse, PresignedUrlPayLoad } from '@app/types';
import { Team } from '@app/types/settings';
import { UnknownAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState, store } from '../store';
import {
  Suggetions,
  OrderPayload,
  PlaceOrderResponse,
  PlaceOrderPayload,
  CheckOrderStatusResponse,
} from '@app/types/tokenLedger';
import { MSME_DATA, isMsmeCompany } from '@components/company/msme/data/msmeData';

const companyApi = createApi({
  reducerPath: 'companyByCin',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['company-api', 'global-search'],
  endpoints: (builder) => ({
    searchCompanyByName: builder.mutation<Suggetions, string>({
      query: (id) => {
        const encodedId = encodeURIComponent(id);
        return {
          url: `rsearch/suggestions?query_key=company_name&query_string=${encodedId}`,
          method: 'get',
        };
      },
    }),
    getCompanyResponse: builder.query<unknown, OrderPayload>({
      query: (payload: OrderPayload) => ({
        url: 'customer-user-report-order-ledger/get-company-data-by-company-identifier',
        method: 'get',
        params: {
          identifier_type: payload.identifierType,
          identifier_value: payload.cinId.toUpperCase(),
          field_name: payload.field_name,
          force_download: payload.force_download,
          skip: payload.skip,
          limit: payload.limit,
        },
      }),
      providesTags: (_, __, arg) => [
        {
          type: 'company-api',
          id: arg.field_name,
        },
      ],
    }),
    getCompanyByCin: builder.query<Company, string>({
      query: (id: string) => ({
        url: '/credhive-parser/overview',
        method: 'post',
        data: {
          cin: id,
        },
      }),
    }),
    reportIssue: builder.mutation<Company, { issue: string; description: string }>({
      query: (data) => ({
        url: '/user-management/report-issue',
        method: 'POST',
        data: data,
      }),
    }),
    getTokenRanges: builder.query<ApiResponse<TokenRangeData[]>, void>({
      query: () => ({
        url: 'customer-company/category/get-all-token-range',
        method: 'GET',
      }),
    }),
    getPaymentRedirectionLink: builder.mutation({
      query: (buyTokenPayload) => ({
        url: '/transaction-and-ledger/buy-tokens-generate-payment-link',
        method: 'POST',
        data: buyTokenPayload,
      }),
    }),
    getDocumentRedirectionLink: builder.mutation<
      {
        pre_signed_url: string;
        flatten: boolean;
      },
      PresignedUrlPayLoad
    >({
      query: (getDocumentPayload) => ({
        url: '/customer-user-report-order-ledger/get-presigned-url',
        method: 'GET',
        params: getDocumentPayload,
      }),
    }),
    getTeamsData: builder.query<ApiResponse<Team[]>, void>({
      query: () => ({
        url: `/customer-company/get-all-members-by-company`,
        method: 'GET',
      }),
    }),
    searchCompanies: builder.query<
      ApiResponse<CompanySearchResultResponse[]>,
      SearchCompaniesFilters
    >({
      query: (filters) => {
        const params = new URLSearchParams(filters as Record<string, string>).toString();
        return {
          url: `/entity/get-all?${params}`,
          method: 'GET',
        };
      },
      providesTags: ['global-search'],
    }),
    findCompanies: builder.mutation<
      ApiResponse<CompanySearchResultResponse[]>,
      SearchCompaniesFilters
    >({
      // TODO: This is a temporary endpoint to search companies by name - mutation is added to invalidate the cache
      query: (filters) => {
        const params = new URLSearchParams(filters as Record<string, string>).toString();
        return {
          url: `/entity/get-all?${params}`,
          method: 'GET',
        };
      },
    }),
    placeOrder: builder.mutation<PlaceOrderResponse, PlaceOrderPayload>({
      query: (payload) => ({
        url: '/customer-company-token-order-ledger/place-order-juspay',
        method: 'POST',
        data: payload,
      }),
    }),
    checkOrderStatus: builder.query<CheckOrderStatusResponse, string>({
      query: (juspay_order_id) => ({
        url: `/customer-company-token-order-ledger/check-order-status-juspay`,
        method: 'GET',
        params: { juspay_order_id },
      }),
    }),
    getContactDetails: builder.query<ApiResponse<ContactDetail>, { identifier_value: string }>({
      query: (params) => ({
        url: 'customer-user-report-order-ledger/get-company-data-by-company-identifier',
        method: 'GET',
        params: {
          identifier_type: 'CIN',
          identifier_value: params.identifier_value,
          field_name: 'contact_details',
          force_download: false,
        },
      }),
    }),
  }),
});

interface QueryOptions {
  skip?: boolean;
  refetchOnMountOrArgChange?: boolean | number;
}

export function useGetCompanyData<T>() {
  const result = (payload: OrderPayload, options: QueryOptions = {}) => {
    const { cinId } = payload;

    if (cinId && isMsmeCompany(cinId)) {
      const msmeResponse = {
        response_data: {
          company: MSME_DATA[cinId as keyof typeof MSME_DATA],
          report_date: new Date().toISOString(),
          refresh_count: 1,
          refresh_available: true,
          token_charges: 0,
          fresh_token_charges: 0,
          existing_token_charges: 0,
          status: ReportStatusEnum.COMPLETED,
        },
      };

      return {
        data: msmeResponse as unknown as T,
        isLoading: false,
        isError: false,
        isSuccess: false,
        refetch: () => Promise.resolve(null),
      };
    }

    const queryResult = useGetCompanyResponseQuery(payload, {
      ...options,
      refetchOnMountOrArgChange: options.refetchOnMountOrArgChange ?? true,
    });

    return {
      ...queryResult,
      data: queryResult.data as T,
    };
  };

  return result;
}

export const {
  useGetCompanyByCinQuery,
  useGetCompanyResponseQuery,
  useSearchCompanyByNameMutation,
  useGetTokenRangesQuery,
  useGetPaymentRedirectionLinkMutation,
  useReportIssueMutation,
  useGetDocumentRedirectionLinkMutation,
  useGetTeamsDataQuery,
  useLazySearchCompaniesQuery,
  useFindCompaniesMutation,
  usePlaceOrderMutation,
  useCheckOrderStatusQuery,
  useGetContactDetailsQuery,
} = companyApi;

export const useSearchCompaniesWithCache = () => {
  const [trigger, result] = useLazySearchCompaniesQuery();
  const dispatch = useDispatch<ThunkDispatch<RootState, unknown, UnknownAction>>();
  const [cachedResult, setCachedResult] = useState<ApiResponse<
    CompanySearchResultResponse[]
  > | null>(null);

  const searchWithCache = useCallback(
    async (filters: SearchCompaniesFilters) => {
      const { skip = 0, ...restFilters } = filters;
      const currentPage = Math.floor(Number(skip) / 40); // Adjusted for 40 rows per page

      try {
        // Check if the data for the current page is already in the cache
        const cachedData = companyApi.endpoints.searchCompanies.select(filters)(
          store.getState() as RootState,
        );

        let searchResult;

        if (cachedData.data) {
          // If data is in cache, use it
          searchResult = cachedData.data;

          setCachedResult(searchResult);
        } else {
          // If not in cache, fetch from API
          searchResult = await trigger(filters).unwrap();
          setCachedResult(null);
        }

        // Prefetch adjacent pages (one on each side)
        const pagesToPrefetch = [currentPage - 1, currentPage + 1];
        const prefetchPromises = pagesToPrefetch.map((page) => {
          if (page >= 0) {
            const prefetchFilters = { ...restFilters, skip: page * 40, limit: 40 }; // Updated prefetch logic
            // Check if the data for this page is already in the cache
            const prefetchCachedData = companyApi.endpoints.searchCompanies.select(prefetchFilters)(
              store.getState() as RootState,
            );

            if (!prefetchCachedData.data) {
              // Only prefetch if not in cache
              return dispatch(
                companyApi.util.prefetch('searchCompanies', prefetchFilters, { force: false }),
              );
            }
          }
          return Promise.resolve();
        });

        // Execute prefetch requests
        await Promise.all(prefetchPromises);

        // Ensure the current page data is cached
        if (!cachedData.data) {
          dispatch(
            companyApi.util.updateQueryData('searchCompanies', filters, () => {
              return searchResult;
            }),
          );
        }

        return searchResult;
      } catch (error) {
        console.error('Error in searchWithCache:', error);
        throw error;
      }
    },
    [trigger, dispatch],
  );

  // Combine the result from the query and the cached result
  const combinedResult = cachedResult || result.data;

  return {
    searchWithCache,
    ...result,
    data: combinedResult,
    isFetching: result.isFetching && !cachedResult,
  };
};

export default companyApi;
