import { ShareholdingData } from '@components/company/company-tabs/ShareHoldingPattern/ShareholdingProps.types';
import { FinancialRatiosResponse, Metadata } from '@app/types/financial';
import { AuthorizedSignatoriesResponse, DirectorNetworkResponse } from '@app/types/management';
import { CreditRatingResponse } from '@app/types/creditRatings';
import { OutstandingDuesWrapper } from '@app/types/compliances';
import { IdentifierTypeEnum } from '@app/types';
import { OverviewResponseType } from '@app/types/overview';

export interface CustomerCompanyTokenBalanceJSON {
  token_balance: string;
  blocked_tokens: string;
  company_id: string;
  created_at: string;
  updated_at: string;
}

interface CustomerTokenOrderDetail {
  company_id: string;
  user_id: string;
  customer_email: string;
  customer_phone_number: string;
  juspay_id: string;
  juspay_txn_id: string;
  juspay_product_id: string;
  juspay_order_id: string;
  juspay_date_created: string;
  number_of_tokens: string;
  juspay_amount: string;
  juspay_currency: string;
  juspay_order_status: string;
  juspay_payment_method_type: string;
  juspay_effective_amount: string;
  juspay_gateway_reference_id: string | null;
}

export interface CustomerTokenOrderResponse {
  message: string;
  response_data: CustomerTokenOrderDetail[];
}

export interface UserReportsLedgerApiJSON {
  identifier_type: string;
  report_expiry: string;
  created_at: string;
  status: string;
  identifier_value: string;
  company_name: string;
}

export interface UserReportsLedgerApi {
  response_data: UserReportsLedgerApiJSON[];
  total: number;
}

export interface CompanyReportsLedgerApiJSON {
  identifier_type: string;
  report_expiry: string;
  created_at: string;
  status: string;
  identifier_value: string;
  company_name: string;
  ordered_by: string;
}

export interface CompanyReportsLedgerApi {
  response_data: CompanyReportsLedgerApiJSON[];
  total: number;
}

export interface OrderPayload {
  identifier_value: string;
  company_name: string;
  is_fresh_report: boolean;
  identifier_type: IdentifierTypeEnum;
}

export enum ReportStatusEnum {
  INITIALISED = 'INITIALISED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  LOCKED = 'LOCKED',
  ALREADY_EXISTS = 'ALREADY_EXISTS',
  IN_PROGRESS_ALREADY_EXISTS = 'IN_PROGRESS_ALREADY_EXISTS',
  FAILED = 'FAILED',
  INVALID = 'INVALID',
}

export interface OrderResponseJSON {
  id: string;
  created_at: string;
  updated_at: string;
  user_id: string;
  report_id: string;
  company_id: string;
  report_order_charges: number;
  is_fresh_report: boolean;
  status: ReportStatusEnum;
}

export interface OrderResponse {
  status: string;
  response_data: OrderResponseJSON;
  message: string;
}
export interface Attachment {
  attachment_name: string;
  pdf_file_name: string;
  attachment_folder_category: string;
}

export interface DocumentsData {
  document_list: Record<DocumentCategory, Metadata[]>;
  latest_documents?: Metadata[];
  status?: string;
}

export type ResponseDataType =
  | OverviewResponseType
  | ShareholdingData
  | DirectorNetworkResponse
  | AuthorizedSignatoriesResponse
  | DocumentsData
  | CreditRatingResponse
  | FinancialRatiosResponse
  | OutstandingDuesWrapper;

export interface Pagination {
  skip: number;
  limit: number;
}

export interface OrderSourceMetadata {
  name: string;
  email: string;
  id: string;
}

export interface TokenLedgerEntry {
  customer_user_name: string;
  user_id: string | null;
  company_id: string;
  number_of_tokens: number;
  token_price: number;
  order_source: string;
  order_reason: string;
  order_source_metadata: OrderSourceMetadata | null;
  id: string;
  created_at: string;
  updated_at: string;
  juspay_order_status: OrderStatus;
}

export interface RequestCompanyReport {
  company_name: string;
  identifier_type: string;
  identifier_value: string;
  status: ReportStatusEnum;
  customer_company_id: string;
  id: string;
  created_at: string;
  updated_at: string;
  customer_company_name: string;
}

export enum DocumentCategory {
  CERTIFICATES = 'Certificates',
  CHANGE_IN_DIRECTORS = 'Change in Directors',
  INCORPORATION_DOCUMENTS = 'Incorporation Documents',
  CHARGE_DOCUMENTS = 'Charge Documents',
  ANNUAL_RETURNS = 'Annual Returns and Balance Sheet eForms',
  LLP_FORMS = 'LLP Forms(Conversion of company to LLP)',
  OTHER_EFORMS = 'Other E-Form Documents',
  OTHER_ATTACHMENTS = 'Other Attachments',
}

export const DOCUMENT_CATEGORY_MAP: Record<DocumentCategory, string> = {
  [DocumentCategory.CERTIFICATES]: 'certificates',
  [DocumentCategory.CHANGE_IN_DIRECTORS]: 'change_in_directors',
  [DocumentCategory.INCORPORATION_DOCUMENTS]: 'incorporation_documents',
  [DocumentCategory.CHARGE_DOCUMENTS]: 'charge_documents',
  [DocumentCategory.ANNUAL_RETURNS]: 'annual_returns_and_balance_sheet_eforms',
  [DocumentCategory.LLP_FORMS]: 'llp_forms',
  [DocumentCategory.OTHER_EFORMS]: 'other_eform_documents',
  [DocumentCategory.OTHER_ATTACHMENTS]: 'other_attachments',
};

export interface TokenRangeData {
  category: string;
  token_range: string;
  token_price: number;
  id: string;
  created_at: string;
  updated_at: string;
}

export type TokenLedgerTableRow = {
  number_of_tokens: string;
  token_price: string;
  created_at: string;
  ordered_by: string;
  status: React.ReactElement;
};

export enum OrderStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  REFUND_PENDING = 'REFUND_PENDING',
  REFUND_COMPLETED = 'REFUND_COMPLETED',
  REFUND_FAILED = 'REFUND_FAILED',
}

export enum AdhocRequestType {
  REQUEST_NEW_COMPANY = 'REQUEST_NEW_COMPANY',
  REQUEST_DOCUMENTS = 'REQUEST_DOCUMENTS',
}
