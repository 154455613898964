import { useMemo } from 'react';
import TableComponent from '@components/lib-components/table/table/TableComponent';
import { OpenCharge, TableRow } from '@app/types/charges';
import Loading from '@components/lib-components/loading/Loading';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { FigureFormat, formatCurrency } from '@components/utils/commonUtils';

interface OpenChargesProps {
  data?: Record<string, OpenCharge[]>[];
  maxLimit: number;
  currentLimit: number;
  skip: number;
  handleNext: () => void;
  handlePrevious: () => void;
  isLoading: boolean;
  isError: boolean;
  selectedFigureFormat: FigureFormat;
}

const headers = ['ID', 'Date', 'Holder Name', 'Status', 'Amount'];

export const OpenCharges = ({
  data,
  maxLimit,
  currentLimit,
  skip,
  handleNext,
  handlePrevious,
  isLoading,
  isError,
  selectedFigureFormat,
}: OpenChargesProps) => {
  const transformOpenChargesData = (data: Record<string, OpenCharge[]>[]) => {
    if (!data) return [];

    return data
      .map((chargeGroup) => {
        const chargeId = Object.keys(chargeGroup)[0];
        const charges = chargeGroup[chargeId];

        if (!charges || charges.length === 0) return null;

        const charge = charges[0];

        return {
          charge_id: charge?.charge_id?.toString() || '-',
          date: charge?.date || '-',
          holderName: charge?.holder_name || '-',
          status: charge?.event_type || '-',
          amount: charge?.amount ? `₹ ${formatCurrency(charge.amount, selectedFigureFormat)}` : '-',
        };
      })
      .filter(Boolean) as TableRow[];
  };

  const tableData = useMemo(() => {
    return transformOpenChargesData(data || []);
  }, [data, selectedFigureFormat]);

  if (isLoading)
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );
  if (isError)
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage message='Error fetching open charges details, please try again later.' />;
      </div>
    );

  if (!data || data.length === 0) {
    return (
      <div className='my-5'>
        <ErrorMessage message='No open charges data available for this company.' />
      </div>
    );
  }

  return (
    <TableComponent
      headers={headers}
      rows={tableData}
      maxLimit={maxLimit}
      currentLimit={currentLimit}
      skip={skip}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      badgeStatus={true}
    />
  );
};
