import React from 'react';
import Icon from '../icon/Icon';
import classNames from 'classnames';
import { RiskCategory } from '@app/types/highlights';

interface RiskBadgeProps {
  type: 'low_risk' | 'medium_risk' | 'high_risk' | 'default';
  count?: number;
  className?: string;
}

export const getRiskBadgeConfig = (type: RiskBadgeProps['type']) => {
  const configs = {
    low_risk: {
      icon: 'CheckIcon' as const,
      bgColor: 'bg-green-50',
      textColor: 'text-green-500',
      label: 'LOW',
    },
    medium_risk: {
      icon: 'ExclamationTriangleIcon' as const,
      bgColor: 'bg-yellow-50',
      textColor: 'text-yellow-500',
      label: 'NEUTRAL',
    },
    high_risk: {
      icon: 'FlagIcon' as const,
      bgColor: 'bg-red-50',
      textColor: 'text-red-500',
      label: 'HIGH',
    },
    default: {
      icon: 'FlagIcon' as const,
      bgColor: 'bg-gray-50',
      textColor: 'text-gray-500',
      label: 'Default',
    },
  };

  return configs[type];
};

export const getRiskTypeFromCategory = (category: RiskCategory): RiskBadgeProps['type'] => {
  switch (category) {
    case RiskCategory.LOW:
      return 'low_risk';
    case RiskCategory.NEUTRAL:
      return 'medium_risk';
    case RiskCategory.HIGH:
      return 'high_risk';
    default:
      return 'default';
  }
};

export const RiskBadge: React.FC<RiskBadgeProps> = ({ type, count, className }) => {
  const config = getRiskBadgeConfig(type);

  return (
    <div
      className={classNames(
        'rounded-full px-3 py-1 text-xs font-medium flex items-center gap-1',
        config.bgColor,
        config.textColor,
        className,
      )}
    >
      <Icon icon={config.icon} size={18} />
      {count !== undefined ? <span className='text-sm'>{count}</span> : null}
    </div>
  );
};
