import { BaseOptionType } from '@components/lib-components/drop-down';

export const formatOptions = [
  { value: 'in', label: 'India', iconName: 'IndianFlagIcon' },
  // { value: 'hk', label: 'Hong Kong', iconName: 'HongKongIcon' },
  { value: 'coming soon', label: 'Coming soon', iconName: 'SparklesIcon' },
];

export const entityOptions: BaseOptionType[] = [
  { value: 'COMPANY', label: 'Company' },
  { value: 'LLP', label: 'LLP' },
];

export const statusOptions: BaseOptionType[] = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
  { value: 'Strike Off', label: 'Strike Off' },
];

export const listingStatusOptions: BaseOptionType[] = [
  { value: 'LISTED', label: 'Listed' },
  { value: 'UNLISTED', label: 'Unlisted' },
];

export const sumOfChargesOptionsMappings = {
  less_than_1cr: {
    min: 0,
    max: 1e7,
  },
  less_than_5cr: {
    min: 0,
    max: 5e7,
  },
  '1_2.5cr': {
    min: 1e7,
    max: 2.5e7,
  },
  '2.5_5cr': {
    min: 2.5e7,
    max: 5e7,
  },
  '5_10cr': {
    min: 5e7,
    max: 1e8,
  },
  '5_25cr': {
    min: 5e7,
    max: 2.5e8,
  },
  '10_25cr': {
    min: 1e8,
    max: 2.5e8,
  },
  '25_100cr': {
    min: 2.5e8,
    max: 1e9,
  },
  '100cr_plus': {
    min: 1e9,
    max: undefined,
  },
  '100_500cr': {
    min: 1e9,
    max: 5e9,
  },
  '500cr_plus': {
    min: 5e9,
    max: undefined,
  },
};

export const revenueOptions = [
  { value: 'less_than_5cr', label: '<5 Cr' },
  { value: '5_25cr', label: '5-25 Cr' },
  { value: '25_100cr', label: '25-100 Cr' },
  { value: '100_500cr', label: '100-500 Cr' },
  { value: '500cr_plus', label: '500 Cr+' },
];

export const sumOfChargesOptions = [
  { value: 'less_than_1cr', label: '<1 Cr' },
  { value: 'less_than_5cr', label: '<5 Cr' },
  { value: '1_2.5cr', label: '1-2.5 Cr' },
  { value: '2.5_5cr', label: '2.5-5 Cr' },
  { value: '5_10cr', label: '5-10 Cr' },
  { value: '10_25cr', label: '10-25 Cr' },
  { value: '25_100cr', label: '25-100 Cr' },
  { value: '100cr_plus', label: '100 Cr+' },
];

export const creditRatingOptions = [
  { value: 'A', label: 'A' },
  { value: 'AA', label: 'AA' },
  { value: 'AAA', label: 'AAA' },
  { value: 'B', label: 'B' },
  { value: 'BB', label: 'BB' },
  { value: 'BBB', label: 'BBB' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
  { value: 'not_rated', label: 'Not Rated' },
];

export const MONTHS = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
] as const;
