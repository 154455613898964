import { ChildProp } from '@app/types';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import CreditComponent from '@app/components/credits/Credit';
import { SearchBar } from '@components/spotlight-search';
import classNames from 'classnames';
import { RequestCompanyModal } from '@app/components/request-company';
import { CreditsSuccessModal } from '@app/components/credits/CreditsSuccessModal';

export const HomeLayout = ({ children }: ChildProp) => {
  const isSearchFocused = useSelector((state: RootState) => state.auth.isBlur);
  const { unlockReport } = useSelector((state: RootState) => state.util);

  return (
    <div className='flex flex-col h-screen text-black bg-brand_color w-[calc(100vw-4rem)] overflow-x-auto'>
      <header
        className={classNames(
          'fixed flex justify-evenly  w-[calc(100vw-5.6rem)] z-[9999] right-4',
          {
            '!z-0': unlockReport,
          },
        )}
      >
        <RequestCompanyModal />
        <div
          className={classNames(
            'flex justify-between w-full relative py-2 bg-white top-[0.3rem] rounded-md',
            {
              '!bg-opacity-0': isSearchFocused,
            },
          )}
        >
          <SearchBar />
          <div
            className={classNames({
              'blur-[3px]': isSearchFocused,
            })}
          >
            <CreditComponent />
            <CreditsSuccessModal />
          </div>
        </div>
      </header>
      <div
        className={classNames(
          'flex w-full flex-col overflow-auto gap-4 p-3 rounded-tl-xl bg-white h-full px-8 mt-[0.3rem]',
          {
            'blur-[3px] p-0': isSearchFocused,
          },
        )}
      >
        {children}
      </div>
    </div>
  );
};
