import React from 'react';
import Icon from '@components/lib-components/icon/Icon';
import { HighlightItem } from './types';

interface StyleConfig {
  bgColor: string;
  textColor: string;
  icon: string;
}

const getStyleConfig = (type: string): StyleConfig => {
  switch (type) {
    case 'success':
      return {
        bgColor: 'bg-green-50',
        textColor: 'text-green-700',
        icon: 'CheckCircleIcon',
      };
    case 'warning':
      return {
        bgColor: 'bg-yellow-50',
        textColor: 'text-yellow-700',
        icon: 'ExclamationCircleIcon',
      };
    default:
      return {
        bgColor: 'bg-red-50',
        textColor: 'text-red-700',
        icon: 'FlagIcon',
      };
  }
};

interface ComplianceHighlightItemProps {
  item: HighlightItem;
}

export const ComplianceHighlightItem: React.FC<ComplianceHighlightItemProps> = ({ item }) => {
  const { bgColor, textColor, icon } = getStyleConfig(item.type);

  return (
    <div className={`${bgColor} p-3 rounded-lg flex items-center`}>
      <div>
        <h4 className={`text-sm font-semibold ${textColor} pb-1`}>{`${item.title} >`}</h4>
        <p className={`flex text-md font-semibold ${textColor} items-center`}>
          {item.value}
          <Icon
            icon={icon as 'CheckCircleIcon' | 'ExclamationCircleIcon' | 'FlagIcon'}
            className={`${textColor} mx-1`}
            size={20}
          />
        </p>
      </div>
    </div>
  );
};
