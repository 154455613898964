import { ValidTab } from '@components/lib-components/tab-menu/tabContainer.types';
import Fuse from 'fuse.js';

export const COMPANY_TYPE = {
  LLP: 'LLP',
  PRIVATE: 'PRIVATE',
  PUBLIC: 'PUBLIC',
} as const;

export type CompanyType = keyof typeof COMPANY_TYPE;

const BASE_MAPPINGS = {
  charges: {
    title: 'Charges',
    fields: ['Charges'],
    route: 'charges',
  },
  compliances: {
    title: 'Compliances',
    fields: ['Licenses', 'Legal History', 'EPFO', 'GST', 'Principal Business Activity'],
    route: 'compliances',
  },
  documents: {
    title: 'Documents',
    fields: ['Financial Statements', 'Incorporation Documents'],
    route: 'documents',
  },
  creditRating: {
    title: 'Credit Rating',
    fields: ['Credit Rating', 'Brickwork', 'Crisil', 'ICRA', 'CARE', 'India', 'Infomerics'],
    route: 'compliances',
  },
};

const LLP_MAPPINGS = {
  ...BASE_MAPPINGS,
  management: {
    title: 'Management',
    fields: ['Partner network', 'Current partners', 'Former partners'],
    route: 'management',
  },
  shareholdings: {
    title: 'Shareholdings',
    fields: ['Individual Partners', 'Body Corporate Partners'],
    route: 'shareholdings',
  },
  financials: {
    title: 'Financials',
    fields: [
      'Balance Sheet',
      'Auditor Information',
      'Detailed Financial Data',
      'Profit & Loss',
      'Revenue Breakup',
      'Assets',
      'Liabilities',
    ],
    route: 'financial',
  },
};

const COMPANY_MAPPINGS = {
  ...BASE_MAPPINGS,
  management: {
    title: 'Management',
    fields: ['Director network', 'Current directors', 'Former directors'],
    route: 'management',
  },
  shareholdings: {
    title: 'Shareholdings',
    fields: [
      'Shareholding Pattern',
      'Top Holdings',
      'Directors Shareholdings',
      'Other Shareholdings',
      'Pattern Summary',
    ],
    route: 'shareholdings',
  },
  financials: {
    title: 'Financials',
    fields: [
      'Balance Sheet',
      'Auditor Information',
      'Detailed Financial Data',
      'Profit & Loss',
      'Revenue Breakup',
      'Assets',
      'Liabilities',
      'Cash Flow',
    ],
    route: 'financial',
  },
  financialAnalysis: {
    title: 'Financial Analysis',
    fields: ['Financial Analysis', 'Financial Ratios'],
    route: 'financial-analysis',
  },
};

export const getSpotlightSearchMappings = (companyType?: string) => {
  if (companyType?.toUpperCase().includes('LLP')) {
    return LLP_MAPPINGS;
  }
  return COMPANY_MAPPINGS;
};

export function searchAndGetRoute(
  keyword: string,
  companyType?: string,
): ISpotlightSearchResult[] | null {
  const mappings = getSpotlightSearchMappings(companyType);
  const searchData = Object.entries(mappings).flatMap(([key, value]) =>
    [value.title, ...value.fields].map((field) => ({
      key,
      field,
      route: value.route,
    })),
  );

  // Initialize fuse with current company type's search data
  const fuse = new Fuse(searchData, {
    keys: ['field'],
    threshold: 0.3,
    ignoreLocation: true,
    minMatchCharLength: 2,
  });

  const results = fuse.search(keyword);

  if (results.length > 0) {
    const uniqueResults = new Map();
    results.forEach((result) => {
      if (!uniqueResults.has(result.item.field)) {
        uniqueResults.set(result.item.field, {
          ...result.item,
          title: mappings[result.item.key as keyof typeof mappings].title,
        });
      }
    });
    return Array.from(uniqueResults.values()) as ISpotlightSearchResult[];
  }
  return null;
}

export interface ISpotlightSearchResult {
  key: string;
  field: string;
  route: ValidTab;
  title: string;
}
