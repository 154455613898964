import React from 'react';
import { RiskType, Status } from '@app/types/charges';
import { LegalHistoryStatus } from '@app/types/compliances';
import './Badge.css';
import classNames from 'classnames';
import { convertToTitleCase } from '@components/utils/commonUtils';

interface Props {
  status: Status | RiskType | LegalHistoryStatus | 'Pending' | 'Approved';
}

const Badge: React.FC<Props> = ({ status }) => {
  const getBadgeStyle = (status: Props['status']) => {
    switch (status) {
      case 'MODIFICATION':
      case 'settlement':
        return 'bg-utility-warning-50 text-utility-warning-700 border border-utility-warning-200';
      case 'CREATION':
      case 'low_risk':
      case 'diposed':
        return 'bg-utility-blue-50 text-utility-blue-700 border border-utility-blue-200';
      case 'SATISFACTION':
      case 'Active':
        return 'bg-utility-success-50 text-utility-success-700 border border-utility-success-200';
      case 'Cancelled':
      case 'medium_risk':
      case 'rejected':
        return 'bg-yellow-50 text-yellow-600 border border-yellow-300';
      case 'Approved':
        return 'bg-utility-success-50 text-utility-success-700 text-sm';
      case 'Pending':
      case 'high_risk':
      case 'pending':
        return 'bg-red-50 text-red-600 border border-red-300';
      case 'not_available':
        return 'bg-gray-100 text-gray-600 border border-gray-300';
      default:
        return 'bg-gray-100 text-gray-600 border border-gray-300';
    }
  };

  return (
    <span
      className={classNames(
        'inline-flex px-1.5 py-0.5 rounded-md items-center gap-1 whitespace-nowrap',
        getBadgeStyle(status),
      )}
    >
      <div className='inline-block w-[6px] h-[6px] rounded-full bg-current'></div>
      <span className='font-medium text-sm'>{convertToTitleCase(status)}</span>
    </span>
  );
};

export default Badge;
