import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import authSlice from './slices/authSlice';
import tokenLedgerApi from './api/tokenLedger/tokenLedgerApi';
import companySlice from './slices/companySlice';
import utilSlice from './slices/utilSlice';
import requestCompanyApi from './api/AdhocTask/requestAdhocTasks';
import authApi from './api/authApi/authApi';
import companyApi from './api/companyApi';
import { templateApi } from './api/template/templateApi';

const utilPersistConfig = {
  key: 'util',
  version: 1,
  storage,
  blacklist: [
    'isRequestNewCompanyModalOpen',
    'identifierType',
    'companyType',
    'showNudge',
    'reportStatus',
    'isReportFetching',
  ],
};

const persistedUtilReducer = persistReducer(utilPersistConfig, utilSlice);

export const store = configureStore({
  reducer: {
    auth: authSlice,
    util: persistedUtilReducer,
    company: companySlice.reducer,
    [tokenLedgerApi.reducerPath]: tokenLedgerApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [companyApi.reducerPath]: companyApi.reducer,
    [requestCompanyApi.reducerPath]: requestCompanyApi.reducer,
    [templateApi.reducerPath]: templateApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, REGISTER, PURGE, REHYDRATE],
      },
    })
      .concat(tokenLedgerApi.middleware)
      .concat(authApi.middleware)
      .concat(companyApi.middleware)
      .concat(requestCompanyApi.middleware)
      .concat(templateApi.middleware),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
