import React, { memo } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';

interface DataPoint {
  name: string;
  value: number;
  color: string;
}

interface ShareholdingPieChartProps {
  data: DataPoint[];
  title?: string;
  type?: 'summary' | 'directors';
}

interface LabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  value: number;
  name: string;
  index: number;
  payload: DataPoint;
}

const COLORS = {
  summary: ['#10B981', '#3B82F6'],
  directors: [
    '#10B981',
    '#3B82F6',
    '#8B5CF6',
    '#EC4899',
    '#F59E0B',
    '#06B6D4',
    '#6366F1',
    '#84CC16',
  ],
};

const DirectorsLabel: React.FC<LabelProps & { sortedData: DataPoint[] }> = ({
  cx,
  cy,
  midAngle,
  outerRadius,
  value,
  name,
  index,
  sortedData,
}) => {
  const RADIAN = Math.PI / 180;
  const isLeftSide = midAngle > 90 && midAngle < 270;

  const labelDistance = 200;
  const x = cx + (isLeftSide ? -labelDistance : labelDistance);

  const verticalSpacing = 25;
  const baseY = cy - (sortedData.length * verticalSpacing) / 4;
  const y = baseY + index * verticalSpacing;

  const pieEdgeX = cx + outerRadius * Math.cos(-midAngle * RADIAN);
  const pieEdgeY = cy + outerRadius * Math.sin(-midAngle * RADIAN);

  const controlX = cx + (isLeftSide ? -100 : 100);
  const controlY = y;

  return (
    <g>
      <path
        d={`M ${pieEdgeX},${pieEdgeY} Q ${controlX},${controlY} ${x},${y}`}
        stroke='#94A3B8'
        strokeWidth={1}
        fill='none'
        strokeDasharray='5,5'
      />
      <circle cx={x} cy={y} r={2} fill='#94A3B8' />
      <text
        x={x + (isLeftSide ? -8 : 8)}
        y={y}
        fill='#64748B'
        textAnchor={isLeftSide ? 'end' : 'start'}
        dominantBaseline='central'
        className='text-xs font-medium'
        style={{ letterSpacing: '0.025em' }}
      >
        {`${name} (${value.toFixed(2)}%)`}
      </text>
    </g>
  );
};

interface LegendProps {
  data: DataPoint[];
}

const Legend = memo(({ data }: LegendProps) => (
  <div className='flex justify-center items-center gap-8 mt-6'>
    {data.map((entry, index) => (
      <div key={`legend-${index}`} className='flex items-center gap-3 group'>
        <div
          className='w-4 h-4 rounded-full transition-transform duration-200 group-hover:scale-110'
          style={{ backgroundColor: entry.color }}
        />
        <span className='text-sm text-gray-600 font-medium transition-colors duration-200 group-hover:text-gray-900'>
          {entry.name} ({entry.value.toFixed(2)}%)
        </span>
      </div>
    ))}
  </div>
));

Legend.displayName = 'Legend';

export const ShareholdingPieChart: React.FC<ShareholdingPieChartProps> = ({
  data,
  title,
  type = 'summary',
}) => {
  const sortedData = React.useMemo(() => [...data].sort((a, b) => b.value - a.value), [data]);

  const chartData = React.useMemo(() => {
    return sortedData.map((item, index) => ({
      ...item,
      color: COLORS[type][index % COLORS[type].length],
    }));
  }, [sortedData, type]);

  return (
    <div className='bg-white'>
      {title && <p className='text-gray-700 text-lg font-medium text-center mb-4'>{title}</p>}
      <div className='h-[200px] w-full'>
        <ResponsiveContainer width='100%' height='100%'>
          <PieChart>
            <defs>
              <filter id='shadow' height='200%'>
                <feDropShadow dx='0' dy='3' stdDeviation='3' floodOpacity='0.1' />
              </filter>
            </defs>
            <Pie
              data={chartData}
              cx='50%'
              cy='50%'
              innerRadius={50}
              outerRadius={100}
              paddingAngle={4}
              dataKey='value'
              label={
                type === 'directors'
                  ? (props) => <DirectorsLabel {...props} sortedData={chartData} />
                  : undefined
              }
              animationBegin={0}
              animationDuration={1200}
              animationEasing='ease-out'
              filter='url(#shadow)'
            >
              {chartData.map((entry, index) => (
                <Cell
                  style={{ outline: 'none' }}
                  key={`cell-${index}`}
                  fill={entry.color}
                  className='transition-opacity duration-200 hover:opacity-80'
                />
              ))}
            </Pie>
            <Tooltip
              contentStyle={{
                backgroundColor: '#fff',
                border: 'none',
                borderRadius: '12px',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                padding: '12px 16px',
              }}
              formatter={(value: number, name: string) => [`${value.toFixed(2)}%`, name]}
              labelStyle={{
                color: '#64748B',
                fontWeight: 500,
                marginBottom: '4px',
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
      {type === 'summary' && <Legend data={chartData} />}
    </div>
  );
};
