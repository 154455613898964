import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '@app/store/axiosBase';
import {
  OrderResponse,
  OrderPayload,
  UserReportsLedgerApi,
  CompanyReportsLedgerApi,
  Pagination,
  TokenLedgerEntry,
  RequestCompanyReport,
  CustomerCompanyTokenBalanceJSON,
  AdhocRequestType,
} from './types';
import { ApiResponse } from '@app/types';

const tokenLedgerApi = createApi({
  reducerPath: 'tokenLedgerApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['company-request', 'my-list-company', 'org-list-company'],
  endpoints: (builder) => ({
    getTokenBalance: builder.query<ApiResponse<CustomerCompanyTokenBalanceJSON>, string>({
      query: (id: string) => ({
        url: `customer-company-token-balance/get-by-company-id?company_id=${id}`,
        method: 'get',
      }),
    }),
    getCompanyTokenOrderDetails: builder.query<ApiResponse<TokenLedgerEntry>, Pagination>({
      query: (pagination: Pagination) => ({
        url: '/customer-company-token-order-ledger/get-all-by-company',
        method: 'get',
        params: {
          skip: pagination.skip,
          limit: pagination.limit,
        },
      }),
    }),
    getUserReportsTable: builder.query<UserReportsLedgerApi, Pagination>({
      query: (pagination: Pagination) => ({
        url: `/customer-user-report-order-ledger/get-all-by-user?skip=${pagination.skip}&limit=${pagination.limit}`,
        method: 'get',
      }),
      providesTags: ['my-list-company'],
    }),
    getCompanyReportsTable: builder.query<CompanyReportsLedgerApi, Pagination>({
      query: (pagination: Pagination) => ({
        url: `/customer-user-report-order-ledger/get-all-by-company?skip=${pagination.skip}&limit=${pagination.limit}`,
        method: 'get',
      }),
      providesTags: ['org-list-company'],
    }),
    getCustomerRequestCompany: builder.query<ApiResponse<RequestCompanyReport[]>, Pagination>({
      query: (pagination: Pagination) => ({
        url: 'request_company/get-customer-request-company',
        method: 'get',
        params: {
          skip: pagination.skip,
          limit: pagination.limit,
          adhoc_request_type: AdhocRequestType.REQUEST_NEW_COMPANY,
        },
      }),
      providesTags: ['company-request'],
    }),
    postPlaceReportOrder: builder.mutation<OrderResponse, OrderPayload>({
      query: (payload) => ({
        url: 'customer-user-report-order-ledger/place-report-order',
        method: 'post',
        data: {
          ...payload,
        },
      }),
    }),
  }),
});

const {
  useGetCompanyTokenOrderDetailsQuery,
  usePostPlaceReportOrderMutation,
  useGetTokenBalanceQuery,
  useLazyGetTokenBalanceQuery,
  useLazyGetCompanyReportsTableQuery,
  useLazyGetCompanyTokenOrderDetailsQuery,
  useLazyGetUserReportsTableQuery,
  useGetCompanyReportsTableQuery,
  useGetUserReportsTableQuery,
  useGetCustomerRequestCompanyQuery,
} = tokenLedgerApi;

export {
  useGetCompanyTokenOrderDetailsQuery,
  usePostPlaceReportOrderMutation,
  useGetTokenBalanceQuery,
  useLazyGetTokenBalanceQuery,
  useLazyGetCompanyReportsTableQuery,
  useLazyGetCompanyTokenOrderDetailsQuery,
  useLazyGetUserReportsTableQuery,
  useGetCompanyReportsTableQuery,
  useGetUserReportsTableQuery,
  useGetCustomerRequestCompanyQuery,
};
export default tokenLedgerApi;
