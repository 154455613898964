import React from 'react';
import classNames from 'classnames';
import { CourtCases, CaseWithFlags } from '@app/types/legal';
import Icon from '@components/lib-components/icon/Icon';
import Modal from '@components/lib-components/modal/Modal';
import { formatDate } from '@components/utils/commonUtils';

interface CourtCasesModalProps {
  isOpen: boolean;
  onClose: () => void;
  courtCases: CourtCases[];
  title: string;
  onPreviewClick: (url: string) => void;
}

export const CourtCasesModal: React.FC<CourtCasesModalProps> = ({
  isOpen,
  onClose,
  courtCases,
  title,
  onPreviewClick,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      title={title}
      titleClass='font-semibold ml-5'
      onClose={onClose}
      className='w-full max-w-screen-lg z-10'
    >
      <div className='p-6'>
        <div className='space-y-6'>
          {courtCases.map((courtCase, index) => (
            <div key={index}>
              {courtCase.cases.map((caseItem: CaseWithFlags, caseIndex: number) => (
                <div
                  key={caseIndex}
                  className={classNames(
                    'bg-white rounded-lg p-4 shadow mb-4',
                    'border border-gray-100',
                  )}
                >
                  <div
                    className={classNames(
                      'flex items-center gap-2 mb-3 pb-3',
                      'border-b border-gray-200',
                    )}
                  >
                    <span className='font-semibold'>Case Number</span>
                    <p>{caseItem.case.case_number}</p>
                  </div>
                  <div className='grid grid-cols-2 gap-4 mt-5'>
                    <div>
                      <div className='mb-2'>
                        <span className='font-semibold'>Case Year</span>
                        <p>{caseItem.case.case_year}</p>
                      </div>
                      <div className='mb-2'>
                        <span className='font-semibold'>Filing Date</span>
                        <p>{formatDate(caseItem.case.filing_date ?? '')}</p>
                      </div>
                      <div className='mb-2'>
                        <span className='font-semibold'>Next Hearing</span>
                        <p>{formatDate(caseItem.case.next_hearing_date ?? '')}</p>
                      </div>
                    </div>
                    <div>
                      <div className='mb-2'>
                        <span className='font-semibold'>Petitioner</span>
                        <p>{caseItem.case.petitioner || 'Not Available'}</p>
                      </div>
                      <div className='mb-2'>
                        <span className='font-semibold'>Respondent</span>
                        <p>{caseItem.case.respondent}</p>
                      </div>
                      <div className='mb-2'>
                        <span className='font-semibold'>Act</span>
                        <p>{caseItem.case.act || 'Not Available'}</p>
                      </div>
                      {caseItem.case.section && (
                        <div className='mb-2'>
                          <span className='font-semibold'>Section</span>
                          <p>{caseItem.case.section}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='mt-4 flex justify-between'>
                    <div>
                      <div className='font-semibold mt-5 mb-2'>Risk Indicators</div>
                      <div className='space-y-1'>
                        {caseItem.highlight_flag_info.map((flag, flagIndex) => (
                          <div key={flagIndex} className='flex items-center gap-2 text-sm'>
                            <Icon
                              icon={
                                flag.risk_category === 'HIGH_RISK_FLAG'
                                  ? 'FlagIcon'
                                  : 'ExclamationTriangleIcon'
                              }
                              className={classNames({
                                'text-red-500': flag.risk_category === 'HIGH_RISK_FLAG',
                                'text-yellow-500': flag.risk_category !== 'HIGH_RISK_FLAG',
                              })}
                              size={16}
                            />
                            <span
                              className={classNames({
                                'text-red-500': flag.risk_category === 'HIGH_RISK_FLAG',
                                'text-yellow-500': flag.risk_category !== 'HIGH_RISK_FLAG',
                              })}
                            >
                              {flag.reason}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div>
                        <button
                          onClick={() => onPreviewClick(caseItem.case.link)}
                          className={classNames(
                            'text-blue-900 flex items-center gap-2 mt-4',
                            'bg-transparent',
                          )}
                        >
                          <Icon icon='ArrowTopRightOnSquareIcon' size={16} />
                          View Case Details
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};
