import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePostHog } from 'posthog-js/react';
import classNames from 'classnames';
import { RootState } from '@app/store/store';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';
import { formatDate } from '@components/utils/commonUtils';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import Loading from '@components/lib-components/loading/Loading';
import { Dropdown } from '@app/components/lib-components/drop-down/Dropdown';
import { FinancialExpandableTable } from './FinancialExpandableTable';
import AuditorTable, { ExtendedMetadata } from './AuditorTable';
import { FinancialOverviewAccordion } from '@components/lib-components/accordian/FinancialOverviewAccordion';

import Button from '@components/lib-components/button/Button';
import { ApiResponse } from '@app/types';
import {
  CompanyFinancialData,
  LLPFinancialData,
  CompanyFinancialsResponse,
  LLPFinancialsResponse,
  StandaloneFinancialParametersResponse,
  ConsolidatedFinancialParametersResponse,
} from '@app/types/financial';
import Icon from '@components/lib-components/icon/Icon';
import { BaseOptionType } from '@components/lib-components/drop-down/Dropdown';
import RelatedPartyTable from './RelatedPartyTable';
import { MultiValue, SingleValue } from 'react-select';
import { FinancialTable } from './FinancialTable';

export type FigureFormat = 'thousands' | 'lacs' | 'millions' | 'crores';
export type FinancialTabs = 'pnl' | 'balance_sheet' | 'cash_flow';
export type FinancialSubTabs =
  | 'line_items'
  | 'revenue_breakup'
  | 'assets'
  | 'liabilities'
  | 'subTotals'
  | 'cash_flow';
type NatureType = 'STANDALONE' | 'CONSOLIDATED';

interface Option extends BaseOptionType {
  value: FigureFormat;
  label: string;
}

interface YearOption extends BaseOptionType {
  value: string;
  label: string;
}

const tabs = [
  { id: 'pnl' as FinancialTabs, text: 'Profit & Loss' },
  { id: 'balance_sheet' as FinancialTabs, text: 'Balance Sheet' },
  { id: 'cash_flow' as FinancialTabs, text: 'Cash Flow' },
];

const formatOptions: Option[] = [
  { value: 'thousands', label: 'in Thousands' },
  { value: 'lacs', label: 'in Lacs' },
  { value: 'millions', label: 'in Millions' },
  { value: 'crores', label: 'in Crores' },
];

const FinancialTab: React.FC = () => {
  const [selectedFigureFormat, setSelectedFigureFormat] = useState<FigureFormat>('thousands');
  const [selectedTab, setSelectedTab] = useState<FinancialTabs>('pnl');
  const [selectedNatureType, setSelectedNatureType] = useState<NatureType>('STANDALONE');
  const [userSelectedFormat, setUserSelectedFormat] = useState<boolean>(false);
  const [selectedYears, setSelectedYears] = useState<YearOption[]>([]);

  const { id } = useParams<{ id: string }>();
  const posthog = usePostHog();
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const companyType = useSelector((state: RootState) => state.util.companyType);

  const getFinancialsData =
    useGetCompanyData<ApiResponse<CompanyFinancialsResponse | LLPFinancialsResponse>>();
  const getConsolidatedFinancialsData = useGetCompanyData<ApiResponse<CompanyFinancialsResponse>>();

  const { data: financialsData, isLoading: isFinancialsLoading } = getFinancialsData({
    cinId: id as string,
    field_name:
      companyType === COMPANY_TYPE.LimitedLiabilityPartnership
        ? 'financials'
        : 'financials_standalone',
    identifierType,
  });
  const { data: consolidatedData, isLoading: isConsolidatedLoading } =
    getConsolidatedFinancialsData(
      {
        cinId: id as string,
        field_name: 'financials_consolidated',
        identifierType,
      },
      {
        skip: companyType === COMPANY_TYPE.LimitedLiabilityPartnership,
      },
    );

  const isInProgress = (data?: CompanyFinancialsResponse | LLPFinancialsResponse) =>
    data?.status === 'INITIALISED' || data?.status === 'IN_PROGRESS';

  const standaloneDataProgress = isInProgress(
    financialsData?.response_data as CompanyFinancialsResponse,
  );
  const consolidatedDataProgress = isInProgress(
    consolidatedData?.response_data as CompanyFinancialsResponse,
  );
  const isReportFetching = standaloneDataProgress || consolidatedDataProgress;

  const hasStandaloneData = useMemo(() => {
    if (companyType === COMPANY_TYPE.LimitedLiabilityPartnership) {
      const llpFinancials = (financialsData?.response_data as LLPFinancialsResponse)?.financials;
      if (!llpFinancials) return false;
      return Object.values(llpFinancials).some((data) => data !== null);
    }
    const standaloneFinancials = (financialsData?.response_data as CompanyFinancialsResponse)
      ?.financials_standalone;
    if (!standaloneFinancials) return false;
    return Object.values(standaloneFinancials).some((data) => data !== null);
  }, [financialsData, companyType]);

  const hasConsolidatedData = useMemo(() => {
    if (companyType === COMPANY_TYPE.LimitedLiabilityPartnership) return false;

    const consolidatedFinancials = (consolidatedData?.response_data as CompanyFinancialsResponse)
      ?.financials_consolidated;
    if (!consolidatedFinancials) return false;

    return Object.values(consolidatedFinancials).some((data) => data !== null);
  }, [consolidatedData, companyType]);

  const filteredFinancialsData = useMemo(() => {
    const getFinancialData = () => {
      if (companyType === COMPANY_TYPE.LimitedLiabilityPartnership) {
        const llpData = (financialsData?.response_data as LLPFinancialsResponse)?.financials;
        return Object.entries(llpData || {})
          .map(([year, yearData]) => ({
            year: parseInt(year),
            data: yearData.data,
            metadata: yearData.metadata,
          }))
          .sort((a, b) => b.year - a.year);
      }

      const isStandalone = selectedNatureType === 'STANDALONE' && hasStandaloneData;
      const isConsolidated = selectedNatureType === 'CONSOLIDATED' && hasConsolidatedData;

      if (isStandalone) {
        const standaloneData = (financialsData?.response_data as CompanyFinancialsResponse)
          ?.financials_standalone;
        return Object.entries(standaloneData || {})
          .filter(([, yearData]) => yearData !== null)
          .map(([year, yearData]) => ({
            year: parseInt(year),
            data: yearData!.data,
            metadata: yearData!.metadata,
          }))
          .sort((a, b) => b.year - a.year);
      } else if (isConsolidated) {
        const consolidatedDataProgress = (
          consolidatedData?.response_data as CompanyFinancialsResponse
        )?.financials_consolidated;
        return Object.entries(consolidatedDataProgress || {})
          .filter(([, yearData]) => yearData !== null)
          .map(([year, yearData]) => ({
            year: parseInt(year),
            data: yearData!.data,
            metadata: yearData!.metadata,
          }))
          .sort((a, b) => b.year - a.year);
      }

      return [];
    };

    return getFinancialData();
  }, [
    financialsData,
    consolidatedData,
    selectedNatureType,
    hasStandaloneData,
    hasConsolidatedData,
    companyType,
  ]);

  useEffect(() => {
    if (!hasConsolidatedData && selectedNatureType === 'CONSOLIDATED') {
      setSelectedNatureType('STANDALONE');
    }
  }, [hasConsolidatedData, selectedNatureType]);

  useEffect(() => {
    if (!userSelectedFormat && filteredFinancialsData.length > 0) {
      const mostRecentData = filteredFinancialsData[0].data;
      let maxValue = 0;

      if (companyType === COMPANY_TYPE.LimitedLiabilityPartnership) {
        const llpData = mostRecentData as LLPFinancialData;
        const lineItems = llpData?.statement_of_income_and_expenditure?.line_items;
        if (lineItems) {
          maxValue = Math.max(
            ...Object.values(lineItems)
              .filter((val): val is number => typeof val === 'number' && !isNaN(val))
              .map(Math.abs),
          );
        }
      } else {
        const companyData = mostRecentData as CompanyFinancialData;
        const lineItems = companyData?.profit_and_loss?.line_items;
        if (lineItems) {
          maxValue = Math.max(
            ...Object.values(lineItems)
              .filter((val): val is number => typeof val === 'number' && !isNaN(val))
              .map(Math.abs),
          );
        }
      }

      if (maxValue >= 10000000) {
        setSelectedFigureFormat('crores');
      } else if (maxValue >= 1000000) {
        setSelectedFigureFormat('millions');
      } else if (maxValue >= 100000) {
        setSelectedFigureFormat('lacs');
      } else {
        setSelectedFigureFormat('thousands');
      }
    }
  }, [filteredFinancialsData, userSelectedFormat, companyType]);

  const handleSelectedTabChange = (tabId: FinancialTabs) => {
    setSelectedTab(tabId);
  };

  const handleFigureFormatChange = (selectedOption: SingleValue<BaseOptionType>) => {
    const option = selectedOption as SingleValue<Option>;
    if (option) {
      setSelectedFigureFormat(option.value as FigureFormat);
      setUserSelectedFormat(true);
    }
  };

  const yearOptions = useMemo(() => {
    return filteredFinancialsData
      .filter((yearData) => {
        if (companyType === COMPANY_TYPE.LimitedLiabilityPartnership) {
          return yearData.data !== null;
        }
        const companyData = yearData.data as CompanyFinancialData;
        return companyData?.profit_and_loss || companyData?.balance_sheet;
      })
      .map((yearData) => ({
        value: yearData.year.toString(),
        label: yearData.year.toString() || yearData.metadata.financial_year,
      }));
  }, [filteredFinancialsData, companyType]);

  const handleYearSelection = (selected: MultiValue<BaseOptionType>) => {
    setSelectedYears(selected as YearOption[]);
  };

  const selectedYearsData = useMemo(() => {
    if (selectedYears.length === 0) return filteredFinancialsData;

    return filteredFinancialsData.filter((yearData) =>
      selectedYears.some((selected) => selected.value === yearData.year.toString()),
    );
  }, [filteredFinancialsData, selectedYears]);

  const getStandaloneParameters =
    useGetCompanyData<ApiResponse<StandaloneFinancialParametersResponse>>();
  const getConsolidatedParameters =
    useGetCompanyData<ApiResponse<ConsolidatedFinancialParametersResponse>>();

  const { data: standaloneParameters, isLoading: isStandaloneParametersLoading } =
    getStandaloneParameters(
      {
        cinId: id as string,
        field_name: 'financials_standalone_parameters',
        identifierType,
      },
      { skip: companyType === COMPANY_TYPE.LimitedLiabilityPartnership },
    );

  const { data: consolidatedParameters, isLoading: isConsolidatedParametersLoading } =
    getConsolidatedParameters(
      {
        cinId: id as string,
        field_name: 'financials_consolidated_parameters',
        identifierType,
      },
      { skip: companyType === COMPANY_TYPE.LimitedLiabilityPartnership },
    );

  const getFinancialParametersData = useMemo(() => {
    if (companyType === COMPANY_TYPE.LimitedLiabilityPartnership) return [];

    const parametersData =
      selectedNatureType === 'STANDALONE'
        ? standaloneParameters?.response_data?.financials_standalone_parameters
        : consolidatedParameters?.response_data?.financials_consolidated_parameters;

    if (!parametersData) return [];

    return Object.entries(parametersData)
      .filter(([year]) => {
        if (selectedYears.length === 0) return true;
        return selectedYears.some((selected) => selected.value === year.split('-')[0]);
      })
      .map(([year, yearData]) => {
        if (!yearData?.data || !yearData?.metadata) return null;
        return {
          year: parseInt(year.split('-')[0]),
          data: yearData?.data,
          metadata: yearData?.metadata,
        };
      })
      .filter((item): item is NonNullable<typeof item> => item !== null)
      .sort((a, b) => b.year - a.year);
  }, [
    selectedNatureType,
    standaloneParameters,
    consolidatedParameters,
    companyType,
    selectedYears,
  ]);

  const hasParametersData = useMemo(() => {
    return getFinancialParametersData.length > 0;
  }, [getFinancialParametersData]);

  const handleNatureTypeChange = (type: NatureType) => {
    setSelectedNatureType(type);
    setSelectedYears([]);
    posthog.capture('financial_tab', { subTab: type });
  };

  if (isReportFetching) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2 h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage message='Kindly wait a few minutes, we are fetching the company financials data for you.' />
      </div>
    );
  }

  if (isFinancialsLoading || isConsolidatedLoading) {
    return <Loading />;
  }

  if (!hasStandaloneData && !hasConsolidatedData) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2 h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage
          message={
            companyType === COMPANY_TYPE.LimitedLiabilityPartnership
              ? 'Financial data not available for this LLP, please try again in some time.'
              : selectedNatureType === 'CONSOLIDATED'
                ? 'Consolidated financial data not available for this company.'
                : 'Financial data not available, please try again in some time.'
          }
        />
      </div>
    );
  }

  const latestFinancialData = filteredFinancialsData[0];
  const lastFilingDate = latestFinancialData?.metadata?.financial_year
    ? formatDate(latestFinancialData.metadata.financial_year)
    : '';

  return (
    <div className='w-full py-4'>
      <h2 className='text-md text-heading font-semibold my-3'>
        Financials Overview {lastFilingDate && `(${lastFilingDate})`}
      </h2>

      {companyType !== COMPANY_TYPE.LimitedLiabilityPartnership && (
        <>
          <div className='my-2 inline-flex rounded-md shadow-sm'>
            {['STANDALONE', 'CONSOLIDATED'].map((type) => (
              <Button
                key={type}
                text={type.charAt(0) + type.slice(1).toLowerCase()}
                variant='text'
                className={classNames(
                  'whitespace-nowrap font-bold py-3 px-4 border-2 text-gray-700 transition-all duration-200 no-underline hover:no-underline',
                  {
                    'bg-blue-500 text-white border-blue-500 hover:bg-blue-600':
                      selectedNatureType === type,
                    'bg-gray-100 border-gray-300 hover:bg-gray-200': selectedNatureType !== type,
                  },
                  type === 'STANDALONE' ? 'rounded-l-lg' : 'rounded-r-lg border-l-0',
                )}
                onClick={() => handleNatureTypeChange(type as NatureType)}
                disabled={
                  (type === 'STANDALONE' && !hasStandaloneData) ||
                  (type === 'CONSOLIDATED' && !hasConsolidatedData)
                }
              />
            ))}
          </div>
          {(!hasStandaloneData || !hasConsolidatedData) && (
            <div className='flex items-center text-sm text-gray-600 mb-4 justify-start mt-2'>
              <Icon icon='InformationCircleIcon' size={16} className='mr-2 text-blue-500' />
              <span>
                {!hasStandaloneData &&
                  !hasConsolidatedData &&
                  'Standalone and consolidated data not available for this company.'}
                {hasStandaloneData &&
                  !hasConsolidatedData &&
                  'Consolidated data not available for this company.'}
                {!hasStandaloneData &&
                  hasConsolidatedData &&
                  'Standalone data not available for this company.'}
              </span>
            </div>
          )}
        </>
      )}

      <div className='w-full'>
        <FinancialOverviewAccordion
          companyType={companyType}
          data={filteredFinancialsData}
          lastFilingDate={lastFilingDate}
          figureFormat={selectedFigureFormat}
          type='pnl'
        />
        <FinancialOverviewAccordion
          companyType={companyType}
          data={filteredFinancialsData}
          lastFilingDate={lastFilingDate}
          figureFormat={selectedFigureFormat}
          type='assets'
        />
        <FinancialOverviewAccordion
          companyType={companyType}
          data={filteredFinancialsData}
          lastFilingDate={lastFilingDate}
          figureFormat={selectedFigureFormat}
          type='liabilities'
        />

        <div className='flex align-baseline items-center'>
          <p className='text-lg font-semibold mt-7 text-heading'>Detailed Financial Data</p>
        </div>

        <div className='flex justify-between'>
          <div className='my-2 flex gap-2'>
            {tabs
              .filter((tab) => {
                if (tab.id === 'cash_flow') {
                  return filteredFinancialsData.some(
                    (yearData) =>
                      companyType !== COMPANY_TYPE.LimitedLiabilityPartnership &&
                      (yearData.data as CompanyFinancialData).cash_flow_statement !== null &&
                      (yearData.data as CompanyFinancialData).cash_flow_statement !== undefined,
                  );
                }
                return true;
              })
              .map(({ id, text }) => (
                <Button
                  key={id}
                  text={
                    companyType === COMPANY_TYPE.LimitedLiabilityPartnership
                      ? id === 'pnl'
                        ? 'Income and Expenditure'
                        : 'Assets and Liabilities'
                      : text
                  }
                  className={classNames(
                    'whitespace-nowrap font-bold py-3 px-4 mr-2 border-2 rounded-lg mt-auto',
                    '!text-tertiary-600 hover:bg-gray-100 hover:!text-gray-900 !font-medium !text-[16px]',
                    selectedTab === id ? 'bg-secondary' : 'bg-white',
                  )}
                  onClick={() => handleSelectedTabChange(id)}
                />
              ))}
          </div>

          <div className='flex my-2 justify-between'>
            <div className='flex gap-4'>
              <Dropdown
                isMulti={true}
                options={yearOptions}
                value={selectedYears}
                placeholder='Select years to compare'
                onChange={(value) => {
                  handleYearSelection(value as MultiValue<BaseOptionType>);
                }}
                label='Compare Years'
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: 'auto',
                    minWidth: '18rem',
                    height: 'auto',
                  }),
                  multiValue: (base) => ({
                    ...base,
                    flexShrink: 0,
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    flexWrap: 'nowrap',
                    whiteSpace: 'pre',
                    overflow: 'hidden',
                    padding: '2px 30px 2px 10px',
                    maxWidth: '100%',
                    minWidth: '15rem',
                  }),
                }}
              />

              <Dropdown
                options={formatOptions}
                value={formatOptions.find((option) => option.value === selectedFigureFormat)}
                onChange={(value) => {
                  handleFigureFormatChange(value as SingleValue<BaseOptionType>);
                }}
                label='Showing data'
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: 'auto',
                    height: 'auto',
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    flexWrap: 'nowrap',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    padding: '2px 5px 2px 4px',
                    maxWidth: '100%',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  singleValue: (baseStyles) => ({
                    ...baseStyles,
                    color: '#374151',
                    width: '100%',
                  }),
                }}
              />
            </div>
          </div>
        </div>

        <FinancialExpandableTable
          key={`${selectedNatureType}-${selectedTab}`}
          selectedTab={selectedTab}
          tableBody={selectedYearsData}
          figureFormat={selectedFigureFormat}
          companyType={companyType}
        />

        {companyType !== COMPANY_TYPE.LimitedLiabilityPartnership && (
          <>
            <div className='mt-8 mb-4'>
              <h2 className='text-lg font-semibold text-heading'>Financial Parameters</h2>
            </div>
            {isStandaloneParametersLoading || isConsolidatedParametersLoading ? (
              <Loading />
            ) : hasParametersData ? (
              <FinancialTable
                key={`${selectedNatureType}-parameters`}
                tableBody={getFinancialParametersData}
                figureFormat={selectedFigureFormat}
              />
            ) : (
              <div className='w-full border border-gray-200 rounded p-4 text-center text-gray-600'>
                No financial parameters data available
              </div>
            )}
          </>
        )}

        <AuditorTable
          financialData={
            selectedYearsData as {
              year: number;
              data: CompanyFinancialData | LLPFinancialData;
              metadata: ExtendedMetadata;
            }[]
          }
          companyType={companyType}
        />

        {companyType !== COMPANY_TYPE.LimitedLiabilityPartnership && (
          <>
            <div className='mt-8 mb-4'>
              <h2 className='text-lg font-semibold text-heading'>Related Parties</h2>
            </div>
            {isFinancialsLoading ? (
              <Loading />
            ) : hasStandaloneData || hasConsolidatedData ? (
              <RelatedPartyTable
                data={
                  selectedNatureType === 'STANDALONE'
                    ? selectedYearsData.map((yearData) => ({
                        year: yearData.year,
                        data: (yearData.data as CompanyFinancialData).related_party_info,
                      }))
                    : Object.entries(consolidatedData?.response_data?.financials_consolidated || {})
                        .filter(([year, yearData]) => {
                          if (selectedYears.length === 0) {
                            return yearData && yearData.data && yearData.data.related_party_info;
                          }
                          return (
                            yearData &&
                            yearData.data &&
                            yearData.data.related_party_info &&
                            selectedYears.some((selected) => selected.value === year.split('-')[0])
                          );
                        })
                        .map(([year, yearData]) => ({
                          year: parseInt(year),
                          data: yearData.data.related_party_info,
                          metadata: yearData.metadata,
                        }))
                        .sort((a, b) => b.year - a.year)
                }
                figureFormat={selectedFigureFormat}
              />
            ) : (
              <div className='w-full border border-gray-200 rounded p-4 text-center text-gray-600'>
                No related party data available
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FinancialTab;
