import React, { useState, useMemo } from 'react';
import classNames from 'classnames';
import { ApiResponse } from '@app/types';
import { LegalHistoryWrapper, CourtData, CaseData } from '@app/types/compliances';
import Accordion from '@components/lib-components/accordian/Accordian';
import Table from '@components/lib-components/table/table/Table';
import { Header } from '@components/lib-components/table/table/table.types';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { formatDate } from '@components/utils/commonUtils';
import InfoCard from '@components/lib-components/info-card/InfoCard';
import { DocumentPreviewModal } from './DocumentPreviewModal';
import { useToggle } from '@app/hooks/useToggle';
import Badge from '@components/lib-components/badge/Badge';

interface LegalHistoryProps {
  data: ApiResponse<LegalHistoryWrapper> | undefined;
  isReportFetching: boolean;
}

const headers: Header[] = [
  { name: 'Case Number', value: 'case_number', classes: 'p-4 text-sm' },
  { name: 'Case Year', value: 'case_year', classes: 'p-4 text-sm' },
  { name: 'Petitioner', value: 'petitioner', classes: 'p-4 text-sm' },
  { name: 'Respondent', value: 'respondent', classes: 'p-4 text-sm' },
  { name: 'Filing Date', value: 'filing_date', classes: 'p-4 text-sm' },
  { name: 'Status', value: 'status', classes: 'p-4 text-sm' },
  { name: 'Case Link', value: 'link', classes: 'p-4 text-sm' },
];

const ITEMS_PER_PAGE = 10;

export const LegalHistory: React.FC<LegalHistoryProps> = ({ data, isReportFetching }) => {
  const [openAccordions, setOpenAccordions] = useState<string[]>([]);
  const [activeTabs, setActiveTabs] = useState<Record<string, 'respondent' | 'petitioner'>>({});
  const [currentPages, setCurrentPages] = useState<Record<string, number>>({});
  const [isPreviewOpen, togglePreviewModal] = useToggle();
  const [activeUrl, setActiveUrl] = useState<string | null>(null);

  if (isReportFetching) {
    return <ErrorMessage message='Fetching Legal History data...' />;
  }

  if (
    !data?.response_data?.legal_history ||
    Object.keys(data.response_data.legal_history).length === 0
  ) {
    return (
      <InfoCard className='py-1 flex flex-col gap-4 w-full mb-6'>
        <ErrorMessage message='No public legal history info available for this company.' />
      </InfoCard>
    );
  }

  const legalData = data.response_data.legal_history;

  // Ignore these keys when rendering
  const ignoredKeys = ['overview', 'legal', 'snapshot'];

  const toggleAccordion = (key: string) => {
    setOpenAccordions((prev) =>
      prev.includes(key) ? prev.filter((k) => k !== key) : [...prev, key],
    );
  };

  const createRows = (cases: Record<string, CaseData>) => {
    return (
      cases &&
      Object.values(cases).map((caseItem) => ({
        case_number: caseItem.case_number || '-',
        case_year: caseItem.case_year || '-',
        petitioner: caseItem.petitioner || '-',
        respondent: caseItem.respondent || '-',
        status: caseItem.status ? <Badge status={caseItem.status} /> : '-',
        filing_date:
          caseItem.filing_date && caseItem.filing_date !== null
            ? formatDate(caseItem.filing_date)
            : '-',
        link: caseItem.link ? (
          <button
            onClick={() => {
              setActiveUrl(caseItem.link ?? null);
              togglePreviewModal.on();
            }}
            className='text-blue-600 hover:text-blue-800'
          >
            View
          </button>
        ) : (
          '-'
        ),
      }))
    );
  };

  const renderTable = (courtData: CourtData, courtKey: string) => {
    const hasRespondentData =
      courtData?.as_respondent && Object.keys(courtData.as_respondent).length > 0;
    const hasPetitionerData =
      courtData?.as_petitioner && Object.keys(courtData.as_petitioner).length > 0;

    // If no data is available for both respondent and petitioner, show error message
    if (!hasRespondentData && !hasPetitionerData) {
      return (
        <ErrorMessage
          message={`No legal history found in ${courtKey.replace('_', ' ').toUpperCase()} for this company`}
        />
      );
    }

    const activeTab = activeTabs[courtKey] || (hasRespondentData ? 'respondent' : 'petitioner');
    const cases = activeTab === 'respondent' ? courtData.as_respondent : courtData.as_petitioner;
    const rows = useMemo(() => createRows(cases || {}), [cases]);
    const currentPage = currentPages[courtKey] || 1;

    const handlePageChange = (newPage: number) => {
      setCurrentPages((prev) => ({ ...prev, [courtKey]: newPage }));
    };

    const renderContent = () => {
      if (!cases || Object.keys(cases).length === 0) {
        return (
          <div className='text-center py-4'>
            No data available for {activeTab === 'respondent' ? 'respondent' : 'petitioner'}
          </div>
        );
      }

      return (
        <Table
          headers={headers}
          rows={rows.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE)}
          tableDivClassname='w-full border border-gray-200 !p-0 rounded mt-4'
          pagination={{
            currentPage,
            maxLimit: rows.length,
            onPageChange: handlePageChange,
          }}
        />
      );
    };

    return (
      <div>
        <div className='flex mb-4 border-2 border-slate-100 bg-slate-100 p-1 w-fit rounded-lg'>
          {['respondent', 'petitioner'].map((tabType) => {
            const isActive = activeTab === tabType;
            const hasData = tabType === 'respondent' ? hasRespondentData : hasPetitionerData;
            const count = Object.keys(courtData[`as_${tabType}` as keyof CourtData] || {}).length;

            return (
              <button
                key={tabType}
                className={classNames('px-4 py-2 text-sm rounded-lg font-medium w-48', {
                  'ml-2': tabType === 'petitioner',
                  'bg-blue-600 text-white': isActive,
                  'bg-gray-100 text-slate-500': !isActive && hasData,
                  'bg-gray-300 text-slate-400 cursor-not-allowed': !hasData,
                })}
                onClick={() => {
                  if (hasData) {
                    setActiveTabs((prev) => ({
                      ...prev,
                      [courtKey]: tabType as 'respondent' | 'petitioner',
                    }));
                    setCurrentPages((prev) => ({ ...prev, [courtKey]: 1 }));
                  }
                }}
                disabled={!hasData}
              >
                As {tabType.charAt(0).toUpperCase() + tabType.slice(1)}{' '}
                {isActive && (
                  <span className='px-2 border rounded-lg bg-blue-600 border-[#ffffff70] !text-sm'>
                    {count}
                  </span>
                )}
              </button>
            );
          })}
        </div>
        {renderContent()}
      </div>
    );
  };

  return (
    <div className='py-4 flex flex-col gap-4'>
      <DocumentPreviewModal
        title='Legal Preview'
        isOpen={isPreviewOpen}
        onClose={togglePreviewModal.off}
        url={activeUrl ?? ''}
        isSanitize={true}
      />
      <div className='text-lg w-max text-heading font-semibold'>Legal History</div>
      <InfoCard className='w-full'>
        {Object.entries(legalData).map(([key, value]) => {
          // Skip ignored keys or null values
          if (ignoredKeys.includes(key)) return null;

          const respondentCount = value?.as_respondent
            ? Object.keys(value.as_respondent).length
            : 0;
          const petitionerCount = value?.as_petitioner
            ? Object.keys(value.as_petitioner).length
            : 0;

          return (
            <Accordion
              key={key}
              title={key.replace('_', ' ').toUpperCase()}
              isOpen={openAccordions.includes(key)}
              onToggle={() => toggleAccordion(key)}
              count={respondentCount + petitionerCount}
            >
              {renderTable(value as CourtData, key)}
            </Accordion>
          );
        })}
      </InfoCard>
    </div>
  );
};
