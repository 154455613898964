import { useEffect, useMemo } from 'react';

interface UseDocumentTitleProps {
  title?: string;
  prefix?: string;
  suffix?: string;
  loading?: boolean;
  defaultTitle?: string;
}

/**
 * Custom hook to manage document title with proper type safety and loading states
 * @param props UseDocumentTitleProps
 * @returns void
 */
export const useDocumentTitle = ({
  title,
  prefix = '',
  suffix = '',
  loading = false,
  defaultTitle = 'Credhive',
}: UseDocumentTitleProps): void => {
  // Memoize the formatted title to prevent unnecessary recalculations
  const formattedTitle = useMemo(() => {
    if (loading) return `${defaultTitle} - Loading...`;
    if (!title) return defaultTitle;
    return `${prefix}${title}${suffix}`.trim();
  }, [title, prefix, suffix, loading, defaultTitle]);

  useEffect(() => {
    // Only update if the title has actually changed
    if (document.title !== formattedTitle) {
      document.title = formattedTitle;
    }

    return () => {
      // Only reset if we're actually changing from our set title
      if (document.title === formattedTitle) {
        document.title = defaultTitle;
      }
    };
  }, [formattedTitle, defaultTitle]);
};
