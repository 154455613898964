import React from 'react';
import { MonthlyDelayData, RiskCategory } from '@app/types/highlights';
import { RiskBadge } from '@components/lib-components/risk-badge/RiskBadge';

interface RiskBadgeGroupProps {
  monthData: MonthlyDelayData[];
}

export const RiskBadgeGroup: React.FC<RiskBadgeGroupProps> = ({ monthData }) => {
  const badges = [
    {
      type: 'low_risk' as const,
      count: monthData.filter((m) => m.risk_category === RiskCategory.LOW).length,
    },
    {
      type: 'medium_risk' as const,
      count: monthData.filter((m) => m.risk_category === RiskCategory.NEUTRAL).length,
    },
    {
      type: 'high_risk' as const,
      count: monthData.filter((m) => m.risk_category === RiskCategory.HIGH).length,
    },
  ];

  return (
    <div className='flex items-center gap-2'>
      {badges
        .filter((badge) => badge.count > 0)
        .map((badge) => (
          <RiskBadge key={badge.type} type={badge.type} count={badge.count} />
        ))}
    </div>
  );
};
