import React, { useState, useEffect, useRef } from 'react';
import Modal from '@components/lib-components/modal/Modal';
import Loading from '@components/lib-components/loading/Loading';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import DOMPurify from 'dompurify';

interface DocumentPreviewModalProps {
  url: string;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  isSanitize?: boolean;
}

export const DocumentPreviewModal: React.FC<DocumentPreviewModalProps> = ({
  url,
  isOpen,
  onClose,
  title,
  isSanitize = false,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const removeUnwantedResources = (html: string): string => {
    // Create a temporary DOM parser
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    // Remove specific CSS links
    const unwantedResources = [
      'bootstrap.min.css',
      'stylesheet.css',
      'font-awesome.min.css',
      'jquery.datepick.css',
    ];

    doc.querySelectorAll('link[rel="stylesheet"]').forEach((link) => {
      const href = link.getAttribute('href');
      if (href && unwantedResources.some((resource) => href.includes(resource))) {
        link.remove();
      }
    });

    return doc.documentElement.outerHTML;
  };

  useEffect(() => {
    const fetchAndInjectContent = async () => {
      try {
        setIsLoading(true);
        if (!isSanitize) {
          return;
        }
        // Use a CORS proxy
        const corsProxyUrl = `https://api.allorigins.win/raw?url=${encodeURIComponent(url)}`;

        const response = await fetch(corsProxyUrl, {
          method: 'GET',
          headers: {
            Accept: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const html = await response.text();
        const cleanedHtml = removeUnwantedResources(html);

        // Sanitize the HTML content using DOMPurify
        const sanitizedHtml = DOMPurify.sanitize(cleanedHtml, {
          USE_PROFILES: { html: true },
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['sandbox', 'referrerpolicy'],
          ALLOWED_TAGS: [
            'iframe',
            'html',
            'head',
            'body',
            'div',
            'p',
            'span',
            'a',
            'img',
            'table',
            'tr',
            'td',
            'th',
            'thead',
            'tbody',
          ],
          ALLOWED_ATTR: [
            'class',
            'id',
            'style',
            'href',
            'src',
            'alt',
            'title',
            'sandbox',
            'referrerpolicy',
          ],
          FORBID_TAGS: ['script', 'style', 'link'],
          FORBID_ATTR: ['onerror', 'onload', 'onclick', 'onmouseover'],
        });

        if (iframeRef.current?.contentWindow) {
          const doc = iframeRef.current.contentWindow.document;
          doc.open();
          doc.write(sanitizedHtml);
          doc.close();
          setIsLoading(false);
        }
      } catch (error) {
        console.error('Error loading content:', error);
        setIsError(true);
        setIsLoading(false);
      }
    };

    if (isOpen && url) {
      fetchAndInjectContent();
    }

    return () => {
      if (iframeRef.current?.contentWindow && isSanitize) {
        const doc = iframeRef.current.contentWindow.document;
        doc.open();
        doc.write('');
        doc.close();
      }
    };
  }, [url, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} className='w-[80vw] z-99999'>
      <div className='h-[80vh] relative'>
        {isLoading && (
          <div className='absolute inset-0 flex items-center justify-center bg-white'>
            <Loading />
          </div>
        )}
        {isError && (
          <div className='absolute inset-0 flex items-center justify-center bg-white'>
            <ErrorMessage message='Error loading content. Please try again.' />
          </div>
        )}

        {isSanitize ? (
          <iframe
            ref={iframeRef}
            className='w-full h-full'
            title={title}
            sandbox='allow-same-origin allow-scripts allow-forms allow-popups'
            referrerPolicy='no-referrer'
          />
        ) : (
          <iframe
            className='w-full h-full'
            title={title}
            src={url}
            onLoad={() => setIsLoading(false)}
            onError={() => {
              setIsLoading(false);
              setIsError(true);
            }}
          />
        )}
      </div>
    </Modal>
  );
};
