import { useState, useMemo } from 'react';
import Modal from '@app/components/lib-components/modal/Modal';
import Icon from '@app/components/lib-components/icon/Icon';
import Button from '@app/components/lib-components/button/Button';
import { FigureFormat } from './FinancialTab';
import classNames from 'classnames';
import { FinancialParameters, Metadata } from '@app/types/financial';
import pdfIcon from '@app/assets/pdfIcon.svg';
import { DocumentModal } from '@components/lib-components/modal/DocumentModal';
import { formatCurrency, formatParticular } from '@app/components/utils/commonUtils';
import { usePostHog } from 'posthog-js/react';
import { FinanceChart } from '@app/components/charts/FinanceChart';

interface Props {
  figureFormat: FigureFormat;
  tableBody: Array<{
    year: number;
    data: FinancialParameters;
    metadata: Metadata;
  }>;
}

export const FinancialTable = ({ tableBody, figureFormat }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState<{
    title: string;
    data: Array<{
      date: string;
      value: number;
    }>;
  }>({
    title: '',
    data: [],
  });
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedYearData, setSelectedYearData] = useState<{
    data: FinancialParameters;
    metadata: Metadata;
    year: number;
  } | null>(null);
  const posthog = usePostHog();

  const tableHeaders = useMemo(() => {
    const header = ['Particulars'];
    tableBody.forEach((yearData) => {
      const year = yearData.year ? `FY-${yearData.year}` : 'N/A';
      header.push(year);
    });
    return header;
  }, [tableBody]);

  const tableData = useMemo(() => {
    const data: Record<string, Record<string, number | string | null>> = {};

    tableBody.forEach((yearData) => {
      const year = yearData.year ? `FY-${yearData.year}` : 'N/A';
      const parameters = yearData.data;

      Object.entries(parameters).forEach(([key, value]) => {
        if (
          ![
            'document_id',
            'entity_id',
            'year',
            'financial_year',
            'nature',
            'id',
            'created_at',
            'updated_at',
          ].includes(key)
        ) {
          if (!data[key]) {
            data[key] = {};
          }
          data[key][year] = value;
        }
      });
    });

    return data;
  }, [tableBody]);

  const getDifference = (current: number, previous: number) => {
    if (!current || !previous || current === undefined || previous === undefined) return null;
    if (previous === 0) return <span className='ml-4 text-xs text-slate-400'>0.00%</span>;

    const diff = ((current - previous) / Math.abs(previous)) * 100;
    const arrow = !isNaN(diff) ? (diff > 0 ? '↑' : diff < 0 ? '↓' : '') : '';
    const color = diff > 0 ? 'text-green-500' : diff < 0 ? 'text-red-500' : 'text-slate-400';

    return (
      <span className={`ml-4 text-xs ${color}`}>
        {arrow} {isNaN(diff) ? '' : Math.abs(diff).toFixed(2)}%
      </span>
    );
  };

  const openModal = (particular: string, values: Record<string, number | null>) => {
    posthog.capture('financial_chart_viewed', {
      particular,
      type: 'parameters',
      years: Object.keys(values).length,
    });

    const years = Object.keys(values).filter((year) => tableHeaders.includes(year));
    const chartData = years
      .map((year) => ({
        date: year,
        value: values[year] ?? 0,
      }))
      .reverse();

    setChartData({
      title: formatParticular(particular),
      data: chartData,
    });
    setIsModalOpen(true);
  };

  const formatValue = (particular: string, value: number | string | null) => {
    if (value === null) return '-';
    if (particular === 'highest_turnover_contributing_product_or_service_code') {
      return value.toString();
    }
    return formatCurrency(Number(value), figureFormat);
  };

  const shouldShowGraph = (particular: string) => {
    return particular !== 'highest_turnover_contributing_product_or_service_code';
  };

  return (
    <>
      <div className='overflow-x-auto w-full border border-gray-200 rounded mt-3'>
        <table className='min-w-max border-separate border-spacing-0 bg-surface-1'>
          <thead className='bg-gray-100/30 text-sm text-gray-600 sticky top-0 z-20'>
            <tr className='bg-surface-2'>
              {tableHeaders.map((header, index) => (
                <th
                  key={index}
                  className={classNames('text-md text-gray-600 p-2 pl-0 text-center', {
                    'sticky left-0 bg-gray-50 z-30 border-r-[0.5px]': index === 0,
                    'bg-neutral-100': index >= 1,
                  })}
                  style={{
                    width:
                      index === 0 ? '35rem' : `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                    minWidth: '18rem',
                  }}
                >
                  <div className='flex items-center justify-center'>
                    {index === 1 && <div className='w-1.5 h-1.5 mr-1 rounded-full bg-green-500' />}
                    {header}
                    {index !== 0 && (
                      <img
                        className='pl-0 scale-[0.5] cursor-pointer z-[1]'
                        src={pdfIcon}
                        onClick={() => {
                          const yearData = tableBody[index - 1];
                          setSelectedYearData(yearData);
                          setIsDocumentModalOpen(true);
                        }}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(tableData).map(([particular, values], index) => (
              <tr
                key={particular}
                className={classNames('border-y border-primary', {
                  'bg-gray-50': index % 2 === 0,
                })}
              >
                <td
                  className='text-sm flex items-center justify-between font-medium py-5 px-16 sticky left-0 z-10 bg-surface-base bg-table-secondary'
                  style={{ width: '35rem' }}
                >
                  <span>{formatParticular(particular)}</span>
                  {shouldShowGraph(particular) &&
                    Object.values(values).some((value) => Number(value) !== 0) && (
                      <Button
                        className='bg-transparent ml-2'
                        onClick={() =>
                          openModal(particular, values as Record<string, number | null>)
                        }
                      >
                        <Icon
                          icon='BarChartIcon'
                          color='#c6c6c6'
                          size={22}
                          className='cursor-pointer'
                        />
                      </Button>
                    )}
                </td>
                {tableHeaders.slice(1).map((header, headerIndex) => {
                  const nextYearHeader = tableHeaders[headerIndex + 2];
                  return (
                    <td
                      key={header}
                      className={classNames(
                        'text-sm font-medium py-5 px-4 text-center',
                        index % 2 === 0 ? 'bg-[#F5F2EB]' : 'bg-table-secondary',
                      )}
                      style={{
                        width: `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                        minWidth: '18rem',
                      }}
                    >
                      {formatValue(particular, values[header])}
                      {shouldShowGraph(particular) &&
                        nextYearHeader &&
                        getDifference(Number(values[header]), Number(values[nextYearHeader]))}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        className='w-[40%] max-w-[90vw]'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={chartData.title}
      >
        <FinanceChart data={chartData.data} title={chartData.title} figureFormat={figureFormat} />
      </Modal>
      <DocumentModal
        isOpen={isDocumentModalOpen}
        onClose={() => {
          setIsDocumentModalOpen(false);
        }}
        documentData={selectedYearData}
      />
    </>
  );
};
