import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfoCard from '@app/components/lib-components/info-card/InfoCard';
import Button from '@app/components/lib-components/button/Button';
import { ShareholdingPieChart } from '@app/components/charts/ShareholdingPieChart';
import Table from '@app/components/lib-components/table/table/Table';
import Modal from '@app/components/lib-components/modal/Modal';
import { useGetCompanyData } from '@app/store/api/companyApi';
import Loading from '@components/lib-components/loading/Loading';
import {
  ShareholdingData,
  ShareholdingTypes,
  ShareholdingsSummaryResponse,
  JointVentureCompany,
  ShareholdingStructureResponse,
  ShareholdingSectionId,
} from './ShareholdingProps.types';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { Header, Row } from '@components/lib-components/table/table/table.types';
import { DocumentIconButton } from '@components/lib-components/DocumentIconButton';
import ExpandableTable from '../../../lib-components/table/table/ExpandableTable';
import { ApiResponse } from '@app/types';
import EntitySection from './EntitySection';
import {
  convertToTitleCase,
  formatCurrencyNumber,
  formatDate,
} from '@components/utils/commonUtils';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';
import { useLocalNavigation } from '@app/hooks/useLocalNavigation';
import classNames from 'classnames';
import Icon from '@app/components/lib-components/icon/Icon';
import { useIntersectionObserver } from '@app/hooks/useIntersectionObserver';

interface TableDataMap {
  [key: string]: {
    headers: Header[];
    rows: Row[];
  };
}

const directorTableHeaders = [
  { name: 'DIN / PAN', value: 'din_pan', classes: 'p-4 text-sm' },
  { name: 'Name', value: 'full_name', classes: 'p-4 text-sm' },
  { name: 'Designation', value: 'designation', classes: 'p-4 text-sm' },
  { name: 'Number of Shares', value: 'no_of_shares', classes: 'p-4 xl:pl-10 text-sm' },
  {
    name: '% Shareholding',
    value: 'percentage_shareholding',
    classes: 'p-4 xl:pl-10 text-sm',
  },
];

const individualTableHeaders = [
  { name: 'Name', value: 'full_name', classes: 'p-4 text-sm' },
  { name: 'PAN', value: 'pan', classes: 'p-4 text-sm' },
  { name: 'Number of Shares', value: 'no_of_shares', classes: 'p-4 xl:pl-10 text-sm' },
  {
    name: '% Shareholding',
    value: 'percentage_shareholding',
    classes: 'p-4 xl:pl-10 text-sm',
  },
  { name: 'Notes', value: 'notes', classes: 'p-4 text-sm' },
];

const companyTableHeaders = [
  { name: 'Company Name', value: 'full_name', classes: 'p-4 text-sm' },
  { name: 'CIN / LLPIN', value: 'cin_llpin', classes: 'p-4 text-sm' },
  { name: 'Number of Shares', value: 'no_of_shares', classes: 'p-4 xl:pl-10 text-sm' },
  {
    name: '% Shareholding',
    value: 'percentage_shareholding',
    classes: 'p-4 xl:pl-10 text-sm',
  },
  { name: 'Notes', value: 'notes', classes: 'p-4 text-sm' },
];

const otherTableHeaders = [
  { name: 'Name', value: 'full_name', classes: 'p-4 text-sm' },
  { name: 'Country', value: 'country', classes: 'p-4 text-sm' },
  { name: 'Number of Shares', value: 'no_of_shares', classes: 'p-4 xl:pl-10 text-sm' },
  {
    name: '% Shareholding',
    value: 'percentage_shareholding',
    classes: 'p-4 xl:pl-10 text-sm',
  },
  { name: 'Notes', value: 'notes', classes: 'p-4 text-sm' },
];

const securitiesAllotmentHeaders = [
  { name: 'Date of Allotment', value: 'date_of_allotment', classes: 'p-4 text-sm' },
  { name: 'Type', value: 'type', classes: 'p-4 text-sm' },
  { name: 'Allotment Type', value: 'allotment_type', classes: 'p-4 text-sm' },
  { name: 'No. of Securities', value: 'number_of_securities', classes: 'p-4 text-sm' },
  { name: 'Nominal Amount (in ₹)', value: 'nominal_amount', classes: 'p-4 text-sm w-60' },
  { name: 'Premium Amount (in ₹)', value: 'premium_amount', classes: 'p-4 text-sm w-60' },
  { name: 'Total Amount (in ₹)', value: 'total_amount', classes: 'p-4 text-sm w-50' },
];

interface NavigationSection {
  id: ShareholdingSectionId;
  label: string;
  show: boolean;
}

const ShareHoldingPattern = () => {
  const [showModal, setShowModal] = useState<string | undefined>(undefined);
  const [holdingView, setHoldingView] = useState<string>('table');
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const [promoterPercentage, setPromoterPercentage] = useState<number>(0);
  const [publicPercentage, setPublicPercentage] = useState<number>(0);
  const [hasNonZeroShareholding, setHasNonZeroShareholding] = useState<boolean>(false);
  const { id } = useParams<string>();
  const navigate = useLocalNavigation();
  const getShareholdingData = useGetCompanyData<ApiResponse<ShareholdingData>>();
  const { data: shareholdingData, isLoading: isLoadingShareholding } = getShareholdingData({
    cinId: id as string,
    field_name: 'shareholdings_summary',
    identifierType: identifierType,
  });
  const [activeSection, setActiveSection] = useState<ShareholdingSectionId>(
    ShareholdingSectionId.SUMMARY,
  );
  const [patternSummaryData, setPatternSummaryData] = useState<
    Array<{ name: string; value: number; color: string }>
  >([]);
  const [promotersChartData, setPromotersChartData] = useState<
    Array<{ name: string; value: number; color: string }>
  >([]);
  const [tableDataMap, setTableDataMap] = useState<TableDataMap>({
    [ShareholdingSectionId.DIRECTOR]: {
      headers: directorTableHeaders,
      rows: [],
    },
    [ShareholdingSectionId.INDIVIDUAL]: {
      headers: individualTableHeaders,
      rows: [],
    },
    [ShareholdingSectionId.COMPANY]: {
      headers: companyTableHeaders,
      rows: [],
    },
    [ShareholdingSectionId.OTHER]: {
      headers: otherTableHeaders,
      rows: [],
    },
    [ShareholdingSectionId.SECURITIES_ALLOTMENT]: {
      headers: securitiesAllotmentHeaders,
      rows: [],
    },
  });

  const securitiesAllotmentRows = useMemo(() => {
    return (
      shareholdingData?.response_data?.shareholdings_summary?.securities_allotment?.map((item) => ({
        date_of_allotment: item.data.date_of_allotment
          ? formatDate(item.data.date_of_allotment)
          : '-',
        type: item.data.type ? convertToTitleCase(item.data.type) : '-',
        allotment_type: item.data.allotment_type
          ? convertToTitleCase(item.data.allotment_type)
          : '-',
        number_of_securities: item.data.number_of_securities_allotted
          ? item.data.number_of_securities_allotted
          : '-',
        nominal_amount: item.data.nominal_amount_per_security
          ? item.data.nominal_amount_per_security.toFixed(2)
          : '-',
        premium_amount: item.data.premium_amount_per_security_due_and_payable
          ? item.data.premium_amount_per_security_due_and_payable.toFixed(2)
          : '-',
        total_amount: item.data.total_amount ? item.data.total_amount.toFixed(2) : '-',
      })) ?? []
    );
  }, [shareholdingData?.response_data?.shareholdings_summary?.securities_allotment]);

  const renderJointVenturesTable = (data: JointVentureCompany[]) => (
    <Table
      tableDivClassname='w-full border border-gray-200 !p-0 rounded'
      headers={[
        { name: 'CIN / FCRN', value: 'cin_or_fcrn', classes: 'p-4 text-sm' },
        { name: 'Legal Name', value: 'legal_name', classes: 'p-4 text-sm' },
        { name: 'Share Holding %', value: 'share_holding_percentage', classes: 'p-4 text-sm' },
      ]}
      rows={data.map((item) => ({
        cin_or_fcrn: item.cin_or_fcrn ? (
          <Button
            className='bg-transparent !text-utility-gray-blue-700 text-xs font-semibold !py-1 !px-2 rounded-md w-auto border-2 border-gray-200 cursor-pointer'
            onClick={() => onCinRedirection(item.cin_or_fcrn)}
            text={item.cin_or_fcrn}
          />
        ) : (
          '-'
        ),
        legal_name: item.jv_holding_subsidiary_company_name,
        share_holding_percentage: `${item.percentage_held.toFixed(2)}%`,
      }))}
    />
  );

  const isInProgress = (status?: ReportStatusEnum) =>
    status === ReportStatusEnum.INITIALISED || status === ReportStatusEnum.IN_PROGRESS;

  const isReportFetching = isInProgress(shareholdingData?.response_data?.status);

  const onCinRedirection = (cin: string) => {
    navigate(cin as string, 'overview', { state: { cid: cin }, replace: true });
  };

  const sortByNumberOfShares = (rows: Row[]) => {
    return [...rows].sort((a, b) => {
      const sharesA = parseInt(a.no_of_shares as string, 10) || 0;
      const sharesB = parseInt(b.no_of_shares as string, 10) || 0;
      return sharesB - sharesA;
    });
  };

  useEffect(() => {
    if (
      !isReportFetching &&
      shareholdingData?.response_data?.shareholdings_summary?.pattern_summary
    ) {
      const {
        pattern_summary,
        director_shareholdings,
        individual_shareholdings,
        company_shareholdings,
        other_shareholdings,
      } = shareholdingData.response_data.shareholdings_summary;

      if (pattern_summary) {
        const promoterPercentage =
          pattern_summary?.data?.COMPUTED_percentage_promoter_equity_shares ?? 0;
        const publicPercentage =
          pattern_summary?.data?.COMPUTED_percentage_public_equity_shares ?? 0;
        setPromoterPercentage(Number(promoterPercentage));
        setPublicPercentage(Number(publicPercentage));

        // Set pattern summary chart data
        setPatternSummaryData([
          {
            name: 'Promoters',
            value: parseFloat(promoterPercentage.toFixed(2)),
            color: '#35B26A',
          },
          {
            name: 'Public',
            value: parseFloat(publicPercentage.toFixed(2)),
            color: '#2E63EE',
          },
        ]);

        if (director_shareholdings?.data) {
          const directorRows: Row[] = director_shareholdings.data
            .map((item) => ({
              full_name: item.full_name ?? '-',
              no_of_shares:
                item.num_shares !== null && item.num_shares !== undefined
                  ? formatCurrencyNumber(item.num_shares)?.toString() || '0'
                  : '0',
              percentage_shareholding:
                item.percentage_held != null ? `${item.percentage_held.toFixed(2)}%` : '0%',
              din_pan: item.din_pan ?? '-',
              designation: item.designation ?? '-',
              _original_no_of_shares: item.num_shares
                ? parseInt(item.num_shares.toString(), 10)
                : 0,
            }))
            .sort((a, b) => (b._original_no_of_shares ?? 0) - (a._original_no_of_shares ?? 0));

          setTableDataMap((prev) => ({
            ...prev,
            [ShareholdingSectionId.DIRECTOR]: {
              headers: directorTableHeaders,
              rows: directorRows,
            },
          }));

          // Check if any director has non-zero shareholding
          const hasNonZero = director_shareholdings.data.some(
            (item) => parseFloat(item.percentage_held?.toFixed(2) ?? '0') > 0,
          );
          setHasNonZeroShareholding(hasNonZero);

          // Set promoters chart data only if there are non-zero values
          if (hasNonZero) {
            setPromotersChartData(
              director_shareholdings.data
                .filter((item) => parseFloat(item.percentage_held?.toFixed(2) ?? '0') > 0)
                .map((item, index) => ({
                  name: item.full_name ?? '-',
                  value: parseFloat(item.percentage_held?.toFixed(2) ?? '0'),
                  color: index % 2 === 0 ? '#35B26A' : '#2E63EE', // Alternating between green and blue
                })),
            );
          }
        }

        if (individual_shareholdings?.data) {
          const individualRows: Row[] = individual_shareholdings.data.map((item) => ({
            full_name: item.full_name ?? '-',
            no_of_shares: item.num_shares ? formatCurrencyNumber(item.num_shares)?.toString() : '-',
            percentage_shareholding:
              item.percentage_held != null ? `${item.percentage_held.toFixed(2)}%` : '-',
            pan: item.pan ?? '-',
            notes: item.notes ? item.notes : '-',
          }));
          setTableDataMap((prev) => ({
            ...prev,
            [ShareholdingSectionId.INDIVIDUAL]: {
              headers: individualTableHeaders,
              rows: sortByNumberOfShares(individualRows),
            },
          }));
        }

        if (company_shareholdings?.data) {
          const companyRows: Row[] = company_shareholdings.data.map((item) => ({
            full_name: item.full_name ?? '-',
            cin_llpin: item.cin_llpin ? (
              <Button
                className='bg-transparent !text-utility-gray-blue-700 text-xs font-semibold !py-1 !px-2 rounded-md w-auto border-2 border-gray-200 cursor-pointer'
                onClick={() => onCinRedirection(item.cin_llpin as string)}
                text={item.cin_llpin}
              />
            ) : (
              '-'
            ),
            no_of_shares: item.num_shares?.toString() ?? '-',
            percentage_shareholding:
              item.percentage_held != null ? `${item.percentage_held.toFixed(2)}%` : '-',
            notes: item.notes ? item.notes : '-',
          }));
          setTableDataMap((prev) => ({
            ...prev,
            [ShareholdingSectionId.COMPANY]: {
              headers: companyTableHeaders,
              rows: sortByNumberOfShares(companyRows),
            },
          }));
        }

        if (other_shareholdings?.data) {
          const otherRows: Row[] = other_shareholdings.data.map((item) => ({
            full_name: item.full_name ?? '-',
            no_of_shares: item.num_shares?.toString() ?? '-',
            percentage_shareholding:
              item.percentage_held != null ? `${item.percentage_held.toFixed(2)}%` : '-',
            notes: item.notes ? item.notes : '-',
            country: item.country ?? '-',
          }));
          setTableDataMap((prev) => ({
            ...prev,
            [ShareholdingSectionId.OTHER]: {
              headers: otherTableHeaders,
              rows: sortByNumberOfShares(otherRows),
            },
          }));
        }

        if (securitiesAllotmentRows.length > 0) {
          setTableDataMap((prev) => ({
            ...prev,
            Securities: {
              headers: securitiesAllotmentHeaders,
              rows: securitiesAllotmentRows,
            },
          }));
        }
      }
    }
  }, [shareholdingData, isReportFetching, securitiesAllotmentRows]);

  const totalShareHoldings = useMemo(() => {
    if (!shareholdingData?.response_data?.shareholdings_summary?.pattern_summary?.data) return 0;

    const { COMPUTED_total_equity_shares, total_preference_shares } =
      shareholdingData.response_data.shareholdings_summary.pattern_summary.data;
    return (COMPUTED_total_equity_shares ?? 0) + (total_preference_shares ?? 0);
  }, [shareholdingData]);

  const isAllFieldsNull = (obj: ShareholdingsSummaryResponse | null): boolean => {
    if (!obj) return true;
    return Object.values(obj).every(
      (value) => value === null || (typeof value === 'object' && isAllFieldsNull(value)),
    );
  };
  const navigationSections: NavigationSection[] = [
    {
      id: ShareholdingSectionId.SUMMARY,
      label: 'Summary',
      show: !!shareholdingData?.response_data?.shareholdings_summary?.pattern_summary?.data,
    },
    {
      id: ShareholdingSectionId.DIRECTOR,
      label: 'Director Shareholdings',
      show:
        tableDataMap[ShareholdingSectionId.DIRECTOR].rows.length > 0 &&
        !!shareholdingData?.response_data?.shareholdings_summary?.director_shareholdings?.data,
    },
    {
      id: ShareholdingSectionId.INDIVIDUAL,
      label: 'Individual Shareholdings',
      show:
        tableDataMap[ShareholdingSectionId.INDIVIDUAL].rows.length > 0 &&
        !!shareholdingData?.response_data?.shareholdings_summary?.individual_shareholdings?.data,
    },
    {
      id: ShareholdingSectionId.COMPANY,
      label: 'Company Shareholdings',
      show:
        tableDataMap[ShareholdingSectionId.COMPANY].rows.length > 0 &&
        !!shareholdingData?.response_data?.shareholdings_summary?.company_shareholdings?.data,
    },
    {
      id: ShareholdingSectionId.OTHER,
      label: 'Other Shareholdings',
      show:
        tableDataMap[ShareholdingSectionId.OTHER].rows.length > 0 &&
        !!shareholdingData?.response_data?.shareholdings_summary?.other_shareholdings?.data,
    },
    {
      id: ShareholdingSectionId.STRUCTURE,
      label: 'Shareholding Structure',
      show: !!shareholdingData?.response_data?.shareholdings_summary?.shareholdings?.data,
    },
    {
      id: ShareholdingSectionId.JOINT_VENTURES,
      label: 'Joint Ventures',
      show:
        !!shareholdingData?.response_data?.shareholdings_summary?.joint_ventures?.data?.company
          ?.length ||
        !!shareholdingData?.response_data?.shareholdings_summary?.joint_ventures?.data?.llp
          ?.length ||
        !!shareholdingData?.response_data?.shareholdings_summary?.joint_ventures?.data?.others
          ?.length,
    },
    {
      id: ShareholdingSectionId.ASSOCIATE,
      label: 'Associate Entities',
      show:
        !!shareholdingData?.response_data?.shareholdings_summary?.associate_entities?.data?.company
          ?.length ||
        !!shareholdingData?.response_data?.shareholdings_summary?.associate_entities?.data?.llp
          ?.length ||
        !!shareholdingData?.response_data?.shareholdings_summary?.associate_entities?.data?.others
          ?.length,
    },
    {
      id: ShareholdingSectionId.SUBSIDIARY,
      label: 'Subsidiary Entities',
      show:
        !!shareholdingData?.response_data?.shareholdings_summary?.subsidiary_entities?.data?.company
          ?.length ||
        !!shareholdingData?.response_data?.shareholdings_summary?.subsidiary_entities?.data?.llp
          ?.length ||
        !!shareholdingData?.response_data?.shareholdings_summary?.subsidiary_entities?.data?.others
          ?.length,
    },
    {
      id: ShareholdingSectionId.HOLDING,
      label: 'Holding Entities',
      show:
        !!shareholdingData?.response_data?.shareholdings_summary?.holding_entities?.data?.company
          ?.length ||
        !!shareholdingData?.response_data?.shareholdings_summary?.holding_entities?.data?.llp
          ?.length ||
        !!shareholdingData?.response_data?.shareholdings_summary?.holding_entities?.data?.others
          ?.length,
    },
    {
      id: ShareholdingSectionId.SECURITIES_ALLOTMENT,
      label: 'Securities Allotment',
      show: !!shareholdingData?.response_data?.shareholdings_summary?.securities_allotment?.length,
    },
  ];

  useIntersectionObserver({
    sections: navigationSections,
    onSectionChange: (sectionId) => setActiveSection(sectionId as ShareholdingSectionId),
    debounceTime: 100,
  });

  const scrollToSection = (sectionId: ShareholdingSectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
      setActiveSection(sectionId);
    }
  };

  const getNavigationButtonClass = (sectionId: ShareholdingSectionId) =>
    classNames('text-sm', {
      'bg-gray-200': activeSection === sectionId,
      'bg-white': activeSection !== sectionId,
    });

  if (isLoadingShareholding) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );
  }

  if (isReportFetching) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage
          message={
            'Kindly wait a few minutes, we are fetching the company shareholding data for you.'
          }
        />
      </div>
    );
  }
  if (
    !isReportFetching &&
    shareholdingData?.response_data?.shareholdings_summary &&
    isAllFieldsNull(shareholdingData.response_data.shareholdings_summary)
  ) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <div className='flex text-center justify-center w-[40vw] min-h-96 h-auto items-center mt-4 text-slate-400 noDataSection !bg-contain'>
          Shareholding data not available
        </div>
      </div>
    );
  }

  return (
    <div className='py-4 flex flex-col gap-4'>
      {!isReportFetching && shareholdingData ? (
        <>
          <div className='sticky top-0 z-[99] bg-white rounded-lg shadow-sm p-4 mb-4'>
            <h3 className='text-lg font-medium mb-3'>Quick Navigation</h3>
            <div className='flex flex-wrap gap-3'>
              {navigationSections
                .filter((section) => section.show)
                .map((section) => (
                  <Button
                    key={section.id}
                    variant='navigation'
                    onClick={() => scrollToSection(section.id)}
                    className={getNavigationButtonClass(section.id)}
                  >
                    {section.label}
                  </Button>
                ))}
            </div>
          </div>
          <InfoCard className='flex w-full justify-between'>
            <div id={ShareholdingSectionId.SUMMARY} className='w-full'>
              <h3 className='text-md font-semibold text-heading'>
                Shareholding Summary (as on{' '}
                {formatDate(
                  shareholdingData.response_data?.shareholdings_summary?.pattern_summary?.data
                    ?.financial_year ?? '',
                )}
                )
              </h3>
              <div className='flex w-full justify-between'>
                <section className='flex flex-col gap-4 p-6 w-full justify-between'>
                  <div className='inline-flex justify-start gap-16 w-max'>
                    <div className='flex flex-col w-max'>
                      <span className='text-md font-medium text-slate-600 mb-2'>Total Shares</span>
                      <h1 className='text-slate-900 font-bold text-2xl'>
                        {formatCurrencyNumber(totalShareHoldings) ?? '-'}
                      </h1>
                    </div>
                    <div className='flex flex-col'>
                      <span className='text-md font-medium text-slate-600 mb-2'>
                        Face value of share
                      </span>
                      <h1 className='text-slate-900 font-bold text-3xl'>
                        &#8377;{' '}
                        {shareholdingData.response_data?.shareholdings_summary?.pattern_summary
                          ?.data?.COMPUTED_value_per_share_authorised_capital ?? '-'}
                      </h1>
                      {/* <Button
                        className='self-start underline text-md text-gray-500 hover:text-gray-700 font-semibold bg-transparent'
                        variant='text'
                        text='View History'
                        onClick={() => {}}
                      /> */}
                    </div>
                  </div>
                  <hr className='text-gray-200' />
                  <div className='inline-flex justify-start gap-16'>
                    <div className='flex flex-col'>
                      <span className='text-md font-medium text-slate-600 mb-2'>
                        Promoter group
                      </span>
                      <h1 className='font-bold text-3xl text-[#35B26A]'>
                        {`${promoterPercentage.toFixed(2)}%`}
                      </h1>
                      <span className='text-md font-medium text-slate-400'>
                        (
                        {shareholdingData.response_data?.shareholdings_summary?.pattern_summary
                          ?.data?.num_promoters_end_of_year ?? 0}{' '}
                        promoters)
                      </span>
                    </div>
                    <div className='flex flex-col'>
                      <span className='text-md font-medium text-slate-600 mb-2'>Public</span>
                      <h1 className='font-bold text-3xl text-[#2E63EE]'>
                        {`${publicPercentage.toFixed(2)}%`}
                      </h1>
                      <span className='text-md font-medium text-slate-400'>
                        (
                        {shareholdingData.response_data?.shareholdings_summary?.pattern_summary
                          ?.data?.members_not_promoters_end_of_year ?? 0}{' '}
                        public holders)
                      </span>
                    </div>
                  </div>
                </section>
                <section className='w-full'>
                  <ShareholdingPieChart
                    data={patternSummaryData}
                    type='summary'
                    title='Shareholding Summary'
                  />
                </section>
              </div>
            </div>
          </InfoCard>

          {tableDataMap[ShareholdingSectionId.DIRECTOR].rows.length > 0 && (
            <div id={ShareholdingSectionId.DIRECTOR}>
              <InfoCard className='p-4 w-full flex flex-col'>
                <div className='flex items-start justify-between'>
                  <div className='flex items-start'>
                    <p className='text-md font-semibold mb-4 text-heading'>
                      Director Shareholdings
                    </p>
                    <DocumentIconButton
                      metadata={
                        shareholdingData?.response_data?.shareholdings_summary
                          ?.director_shareholdings?.metadata ?? null
                      }
                      documentData={
                        shareholdingData?.response_data?.shareholdings_summary
                          ?.director_shareholdings as ShareholdingTypes
                      }
                    />
                  </div>
                  {hasNonZeroShareholding && (
                    <div className='flex gap-2'>
                      <Icon
                        icon='Bars3Icon'
                        variant='outline'
                        onClick={() => setHoldingView('table')}
                        className={`w-4 font-bold cursor-pointer ${
                          holdingView === 'table' ? 'text-blue-600' : 'text-gray-600'
                        }`}
                      />
                      <Icon
                        icon='Squares2X2Icon'
                        variant='outline'
                        onClick={() => setHoldingView('charts')}
                        className={`w-4 font-bold cursor-pointer ${
                          holdingView === 'charts' ? 'text-blue-600' : 'text-gray-600'
                        }`}
                      />
                    </div>
                  )}
                </div>
                <>
                  {holdingView === 'table' || !hasNonZeroShareholding ? (
                    <Table
                      tableDivClassname='w-full border border-gray-200 !p-0 rounded'
                      tableHeaderClassname='bg-secondary border-b border-gray-200 p-4 text-sm text-gray-600'
                      headers={tableDataMap[ShareholdingSectionId.DIRECTOR].headers}
                      rows={tableDataMap[ShareholdingSectionId.DIRECTOR].rows.slice(0, 5)}
                    />
                  ) : (
                    <ShareholdingPieChart
                      data={promotersChartData}
                      type='directors'
                      title='Director Shareholdings'
                    />
                  )}
                  {tableDataMap[ShareholdingSectionId.DIRECTOR].rows.length > 5 && (
                    <Button
                      variant='outline'
                      className='mt-4 self-end text-gray-600 outline-gray-300'
                      text={holdingView === 'table' ? 'View All' : 'View list'}
                      onClick={() => setShowModal(ShareholdingSectionId.DIRECTOR)}
                    />
                  )}
                </>
              </InfoCard>
            </div>
          )}

          {tableDataMap[ShareholdingSectionId.INDIVIDUAL].rows.length > 0 && (
            <div id={ShareholdingSectionId.INDIVIDUAL}>
              <InfoCard className=' col-span-full p-4 w-full'>
                <div className='flex items-start'>
                  <p className='text-md font-semibold mb-4 text-heading'>
                    Individual Shareholdings
                  </p>
                  <DocumentIconButton
                    metadata={
                      shareholdingData?.response_data?.shareholdings_summary
                        ?.individual_shareholdings?.metadata ?? null
                    }
                    documentData={
                      shareholdingData?.response_data?.shareholdings_summary
                        ?.individual_shareholdings as ShareholdingTypes
                    }
                  />
                </div>
                <>
                  <Table
                    tableDivClassname='w-full border border-gray-200 !p-0 rounded'
                    headers={tableDataMap[ShareholdingSectionId.INDIVIDUAL].headers}
                    rows={tableDataMap[ShareholdingSectionId.INDIVIDUAL].rows.slice(0, 5)}
                  />
                  {tableDataMap[ShareholdingSectionId.INDIVIDUAL].rows.length > 5 && (
                    <Button
                      variant='outline'
                      className='mt-4 self-end text-gray-600 outline-gray-300'
                      text='View All'
                      onClick={() => setShowModal(ShareholdingSectionId.INDIVIDUAL)}
                    />
                  )}
                </>
              </InfoCard>
            </div>
          )}

          {tableDataMap[ShareholdingSectionId.COMPANY].rows.length > 0 && (
            <div id={ShareholdingSectionId.COMPANY}>
              <InfoCard className=' col-span-full p-4 w-full'>
                <div className='flex items-start'>
                  <p className='text-md font-semibold mb-4 text-heading'>Company Shareholdings</p>
                  <DocumentIconButton
                    metadata={
                      shareholdingData?.response_data?.shareholdings_summary?.company_shareholdings
                        ?.metadata ?? null
                    }
                    documentData={
                      shareholdingData?.response_data?.shareholdings_summary
                        ?.company_shareholdings as ShareholdingTypes
                    }
                  />
                </div>
                <>
                  <Table
                    tableDivClassname='w-full border border-gray-200 !p-0 rounded'
                    headers={tableDataMap[ShareholdingSectionId.COMPANY].headers}
                    rows={tableDataMap[ShareholdingSectionId.COMPANY].rows.slice(0, 5)}
                  />
                  {tableDataMap[ShareholdingSectionId.COMPANY].rows.length > 5 && (
                    <Button
                      variant='outline'
                      className='mt-4 self-end text-gray-600 outline-gray-300'
                      text='View All'
                      onClick={() => setShowModal(ShareholdingSectionId.COMPANY)}
                    />
                  )}
                </>
              </InfoCard>
            </div>
          )}

          {tableDataMap[ShareholdingSectionId.OTHER].rows.length > 0 && (
            <div id={ShareholdingSectionId.OTHER}>
              <InfoCard className=' col-span-full p-4 w-full'>
                <div className='flex items-start'>
                  <p className='text-md font-semibold mb-4 text-heading'>Other Shareholdings</p>
                  <DocumentIconButton
                    metadata={
                      shareholdingData?.response_data?.shareholdings_summary?.other_shareholdings
                        ?.metadata ?? null
                    }
                    documentData={
                      shareholdingData?.response_data?.shareholdings_summary
                        ?.other_shareholdings as ShareholdingTypes
                    }
                  />
                </div>
                <>
                  <Table
                    tableDivClassname='w-full border border-gray-200 !p-0 rounded'
                    headers={tableDataMap[ShareholdingSectionId.OTHER].headers}
                    rows={tableDataMap[ShareholdingSectionId.OTHER].rows.slice(0, 5)}
                  />
                  {tableDataMap[ShareholdingSectionId.OTHER].rows.length > 5 && (
                    <Button
                      variant='outline'
                      className='mt-4 self-end text-gray-600 outline-gray-300'
                      text='View All'
                      onClick={() => setShowModal(ShareholdingSectionId.OTHER)}
                    />
                  )}
                </>
              </InfoCard>
            </div>
          )}

          {shareholdingData?.response_data?.shareholdings_summary?.shareholdings?.data && (
            <div id={ShareholdingSectionId.STRUCTURE}>
              <InfoCard className=' col-span-full p-4 w-full'>
                <div className='flex items-start'>
                  <p className='text-md font-semibold text-heading'>Shareholding Structure</p>
                  <DocumentIconButton
                    metadata={
                      shareholdingData?.response_data?.shareholdings_summary?.shareholdings
                        ?.metadata ?? null
                    }
                    documentData={
                      shareholdingData?.response_data?.shareholdings_summary
                        ?.shareholdings as ShareholdingTypes
                    }
                  />
                </div>
                <ExpandableTable
                  data={
                    shareholdingData?.response_data?.shareholdings_summary
                      .shareholdings as ShareholdingStructureResponse
                  }
                  isShareholdingTable={true}
                  tableType='shareholding'
                />
              </InfoCard>
            </div>
          )}

          {shareholdingData?.response_data?.shareholdings_summary?.joint_ventures?.data && (
            <EntitySection
              title='Joint Ventures'
              data={shareholdingData.response_data.shareholdings_summary.joint_ventures}
              metadata={
                shareholdingData.response_data.shareholdings_summary.joint_ventures?.metadata
              }
              renderTable={renderJointVenturesTable}
              sectionId={ShareholdingSectionId.JOINT_VENTURES}
            />
          )}
          {shareholdingData?.response_data?.shareholdings_summary?.associate_entities?.data && (
            <EntitySection
              title='Associate Entities'
              data={shareholdingData.response_data.shareholdings_summary.associate_entities}
              metadata={
                shareholdingData.response_data.shareholdings_summary.associate_entities?.metadata
              }
              renderTable={renderJointVenturesTable}
              sectionId={ShareholdingSectionId.ASSOCIATE}
            />
          )}
          {shareholdingData?.response_data?.shareholdings_summary?.subsidiary_entities?.data && (
            <EntitySection
              title='Subsidiary Entities'
              data={shareholdingData.response_data.shareholdings_summary.subsidiary_entities}
              metadata={
                shareholdingData.response_data.shareholdings_summary.subsidiary_entities?.metadata
              }
              renderTable={renderJointVenturesTable}
              sectionId={ShareholdingSectionId.SUBSIDIARY}
            />
          )}
          {shareholdingData?.response_data?.shareholdings_summary?.holding_entities?.data && (
            <EntitySection
              title='Holding Entities'
              data={shareholdingData.response_data.shareholdings_summary.holding_entities}
              metadata={
                shareholdingData.response_data.shareholdings_summary.holding_entities?.metadata
              }
              renderTable={renderJointVenturesTable}
              sectionId={ShareholdingSectionId.HOLDING}
            />
          )}
          {shareholdingData?.response_data?.shareholdings_summary?.securities_allotment &&
            shareholdingData?.response_data?.shareholdings_summary?.securities_allotment?.length >
              0 && (
              <div id={ShareholdingSectionId.SECURITIES_ALLOTMENT}>
                <InfoCard className='col-span-full p-4 w-full'>
                  <div className='flex items-start'>
                    <p className='text-md font-semibold mb-4 text-heading'>Securities Allotment</p>
                    <DocumentIconButton
                      metadata={
                        shareholdingData?.response_data?.shareholdings_summary
                          ?.securities_allotment[0]?.metadata ?? null
                      }
                      documentData={
                        shareholdingData?.response_data?.shareholdings_summary
                          ?.securities_allotment[0]
                      }
                    />
                  </div>
                  <Table
                    tableDivClassname='w-full border border-gray-200 !p-0 rounded max-h-[35rem] overflow-y-auto'
                    headers={securitiesAllotmentHeaders}
                    rows={securitiesAllotmentRows}
                  />
                </InfoCard>
              </div>
            )}
          <Modal
            className='w-[70%] max-w-[90vw]'
            title={showModal ?? ''}
            isOpen={!!showModal}
            onClose={() => setShowModal(undefined)}
          >
            <Table
              tableDivClassname='w-full border border-gray-200 !p-0 rounded'
              tableHeaderClassname='bg-secondary border-b border-gray-200-200 text-sm p-4 text-xs p-1 text-gray-600'
              headers={showModal ? tableDataMap[showModal].headers : []}
              rows={showModal ? tableDataMap[showModal].rows : []}
            />
          </Modal>
        </>
      ) : isLoadingShareholding ? (
        <div className='w-full w-md-[83.8vw] '>
          <Loading
            message={
              isReportFetching
                ? 'Kindly wait a few minutes the report is getting fetched'
                : undefined
            }
          />
        </div>
      ) : (
        <div className='flex justify-center w-full  '>
          <div className='flex text-center justify-center w-[40vw] min-h-96 h-auto items-center mt-4 text-slate-400 noDataSection !bg-contain'>
            Shareholding data not available, please try again in some time.
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareHoldingPattern;
