import React from 'react';
import Badge from '@app/components/lib-components/badge/Badge';
import Button from '@app/components/lib-components/button/Button';
import { TableRow, Status } from '@app/types/charges';
import { JSX } from 'react/jsx-runtime';
import classNames from 'classnames';
import { formatDate } from '@components/utils/commonUtils';

interface TableComponentProps {
  headers: string[];
  rows: TableRow[];
  maxLimit?: number;
  currentLimit?: number;
  skip?: number;
  handleNext?: () => void;
  handlePrevious?: () => void;
  onViewClick?: (row: TableRow) => void;
  badgeStatus?: boolean;
  isLoading?: boolean;
}

const TableComponent: React.FC<TableComponentProps> = ({
  headers,
  rows,
  maxLimit,
  currentLimit = 10,
  skip = 0,
  handleNext,
  handlePrevious,
  onViewClick,
  badgeStatus,
  isLoading = false,
}) => {
  const showPagination = Boolean(maxLimit && currentLimit && handleNext && handlePrevious);

  const currentPage = Math.floor(skip / currentLimit) + 1;

  const isPreviousDisabled = currentPage === 1 || isLoading;
  const isNextDisabled = skip + currentLimit >= (maxLimit || 0) || isLoading;

  const start = (currentPage - 1) * currentLimit + 1;
  const end = Math.min(currentPage * currentLimit, maxLimit || 0);

  const renderRows = (rows: TableRow[]) => {
    const renderedRows: JSX.Element[] = [];

    rows.forEach((row, index) => {
      const hasSubRows = row.subRows && row.subRows.length > 0;
      const bgColor = index % 2 === 0 ? 'bg-white' : 'bg-gray-50';
      const subRowBgColor = index % 2 === 0 ? 'bg-white' : 'bg-gray-50';

      // Render main row
      renderedRows.push(
        <tr
          key={`${row.id}-${index}`}
          className={`${bgColor} ${hasSubRows ? 'border-b-0' : 'border-b-0'} font-medium text-tertiary`}
        >
          <td
            className={`cursor-pointer text-tertiary font-medium px-2 py-4 ${headers[0] === '' && '!border-0'}  ${hasSubRows ? 'border-r-2 !border-b-0 border-slate-100' : 'border-r-2 border-slate-100'} text-sm font-medium text-gray-600`}
          >
            {row.charge_id}
          </td>
          {Object.keys(row).map(
            (key) =>
              key !== 'id' &&
              key !== 'charge_id' &&
              key !== 'subRows' &&
              key !== 'link' &&
              key !== 'additionalDetails' && (
                <td
                  key={key}
                  className='px-2 py-4 text-sm font-medium text-tertiary whitespace-break-spaces'
                >
                  {key === 'status' && badgeStatus ? (
                    <Badge status={row[key] as Status} />
                  ) : key === 'date' ? (
                    formatDate(row[key] as string)
                  ) : (
                    // @ts-expect-error status can be different as its a common component
                    row[key]
                  )}
                </td>
              ),
          )}
          {onViewClick && row.status !== 'SATISFACTION' ? (
            <td className='px-2 py-4'>
              <button
                onClick={() => {
                  onViewClick(row);
                }}
                className='text-blue-500 text-sm bg-transparent'
              >
                View
              </button>
            </td>
          ) : (
            <td></td>
          )}
        </tr>,
      );

      // Render subRows if they exist
      if (hasSubRows) {
        row?.subRows?.forEach((subRow, subIndex) => {
          renderedRows.push(
            <tr
              key={`${row.id}-${index}-${subIndex}`}
              className={`${subRowBgColor} font-medium text-tertiary`}
            >
              <td className='pl-10 px-2 py-4 !border-r-2 border-slate-100 text-sm text-tertiary'></td>
              {Object.keys(subRow).map(
                (key) =>
                  key !== 'id' &&
                  key !== 'charge_id' &&
                  key !== 'additionalDetails' && (
                    <td
                      key={key}
                      className='px-2 py-4 border-b-0 text-sm font-medium text-tertiary'
                    >
                      {key === 'status' ? (
                        <Badge status={subRow[key] as Status} />
                      ) : key === 'date' ? (
                        formatDate(subRow[key] as string)
                      ) : (
                        // @ts-expect-error status can be different as its a common component
                        subRow[key]
                      )}
                    </td>
                  ),
              )}
              {onViewClick && subRow.status !== 'SATISFACTION' ? (
                <td className='px-2 py-4 border-b-0 text-sm'>
                  <button
                    onClick={() => {
                      const rowToPass = {
                        ...subRow,
                        id: subRow.id,
                      };
                      onViewClick(rowToPass as TableRow);
                    }}
                    className='text-blue-500 text-sm bg-transparent'
                  >
                    View
                  </button>
                </td>
              ) : (
                <td></td>
              )}
            </tr>,
          );
        });
      }
    });

    return renderedRows;
  };

  return (
    <div className='overflow-x-auto'>
      <div className='relative border rounded-lg overflow-hidden'>
        <table className='min-w-full  divide-y divide-gray-200'>
          <thead className='bg-gray-100 text-gray-600'>
            <tr>
              {headers.map((header, index) => (
                <th key={index} className='px-2 py-3 text-sm text-left font-semibold text-gray-600'>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{renderRows(rows)}</tbody>
        </table>

        {showPagination && (
          <div className='flex justify-between items-center py-3 px-6 border-t border-gray-200 text-sm rounded-b-lg'>
            <div>{`Showing ${start} - ${end} of ${maxLimit}`}</div>
            <div>
              <Button
                onClick={handlePrevious}
                disabled={isPreviousDisabled}
                isLoading={isLoading}
                variant='text'
                size='sm'
                className={classNames(
                  'mx-1',
                  isPreviousDisabled
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-white text-gray-700 hover:bg-gray-50 border hover:no-underline',
                )}
              >
                Previous
              </Button>
              <Button
                onClick={handleNext}
                disabled={isNextDisabled}
                isLoading={isLoading}
                variant='text'
                size='sm'
                className={classNames(
                  'mx-1',
                  isNextDisabled
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-white text-gray-700 hover:bg-gray-50 border hover:no-underline',
                )}
              >
                Next
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TableComponent;
