import { ModalProps } from './modal.types';
import '@app/components/credits/CreditModal.css';
import classNames from 'classnames';
import { useOutsideClick } from '@app/hooks/useOutSideClick';
import { XMarkIcon } from '@heroicons/react/24/outline';

const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  className,
  titleClass,
  headerClass,
}: ModalProps) => {
  const ref = useOutsideClick<HTMLDivElement>(onClose);

  if (!isOpen) return null;

  const handleOnClose = () => {
    onClose && onClose();
  };

  return (
    <div className='credit-modal-backdrop' data-testid='credhive-common-modal'>
      <div
        className={classNames(
          'bg-white !p-7 rounded-lg shadow-md max-h-[90%] relative text-primary-900',
          className,
        )}
        ref={ref}
      >
        <div className='flex flex-col h-full'>
          <div
            className={classNames('w-full inline-flex justify-between items-center', headerClass)}
          >
            <span className={classNames('text-lg font-semibold', titleClass)}>{title}</span>
            <div className='text-gray-400 m-2 cursor-pointer' onClick={handleOnClose}>
              <XMarkIcon className='w-5 h-5' />
            </div>
          </div>
          <div className='flex-1 overflow-y-auto py-5 pb-0 scrollbar-thin max-h-[80vh]'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
