import React, { useMemo, useState } from 'react';
import Modal from '@app/components/lib-components/modal/Modal';
import Accordion from '@app/components/lib-components/accordian/Accordian';
import Button from '@app/components/lib-components/button/Button';
import {
  useGetDocumentRedirectionLinkMutation,
  useReportIssueMutation,
} from '@app/store/api/companyApi';

import { ChargeDetailsModalProps } from '@app/types/charges';
import { Metadata } from '@app/types/financial';
import { RootState } from '@app/store/store';
import { PresignedUrlPayLoad } from '@app/types';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useSelector } from 'react-redux';
import { DownloadDocuments } from './DownloadDocuments';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { DocumentItem } from '@components/lib-components/modal/DocumentModal';
import { formatDate, numDifferentiation } from '@components/utils/commonUtils';
import { DocumentPreviewModal } from '@components/company/company-tabs/Compliances/DocumentPreviewModal';

interface ChargeOverviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  chargeDetails?: ChargeDetailsModalProps;
  documents?: Metadata;
  figureFormat: string;
}

type Document = Omit<DocumentItem, 's3_url'>;

export const ChargeOverviewModal: React.FC<ChargeOverviewModalProps> = ({
  isOpen,
  onClose,
  chargeDetails,
  documents,
  figureFormat,
}) => {
  if (!chargeDetails) return null;

  const [openAccordion, setOpenAccordion] = useState<string | null>(null);
  const [getDocumentUrl, { isLoading: isDocumentLoading }] =
    useGetDocumentRedirectionLinkMutation();
  const [reportIssue] = useReportIssueMutation();
  const [isDocumentPreviewOpen, setIsDocumentPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');

  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const { id: cinId } = useParams<{ id: string }>();

  const onToggle = (accordionId: string) => {
    setOpenAccordion((prevState) => (prevState === accordionId ? null : accordionId));
  };

  const formatDetailText = (text: string | null): React.ReactNode => {
    if (!text) return '-';
    if (!text?.trim()) return '-';

    const lines = text.split(/\r|\n/).filter((line) => line.trim());
    if (lines.length <= 1) return text;

    return (
      <div className='formatted-details space-y-2'>
        {lines.map((line, index) => {
          const segments = line
            .split(/\s{2,}/)
            .map((s) => s.trim())
            .filter(Boolean);

          return segments.length > 1 ? (
            <div key={index} className='grid grid-cols-2 gap-4'>
              {segments.map((segment, i) => (
                <span key={i} className={i === segments.length - 1 ? 'text-right' : ''}>
                  {segment}
                </span>
              ))}
            </div>
          ) : (
            <div key={index} className='text-sm'>
              {line}
            </div>
          );
        })}
      </div>
    );
  };

  const chargeDetailsFields = useMemo(() => {
    if (!chargeDetails?.data) return [];
    const { data } = chargeDetails;

    return [
      {
        label: 'Instrument Description',
        value: formatDetailText(data.instrument_description),
      },
      {
        label: 'Rate of Interest',
        value: formatDetailText(data.rate_of_interest),
      },
      {
        label: 'Terms of Payment',
        value: formatDetailText(data.term_of_payment),
      },
      {
        label: 'Property Particulars',
        value: formatDetailText(data.property_particulars),
      },
      {
        label: 'Extent and Operation',
        value: formatDetailText(data.extent_and_operation),
      },
      {
        label: 'Other Terms',
        value: formatDetailText(data.other_terms),
      },
      {
        label: 'Joint Holding',
        value: data.joint_holding !== null ? (data.joint_holding ? 'Yes' : 'No') : '-',
      },
      {
        label: 'Consortium Holding',
        value: data.consortium_holding !== null ? (data.consortium_holding ? 'Yes' : 'No') : '-',
      },
    ];
  }, [chargeDetails]);

  const hasNonNullValues = useMemo(() => {
    return chargeDetailsFields.some((field) => {
      const value = field.value;
      return value !== '-' && value !== null && value !== undefined;
    });
  }, [chargeDetailsFields]);

  const handleReportIssue = async (issue: string, description: string, documentName: string) => {
    try {
      await reportIssue({
        issue,
        description: `${description} - Document: ${documentName}`,
      }).unwrap();
    } catch {
      toast.error('Failed to report issue');
    }
  };

  const extractFilenameFromUrl = (url: string): string => {
    try {
      const decodedUrl = decodeURIComponent(url);
      const urlObj = new URL(decodedUrl);
      const pathname = urlObj.pathname;
      const fileNameWithParams = pathname.split('/').pop();
      if (fileNameWithParams) {
        return fileNameWithParams.split('?')[0];
      }
      throw new Error('Unable to extract filename from URL');
    } catch {
      toast.error('Failed to extract filename from URL');
      handleReportIssue('Filename Extraction Error', 'Failed to extract filename from URL', url);
      return 'document.pdf';
    }
  };

  const forceFileDownload = async (url: string, filename: string): Promise<void> => {
    if (isDocumentLoading) return;
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = decodeURIComponent(filename).replace(/%20/g, ' ');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => {
        window.URL.revokeObjectURL(blobUrl);
      }, 100);
    } catch (error) {
      toast.error('Failed to download the document');
      handleReportIssue('File Download Error', 'Failed to download the document', filename);
    }
  };

  const openPdfInNewTab = (url: string, documentName: string, flatten: boolean): void => {
    if (isDocumentLoading) return;
    try {
      const decodedUrl = decodeURIComponent(url);
      if (flatten) {
        window.open(
          `${window.location.origin}/pdf-viewer#${decodedUrl}`,
          '_blank',
          'noopener,noreferrer',
        );
      } else {
        setPreviewUrl(decodedUrl);
        setIsDocumentPreviewOpen(true);
      }
    } catch (error) {
      toast.error('Failed to open the PDF in a new tab');
      handleReportIssue('PDF Viewer Error', 'Failed to open the PDF in a new tab', documentName);
    }
  };

  const handleDocumentClick = async (
    item: Document,
    isDownload: boolean = false,
  ): Promise<void> => {
    if (!chargeDetails || !documents) return;
    const payload: PresignedUrlPayLoad = {
      s3_url: documents.s3_url,
      is_download: isDownload,
      identifier_type: identifierType,
      identifier_value: cinId as string,
    };
    if (item.isAttachment && item.attachment) {
      Object.assign(payload, {
        s3_url: item.attachment.s3_url,
        is_download: isDownload,
      });
    }
    try {
      const response = await getDocumentUrl(payload).unwrap();

      if (response.pre_signed_url) {
        if (isDownload) {
          const filename = extractFilenameFromUrl(response.pre_signed_url);
          await forceFileDownload(response.pre_signed_url, filename);
        } else {
          openPdfInNewTab(response.pre_signed_url, item.name, response.flatten);
        }
      }
    } catch (error) {
      toast.error('Failed to fetch the document URL');
      handleReportIssue(
        'Document Fetch Error',
        `Failed to fetch the document URL for path ${payload?.s3_url} `,
        item.name,
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title='Charge Overview'
        className='w-[calc(100vw-36rem)]'
      >
        <div className='p-6'>
          {chargeDetails?.data && (
            <div className='flex justify-between mb-6'>
              <div>
                <p className='text-secondary-700 text-sm'>Charge ID</p>
                <p className='text-sm font-semibold text-gray-700'>
                  {chargeDetails.data.charge_id}
                </p>
              </div>
              <div>
                <p className='text-secondary-700 text-sm'>Date</p>
                <p className='text-sm font-semibold text-gray-700'>
                  {formatDate(chargeDetails.data.date)}
                </p>
              </div>
              <div>
                <p className='text-secondary-700 text-sm'>Amount</p>
                <p className='text-sm font-semibold text-gray-700'>
                  ₹ {numDifferentiation(chargeDetails.data.amount, figureFormat)}
                </p>
              </div>
            </div>
          )}

          {hasNonNullValues ? (
            <Accordion
              icon={null}
              title='Charge Details'
              isOpen={openAccordion === 'charge-details'}
              onToggle={() => onToggle('charge-details')}
            >
              <table className='max-w-full min-w-full divide-y divide-gray-200 !border-b-tertiary-600'>
                <tbody className='bg-white divide-y divide-gray-200'>
                  {chargeDetailsFields.map((field, index) => (
                    <tr key={index} className='border b-2'>
                      <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-600 font-normal border-r'>
                        {field.label}
                      </td>
                      <td className='px-6 py-4 whitespace-break-spaces text-sm text-secondary-700 font-medium max-w-xl overflow-auto'>
                        {field.value}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Accordion>
          ) : (
            <div className='p-4 text-center text-gray-600 w-full flex justify-center'>
              <ErrorMessage
                message='No details available in public registry MCA V2'
                className='w-fit'
              />
            </div>
          )}

          {/* Document section */}
          {chargeDetails && documents && Object.keys(documents).length > 0 && (
            <Accordion
              icon={null}
              title='Supporting Documents'
              isOpen={openAccordion === 'documents-details'}
              onToggle={() => onToggle('documents-details')}
            >
              <div className='mt-0 ml-2'>
                <h4 className='text-md text-gray-600 font-semibold mb-3'>Primary Document</h4>
                <div className='flex'>
                  <DownloadDocuments
                    name={documents.file_name ?? ''}
                    isAttachment={false}
                    isDocumentLoading={isDocumentLoading}
                    handleDocumentClick={handleDocumentClick}
                  />
                </div>
                {chargeDetails.metadata.attachments &&
                  chargeDetails.metadata.attachments.length > 0 && (
                    <>
                      <h4 className='text-md text-gray-600 font-semibold mb-3 mt-4'>Attachments</h4>
                      <div className='flex'>
                        {chargeDetails.metadata?.attachments?.length ? (
                          chargeDetails.metadata.attachments.map((attachment, index) => (
                            <React.Fragment key={index}>
                              <DownloadDocuments
                                name={attachment.file_name ?? ''}
                                isAttachment={true}
                                isDocumentLoading={isDocumentLoading}
                                attachment={attachment}
                                handleDocumentClick={handleDocumentClick}
                              />
                            </React.Fragment>
                          ))
                        ) : (
                          <p className='text-gray-500'>No attachments available</p>
                        )}
                      </div>
                    </>
                  )}
              </div>
            </Accordion>
          )}
          <div className='mt-4 flex justify-center'>
            <Button
              text='Close'
              onClick={onClose}
              className='w-5/12 text-center bg-transparent border font-semibold !text-slate-500'
            />
          </div>
        </div>
      </Modal>
      <DocumentPreviewModal
        isOpen={isDocumentPreviewOpen}
        onClose={() => setIsDocumentPreviewOpen(false)}
        url={previewUrl}
        title='Document Preview'
      />
    </>
  );
};
