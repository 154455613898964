import React, { useMemo, useState } from 'react';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';
import Button from '@components/lib-components/button/Button';
import { toast } from 'sonner';
import Icon from '@components/lib-components/icon/Icon';
import { FigureFormat } from '../financials/FinancialTab';
import { TemplateDataResponse } from '@app/store/api/template/template.type';
import { Metadata } from '@app/types/financial';

interface HighlightsTemplateProcessorProps {
  data: TemplateDataResponse;
  companyType: COMPANY_TYPE;
  type: 'STANDALONE' | 'CONSOLIDATED';
}

const formatParticular = (section: string, subsection: string, key: string): string => {
  const sectionDisplay =
    {
      balance_sheet: 'Balance Sheet',
      profit_and_loss: 'P&L',
      statement_of_assets_and_liabilities: 'Statement of Assets and Liabilities',
      statement_of_income_and_expenditure: 'Statement of Income and Expenditure',
    }[section] || section;

  return `${sectionDisplay} - ${subsection.charAt(0).toUpperCase() + subsection.slice(1).replace(/_/g, ' ')} - ${key
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')}`;
};

const determineFigureFormat = (values: number[]): FigureFormat => {
  const maxValue = Math.max(...values.filter((val) => typeof val === 'number' && !isNaN(val)));

  if (maxValue >= 10000000) {
    // 1 crore
    return 'crores';
  } else if (maxValue >= 1000000) {
    // 10 lakhs
    return 'millions';
  } else if (maxValue >= 100000) {
    // 1 lakh
    return 'lacs';
  } else {
    return 'thousands';
  }
};

const formatValue = (value: number, figureFormat: FigureFormat): string => {
  if (typeof value !== 'number' || isNaN(value)) return '0.00';

  let formattedValue: number;
  let suffix: string;

  switch (figureFormat) {
    case 'crores':
      formattedValue = value / 10000000;
      suffix = 'Cr';
      break;
    case 'lacs':
      formattedValue = value / 100000;
      suffix = 'L';
      break;
    case 'millions':
      formattedValue = value / 1000000;
      suffix = 'M';
      break;
    default:
      formattedValue = value / 1000;
      suffix = 'K';
  }

  return `${formattedValue.toFixed(2)}${suffix}`;
};

type FinancialData = {
  [key: string]: {
    data: {
      [section: string]: {
        [subsection: string]: {
          [metric: string]: number;
        };
      };
    };
    metadata: Metadata;
  };
};

interface ProcessedMetric {
  section: string;
  subsection: string;
  metric: string;
  values: Record<string, number>;
}

export const HighlightsTemplateProcessor: React.FC<HighlightsTemplateProcessorProps> = ({
  data,
  companyType,
  type,
}) => {
  const [isCopying, setIsCopying] = useState(false);

  const tableData = useMemo(() => {
    const keyName =
      companyType === COMPANY_TYPE.LimitedLiabilityPartnership
        ? 'financials'
        : type === 'STANDALONE'
          ? 'financials_standalone'
          : 'financials_consolidated';

    if (!data?.[keyName as keyof TemplateDataResponse]) {
      console.error('No data available');
      return '';
    }

    const templateData = data[keyName as keyof TemplateDataResponse];

    if (!templateData || typeof templateData !== 'object') {
      console.error('Invalid template data format');
      return '';
    }

    const years = Object.keys(templateData).sort((a, b) => parseInt(b) - parseInt(a));
    if (years.length === 0) {
      console.error('No years found in template data');
      return '';
    }

    const headers = ['Particulars'];
    headers.push(...years.map((year) => `FY-${year}`));

    const allValues: number[] = [];
    const processedMetrics: ProcessedMetric[] = [];
    // First, collect all metrics and their values
    const firstYearData = (templateData as FinancialData)[years[0]]?.data;
    if (!firstYearData) {
      console.error('No data found for first year');
      return '';
    }

    try {
      Object.entries(firstYearData).forEach(([section, sectionData]) => {
        if (sectionData && typeof sectionData === 'object') {
          Object.entries(sectionData).forEach(([subsection, metrics]) => {
            if (metrics && typeof metrics === 'object') {
              Object.entries(metrics as Record<string, number>).forEach(([metric]) => {
                const values: Record<string, number> = {};
                years.forEach((year) => {
                  const yearData = (templateData as unknown as FinancialData)[year]?.data;
                  if (yearData?.[section]?.[subsection]?.[metric] !== undefined) {
                    const value = yearData[section][subsection][metric];
                    values[year] = value;
                    allValues.push(value);
                  }
                });

                processedMetrics.push({
                  section,
                  subsection,
                  metric,
                  values,
                });
              });
            }
          });
        }
      });

      const figureFormat = determineFigureFormat(allValues);

      // Now create the rows with the processed data
      const rows = processedMetrics.map(({ section, subsection, metric, values }) => {
        const row = [formatParticular(section, subsection, metric)];
        years.forEach((year) => {
          const value = values[year] ?? 0;
          row.push(formatValue(value, figureFormat));
        });
        return row;
      });

      return [headers, ...rows].map((row) => row.join('\t')).join('\n');
    } catch (error) {
      console.error('Error processing template data:', error);
      return '';
    }
  }, [data, companyType, type]);

  const handleCopy = async () => {
    if (!tableData) {
      toast.error('No data available to copy');
      return;
    }

    setIsCopying(true);
    try {
      await navigator.clipboard.writeText(tableData);
      toast.success('Copied to clipboard');
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } catch (error) {
      console.error('Error copying to clipboard:', error);
      toast.error('Failed to copy data');
    } finally {
      setIsCopying(false);
    }
  };

  return (
    <Button
      onClick={handleCopy}
      isLoading={isCopying}
      loadingText='Copying...'
      spinnerColor='text-tertiary-600'
      className='self-end px-4 py-2 ml-3 text-sm font-medium !bg-gray-100 !text-tertiary-600 border rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 flex items-center gap-2 w-30'
    >
      {!isCopying && <Icon icon='DocumentDuplicateIcon' className='w-4 h-4' />}
      Copy to Excel
    </Button>
  );
};
