import { useCallback, useMemo, useRef, useState, useEffect } from 'react';
import Icon from '@app/components/lib-components/icon/Icon';
import { CompanyDropdown } from './CompanyDropdown';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useSearchCompanyByNameMutation } from '@app/store/api/companyApi';
import { NoResultsMessage } from './NoResultsMessage';
import { usePostHog } from 'posthog-js/react';
import {
  ISpotlightSearchResult,
  searchAndGetRoute,
} from '@app/components/spotlight-search/constants';
import { SearchResult } from '@app/components/spotlight-search';
import { useActiveCompany } from '@app/hooks/useActiveCompany';
import { formatOptions } from '@components/utils/constants';
import { SupportedCountry } from '@app/store/slices/companySlice';
import { setIsBlur } from '@app/store/slices/authSlice';
import { toast } from 'sonner';
import { useLocalNavigation } from '@app/hooks/useLocalNavigation';
import { CompanySuggestion } from './input.types';
import { useOutsideClick } from '@app/hooks/useOutSideClick';

export const SearchBar = () => {
  const dispatch = useDispatch();
  const { id: cin } = useParams();
  const posthog = usePostHog();
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const localNavigate = useLocalNavigation();
  const [searchCompany, { data, reset, isError }] = useSearchCompanyByNameMutation();
  const [noResultsMessage, setNoResultsMessage] = useState<string | null>(null);
  const [spotlightSearchResults, setSpotlightSearchResults] = useState<ISpotlightSearchResult[]>(
    [],
  );
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  const { changeCountry, activeCountry, supportedCountry } = useActiveCompany();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Check for Command+K (Mac) or Control+K (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
        e.preventDefault();
        if (document.activeElement === inputRef.current) {
          clearSearch();
          inputRef.current?.blur();
          dispatch(setIsBlur(false));
        } else {
          inputRef.current?.focus();
          dispatch(setIsBlur(true));
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [dispatch]);

  const allResults = useMemo(() => {
    const companyResults = data?.suggestions || [];
    return [...spotlightSearchResults, ...companyResults];
  }, [data?.suggestions, spotlightSearchResults]);

  const selectedOption = useMemo(() => {
    const countryCode = formatOptions.find((value) => value.value === activeCountry);
    return countryCode || formatOptions.find((el) => el.value === 'coming soon');
  }, [activeCountry]);

  const handleCountryChange = (option: { value: string; label: string }) => {
    if (!supportedCountry.includes(option.value as SupportedCountry)) {
      changeCountry('in');
      toast.success('Switched To India Location');
    } else {
      changeCountry(option.value as SupportedCountry);
      toast.success(`Switched to ${option.label} Location`);
    }
  };

  const clearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    reset();
    setNoResultsMessage(null);
    setSpotlightSearchResults([]);
    setSelectedIndex(-1);
  };

  const handleResultSelection = (result: ISpotlightSearchResult | CompanySuggestion) => {
    if (!result) return;

    // Handle local result (within company tabs)
    if ('route' in result) {
      localNavigate(cin as string, result.route);
    }
    // Handle global company search result
    else if ('suggestion' in result) {
      localNavigate(result.suggestion.cin, 'overview');
    }
    clearSearch();
    dispatch(setIsBlur(false));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    dispatch(setIsBlur(true));
    query ? triggerSearch(query) : clearSearch();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!allResults.length) return;

    if (e.key !== 'Escape') {
      dispatch(setIsBlur(true));
    }

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setSelectedIndex((prev) => (prev < allResults.length - 1 ? prev + 1 : 0));
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex((prev) => (prev > 0 ? prev - 1 : allResults.length - 1));
        break;
      case 'Enter':
        e.preventDefault();
        if (selectedIndex >= 0 && selectedIndex < allResults.length) {
          const selectedResult = allResults[selectedIndex];
          handleResultSelection(selectedResult as ISpotlightSearchResult | CompanySuggestion);
        }
        break;
      case 'Escape':
        e.preventDefault();
        clearSearch();
        inputRef.current?.blur();
        dispatch(setIsBlur(false));
        break;
    }
  };

  const triggerSearch = useCallback(
    (query: string) => {
      if (!query || query.length < 3) return;

      if (cin) {
        const results = searchAndGetRoute(query, cin.startsWith('A') ? 'LLP' : 'PRIVATE');
        if (results) {
          setSpotlightSearchResults(results);
        }
      }

      searchCompany(query)
        .unwrap()
        .then(() => {
          setNoResultsMessage(null);
          setSelectedIndex(-1);
          posthog.capture('search_company', { company_name: query });
        })
        .catch((err) => {
          setNoResultsMessage(err?.data?.detail);
          posthog.capture('search_company_error', {
            company_name: query,
            error: err?.data?.detail,
          });
        });
    },
    [searchCompany, cin, posthog],
  );

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData('text');
    if (pastedText) {
      if (inputRef.current) {
        inputRef.current.value = pastedText;
      }
      triggerSearch(pastedText);
    }
  };

  const handleClickOutside = useCallback(() => {
    clearSearch();
    dispatch(setIsBlur(false));
  }, [dispatch, clearSearch]);

  const searchContainerRef = useOutsideClick<HTMLDivElement>(handleClickOutside);

  const handleSearchFocus = useCallback(() => {
    dispatch(setIsBlur(true));
  }, [dispatch]);

  const dropdownClassNames = classNames(
    'text-gray-400 inline-flex absolute inset-y-0 right-7 z-50',
    {
      'mb-9 top-3': noResultsMessage,
      'items-center': !noResultsMessage,
      'bottom-16': isError,
    },
  );

  return (
    <div
      className={classNames(
        'relative text-black z-40 px-5',
        'w-full sm:w-7/12 md:w-4/12 lg:w-6/12',
      )}
      ref={searchContainerRef}
    >
      <Icon
        className='input-icon absolute left-8 top-4'
        icon='MagnifyingGlassIcon'
        color='gray'
        variant='outline'
        size={18}
      />

      <input
        ref={inputRef}
        className='w-full rounded-md py-3 px-9 bg-white border outline-none outline-offset-0'
        onChange={handleSearchChange}
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
        placeholder='Enter at least three characters to search'
        onFocus={handleSearchFocus}
        data-testid='search-bar-input'
      />

      <div className='pointer-events' ref={dropdownRef}>
        <CompanyDropdown
          suffixButton={false}
          iconButton={true}
          dropdownClassName={dropdownClassNames}
          menuPositionClass={classNames('w-100', {
            'mt-11': noResultsMessage,
            'mt-36': !noResultsMessage,
          })}
          options={formatOptions}
          value={selectedOption}
          onOptionSelect={handleCountryChange}
        />
      </div>

      {data?.suggestions && data.suggestions.length > 0 && (
        <SearchResult
          result={data.suggestions}
          localResult={spotlightSearchResults}
          selectedIndex={selectedIndex}
          onResultClick={() => {
            clearSearch();
            dispatch(setIsBlur(false));
          }}
        />
      )}
      {isError && <NoResultsMessage message={noResultsMessage || 'No results found'} />}
    </div>
  );
};
