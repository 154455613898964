import React, { useState } from 'react';
import HighlightsOverview from './HighlightsOverview';
import { useParams } from 'react-router-dom';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { HighlightSection } from './highlights/types';
import { FinancialMetrics, HighlightsData } from '@app/types/highlights';
import { NatureType } from './financial-analysis/FinancialAnalysisTab';
import Loading from '@components/lib-components/loading/Loading';
import { LegalHistory } from './highlights/LegalHistory';
import { CompliancesOverview } from './highlights/CompliancesOverview';
import { ApiResponse } from '@app/types';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';

const Highlights: React.FC = () => {
  const [selectedNatureType, setSelectedNatureType] = useState<NatureType>('STANDALONE');
  const isReportFetching = useSelector((state: RootState) => state.util.isReportFetching);
  const { id } = useParams<{ id: string }>();
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const companyType = useSelector((state: RootState) => state.util.companyType);

  const getHighlightsData = useGetCompanyData<ApiResponse<HighlightsData>>();
  const { data: highlightsData, isLoading: isHighlightsLoading } = getHighlightsData({
    cinId: id as string,
    field_name: 'highlights',
    identifierType: identifierType,
  });

  const calculateChange = (current: number | null, previous: number | null): number | null => {
    if (current === null || previous === null || previous === 0) return null;
    return ((current - previous) / previous) * 100;
  };

  const onChangeFinancialNature = (value: NatureType) => {
    setSelectedNatureType(value);
  };

  const getFinancialOverviewItems = (
    currentYear: FinancialMetrics,
    previousYear: FinancialMetrics,
  ) => {
    const items = [
      {
        title: 'Total Assets',
        value: currentYear.total_assets,
        change: calculateChange(currentYear.total_assets, previousYear.total_assets),
      },
      {
        title: 'Reserves and Surpluses',
        value: currentYear.reserves_and_surplus,
        change: calculateChange(
          currentYear.reserves_and_surplus,
          previousYear.reserves_and_surplus,
        ),
      },
      {
        title: "Shareholder's Fund Capital",
        value: currentYear.shareholders_fund_capital,
        change: calculateChange(
          currentYear.shareholders_fund_capital,
          previousYear.shareholders_fund_capital,
        ),
      },
      {
        title: 'Net Worth',
        value: currentYear.net_worth,
        change: calculateChange(currentYear.net_worth, previousYear.net_worth),
      },
      {
        title: 'Debt to Equity Ratio',
        value: currentYear.debt_to_equity_ratio,
        displayValue: currentYear.debt_to_equity_ratio?.toFixed(2),
        change: calculateChange(
          currentYear.debt_to_equity_ratio,
          previousYear.debt_to_equity_ratio,
        ),
        skipCurrencyFormat: true,
      },
      {
        title: 'Inventory Turnover Ratio',
        value: currentYear.inventory_turnover_ratio,
        displayValue: currentYear.inventory_turnover_ratio?.toFixed(2),
        change: calculateChange(
          currentYear.inventory_turnover_ratio,
          previousYear.inventory_turnover_ratio,
        ),
        skipCurrencyFormat: true,
      },
      {
        title: 'Net Sales',
        value: currentYear.net_sales,
        change: calculateChange(currentYear.net_sales, previousYear.net_sales),
      },
      {
        title: 'Cash Conversion Cycle',
        value: currentYear.cash_conversion_cycle,
        unit: 'days',
        change: calculateChange(
          currentYear.cash_conversion_cycle,
          previousYear.cash_conversion_cycle,
        ),
      },
      {
        title: 'Trade Receivables',
        value: currentYear.trade_receivables,
        change: calculateChange(currentYear.trade_receivables, previousYear.trade_receivables),
      },
    ];

    return items.filter((item) => item.value !== null);
  };

  const financialData = highlightsData?.response_data?.highlights?.data?.financial_overview;
  const years = Object.keys(financialData?.data || {}).sort((a, b) => Number(b) - Number(a));
  const currentYear = years[0];
  const previousYear = years[1];

  const getCurrentAndPreviousData = () => {
    if (!financialData?.data || !currentYear || !previousYear)
      return { current: null, previous: null };

    const natureKey = selectedNatureType.toLowerCase() as 'standalone' | 'consolidated';
    const current = financialData.data[currentYear][natureKey];
    const previous = financialData.data[previousYear][natureKey];

    return { current, previous };
  };

  const { current: currentYearData, previous: previousYearData } = getCurrentAndPreviousData();

  const hasConsolidatedData = Boolean(
    currentYear && financialData?.data[currentYear]?.consolidated,
  );

  const highlightSections = [
    {
      title: 'Financial Overview',
      items:
        currentYearData && previousYearData
          ? getFinancialOverviewItems(currentYearData, previousYearData)
          : [],
    },
  ];

  if (isHighlightsLoading) {
    return <Loading />;
  }

  if (isReportFetching) {
    return (
      <div className='w-full flex justify-center items-center  h-[calc(60vh)] '>
        <ErrorMessage
          message={
            'Kindly wait a few minutes, we are fetching the company highlights data for you.'
          }
        />
      </div>
    );
  }

  return (
    <div className='p-4'>
      {highlightsData?.response_data?.highlights ? (
        <>
          <h2 className='text-xl font-semibold text-blue-700 mb-4'>Company Highlights</h2>
          {Boolean(highlightsData?.response_data?.highlights?.data?.financial_overview) && (
            <HighlightsOverview
              sections={highlightSections as HighlightSection[]}
              selectedNatureType={selectedNatureType}
              onNatureTypeChange={onChangeFinancialNature}
              hasConsolidatedData={hasConsolidatedData}
              companyType={companyType}
            />
          )}
          {Boolean(highlightsData?.response_data?.highlights?.data?.epfo_overview) && (
            <CompliancesOverview
              epfoData={highlightsData.response_data.highlights.data.epfo_overview.data}
              gstData={highlightsData.response_data.highlights.data.gst_overview?.data}
            />
          )}
          {Boolean(highlightsData?.response_data?.highlights?.data?.legal_history_overview) && (
            <LegalHistory highlightsData={highlightsData} />
          )}
        </>
      ) : (
        <ErrorMessage message={'Highlights data not available for the company'} />
      )}
    </div>
  );
};

export default Highlights;
