import { EmailContact, PhoneContact } from '@app/types/overview';
import { formatDate } from '@app/components/utils/commonUtils';

type ContactType = 'email' | 'phone';

interface ContactDetailsTooltipProps<T extends ContactType> {
  items: T extends 'email' ? EmailContact[] : PhoneContact[];
  type: T;
}

export function ContactDetailsTooltip<T extends ContactType>({
  items,
  type,
}: ContactDetailsTooltipProps<T>) {
  const remainingItems = items.slice(1);

  function getContactValue(item: EmailContact | PhoneContact) {
    if ('email_id' in item) return item.email_id;
    return item.phone_number;
  }

  return (
    <div className='w-80'>
      <h3 className='font-medium italic mb-2 text-sm'>
        Additional {type === 'email' ? 'Email Addresses' : 'Phone Numbers'}
      </h3>
      <div className='space-y-3'>
        {remainingItems.map((item, index) => {
          const documents = item.documents || [];
          const latestDocument = documents[0];

          return (
            <div key={index} className='border-b border-gray-200 pb-2 last:border-0'>
              <p className='font-semibold text-sm'>{getContactValue(item)}</p>
              <div className='text-xs text-gray-500 italic'>
                <p>Source: {latestDocument?.document_name || 'N/A'}</p>
                <p>
                  Filed on:{' '}
                  {latestDocument?.filing_date ? formatDate(latestDocument.filing_date) : 'N/A'}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
