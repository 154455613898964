import { ChargeSequence, Status, TableRow, ChargeDetailsModalProps } from '@app/types/charges';

export const transformData = (charges: Record<string, ChargeSequence[]>[]) => {
  const tableData: TableRow[] = [];
  const chargeDetailsMap: Record<string, ChargeDetailsModalProps> = {};
  const hasPartialData = false;

  // Process each charge group
  charges?.forEach((chargeGroup) => {
    const chargeId = Object.keys(chargeGroup)[0];
    const sequences = chargeGroup[chargeId];

    if (!sequences?.[0]?.data) return;

    // Find the latest event (MODIFICATION or CREATION)
    const latestEvent =
      sequences.find((seq) => seq.data.event_type === 'MODIFICATION') || sequences[0];
    const { id, charge_id, date, holder_name, event_type, amount, number_of_chargeholder } =
      latestEvent.data;

    const row: TableRow = {
      id: id,
      charge_id: charge_id.toString(),
      date: date,
      holderName: holder_name.toUpperCase(),
      status: event_type as Status,
      amount: amount,
      totalHolders: number_of_chargeholder?.toString() || '-',
    };

    // Add subRows if there are additional charges with the same ID
    if (sequences.length > 1) {
      row.subRows = sequences
        .filter((seq) => seq !== latestEvent) // Exclude the main row event
        .map((subCharge) => ({
          id: subCharge.data.id,
          charge_id: subCharge.data.charge_id.toString(),
          date: subCharge.data.date,
          holderName: subCharge.data.holder_name.toUpperCase(),
          status: subCharge.data.event_type as Status,
          amount: subCharge.data.amount,
          totalHolders: subCharge.data.number_of_chargeholder?.toString() || '-',
        }));
    }

    // Map charge details for both main row and subrows
    chargeDetailsMap[id] = {
      data: latestEvent.data,
      metadata: latestEvent.metadata,
    };

    // Add details for subrows
    if (row.subRows) {
      row.subRows.forEach((subRow) => {
        if (subRow.id) {
          const subCharge = sequences.find((seq) => seq.data.id === subRow.id);
          if (subCharge) {
            chargeDetailsMap[subRow.id] = {
              data: subCharge.data,
              metadata: subCharge.metadata,
            };
          }
        }
      });
    }

    tableData.push(row);
  });

  return { tableData, chargeDetailsMap, hasPartialData };
};
