import React, { useEffect, useState, useMemo } from 'react';
import Modal from '@app/components/lib-components/modal/Modal';
import Icon from '@app/components/lib-components/icon/Icon';
import Button from '@app/components/lib-components/button/Button';
import { FigureFormat, FinancialTabs } from './FinancialTab';
import classNames from 'classnames';
import { CompanyFinancialData, LLPFinancialData, Metadata } from '@app/types/financial';
import pdfIcon from '@app/assets/pdfIcon.svg';
import { DocumentModal } from '@components/lib-components/modal/DocumentModal';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';
import { formatParticular } from '@app/components/utils/commonUtils';
import { usePostHog } from 'posthog-js/react';
import { formatCurrency } from '@app/components/utils/commonUtils';
import { FinanceChart } from '@app/components/charts/FinanceChart';
import {
  AssetsAndLiabilities,
  BalanceSheet,
  ProfitAndLoss,
  IncomeAndExpenditure,
  categoryOrder,
} from './constants';

// Type definitions for table data structure
type CategoryItems = Record<string, Record<string, number | null>>;

interface TableCategory {
  items: CategoryItems;
}

type TableDataType = Record<string, TableCategory>;

type FinancialData<T extends COMPANY_TYPE> = T extends COMPANY_TYPE.LimitedLiabilityPartnership
  ? LLPFinancialData
  : CompanyFinancialData;

interface Props<T extends COMPANY_TYPE> {
  figureFormat: FigureFormat;
  selectedTab: FinancialTabs;
  tableBody: Array<{
    year: number;
    data: FinancialData<T>;
    metadata: Metadata;
  }>;
  companyType: T;
}

// Type guard to check if data is LLP Financial Data
function isLLPFinancialData(data: FinancialData<COMPANY_TYPE>): data is LLPFinancialData {
  return 'statement_of_income_and_expenditure' in data;
}

// Type guard to check if data is Company Financial Data
function isCompanyFinancialData(data: FinancialData<COMPANY_TYPE>): data is CompanyFinancialData {
  return 'profit_and_loss' in data;
}

// Helper function to organize data by categories
const organizeDataByCategories = (
  data: Record<string, number | null | unknown> | null,
  categoryMapping: Record<string, unknown>,
): Record<string, Record<string, number | null>> => {
  const organizedData: Record<string, Record<string, number | null>> = {};

  if (!data) return organizedData;

  Object.entries(data as Record<string, number | null | unknown>).forEach(([key, value]) => {
    if (typeof value === 'number' || value === null) {
      let foundCategory = false;

      // Check main categories
      for (const [mainCategory, subCategories] of Object.entries(categoryMapping)) {
        if (Array.isArray(subCategories)) {
          if (subCategories.includes(key)) {
            if (!organizedData[mainCategory]) {
              organizedData[mainCategory] = {};
            }
            organizedData[mainCategory][key] = value;
            foundCategory = true;
            break;
          }
        } else if (typeof subCategories === 'object' && subCategories !== null) {
          // Check nested categories
          for (const [subCategory, keys] of Object.entries(
            subCategories as Record<string, unknown>,
          )) {
            if (Array.isArray(keys) && keys.includes(key)) {
              const categoryName = `${mainCategory}_${subCategory}`;
              if (!organizedData[categoryName]) {
                organizedData[categoryName] = {};
              }
              organizedData[categoryName][key] = value;
              foundCategory = true;
              break;
            }
          }
          if (foundCategory) break;
        }
      }

      // If no matching category found, add to Others
      if (!foundCategory) {
        if (!organizedData['Others']) {
          organizedData['Others'] = {};
        }
        organizedData['Others'][key] = value;
      }
    }
  });

  return organizedData;
};

export const FinancialExpandableTable = <T extends COMPANY_TYPE>({
  selectedTab,
  tableBody,
  figureFormat,
  companyType,
}: Props<T>) => {
  const posthog = usePostHog();

  // State management for table and modal functionality
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState<{
    title: string;
    data: Array<{ date: string; value: number }>;
  }>({
    title: '',
    data: [],
  });
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [selectedYearData, setSelectedYearData] = useState<{
    data: FinancialData<T>;
    metadata: Metadata;
    year: number;
  } | null>(null);

  // Initialize table headers with fiscal years
  const tableHeaders = useMemo(() => {
    const header = ['Particulars'];
    tableBody.forEach((yearData) => {
      const year = yearData.year ? `FY-${yearData.year}` : 'N/A';
      header.push(year);
    });
    return header;
  }, [tableBody]);

  // Main function to process and structure table data based on company type and selected tab
  const getTableData = (): TableDataType => {
    const data: TableDataType = {};

    tableBody.forEach((yearData) => {
      const year = yearData.year ? `FY-${yearData.year}` : 'N/A';
      const financialData = yearData.data as FinancialData<typeof companyType>;

      if (isLLPFinancialData(financialData)) {
        if (selectedTab === 'pnl') {
          const { line_items, revenue_breakup } = financialData.statement_of_income_and_expenditure;

          // Process line items
          const organizedData = organizeDataByCategories(line_items, {
            line_items: IncomeAndExpenditure.line_items,
          });
          Object.entries(organizedData).forEach(([, items]) => {
            const displayCategory = 'Income and Expenditure';
            if (!data[displayCategory]) {
              data[displayCategory] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data[displayCategory].items[key]) {
                data[displayCategory].items[key] = {};
              }
              data[displayCategory].items[key][year] = value;
            });
          });

          // Process revenue breakup
          const organizedRevenue = organizeDataByCategories(revenue_breakup, {
            revenue_breakup: IncomeAndExpenditure.revenue_breakup,
          });
          Object.entries(organizedRevenue).forEach(([, items]) => {
            const displayCategory = 'Revenue Breakup';
            if (!data[displayCategory]) {
              data[displayCategory] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data[displayCategory].items[key]) {
                data[displayCategory].items[key] = {};
              }
              data[displayCategory].items[key][year] = value;
            });
          });
        } else if (selectedTab === 'balance_sheet') {
          const { assets, liabilities, subTotals } =
            financialData.statement_of_assets_and_liabilities;

          // Process assets
          const organizedAssets = organizeDataByCategories(assets, {
            assets: AssetsAndLiabilities.assets,
          });
          Object.entries(organizedAssets).forEach(([, items]) => {
            const displayCategory = 'Assets';
            if (!data[displayCategory]) {
              data[displayCategory] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data[displayCategory].items[key]) {
                data[displayCategory].items[key] = {};
              }
              data[displayCategory].items[key][year] = value;
            });
          });

          // Process liabilities
          const organizedLiabilities = organizeDataByCategories(liabilities, {
            liabilities: AssetsAndLiabilities.liabilities,
          });
          Object.entries(organizedLiabilities).forEach(([, items]) => {
            const displayCategory = 'Liabilities';
            if (!data[displayCategory]) {
              data[displayCategory] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data[displayCategory].items[key]) {
                data[displayCategory].items[key] = {};
              }
              data[displayCategory].items[key][year] = value;
            });
          });

          // Process sub totals
          const organizedTotals = organizeDataByCategories(subTotals, {
            sub_totals: BalanceSheet.sub_totals,
          });
          Object.entries(organizedTotals).forEach(([, items]) => {
            if (!data['Sub Totals']) {
              data['Sub Totals'] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data['Sub Totals'].items[key]) {
                data['Sub Totals'].items[key] = {};
              }
              data['Sub Totals'].items[key][year] = value;
            });
          });
        }
      } else if (isCompanyFinancialData(financialData)) {
        if (selectedTab === 'pnl') {
          const { line_items, revenue_breakup } = financialData.profit_and_loss;
          const organizedLineItems = organizeDataByCategories(line_items, ProfitAndLoss);

          // Process line items
          Object.entries(organizedLineItems).forEach(([category, items]) => {
            let displayCategory = category;
            if (category === 'line_items') {
              displayCategory = 'Line Items';
            } else if (category === 'revenue') {
              displayCategory = 'Revenue';
            } else if (category === 'expenses') {
              displayCategory = 'Expenses';
            }

            if (!data[displayCategory]) {
              data[displayCategory] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data[displayCategory].items[key]) {
                data[displayCategory].items[key] = {};
              }
              data[displayCategory].items[key][year] = value;
            });
          });

          // Process revenue breakup
          const organizedRevenue = organizeDataByCategories(revenue_breakup, ProfitAndLoss);
          Object.entries(organizedRevenue).forEach(([category, items]) => {
            const displayCategory =
              category === 'revenue_breakup' ? 'Revenue Breakup' : `Revenue ${category}`;
            if (!data[displayCategory]) {
              data[displayCategory] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data[displayCategory].items[key]) {
                data[displayCategory].items[key] = {};
              }
              data[displayCategory].items[key][year] = value;
            });
          });
        } else if (selectedTab === 'balance_sheet') {
          const { assets, liabilities, subTotals } = financialData.balance_sheet;

          // Process assets
          const organizedAssets = organizeDataByCategories(assets, BalanceSheet);
          Object.entries(organizedAssets).forEach(([category, items]) => {
            let displayCategory = category;
            if (category === 'non_current_assets') {
              displayCategory = 'Non-Current Assets';
            } else if (category === 'current_assets') {
              displayCategory = 'Current Assets';
            } else if (category === 'extra_assets') {
              displayCategory = 'Other Assets';
            }

            if (!data[displayCategory]) {
              data[displayCategory] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data[displayCategory].items[key]) {
                data[displayCategory].items[key] = {};
              }
              data[displayCategory].items[key][year] = value;
            });
          });

          // Process liabilities
          const organizedLiabilities = organizeDataByCategories(
            liabilities,
            BalanceSheet.liabilities,
          );
          Object.entries(organizedLiabilities).forEach(([category, items]) => {
            let displayCategory = category;
            if (category === 'equity') {
              displayCategory = 'Equity';
            } else if (category === 'non_current_liabilities') {
              displayCategory = 'Non-Current Liabilities';
            } else if (category === 'current_liabilities') {
              displayCategory = 'Current Liabilities';
            }

            if (!data[displayCategory]) {
              data[displayCategory] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data[displayCategory].items[key]) {
                data[displayCategory].items[key] = {};
              }
              data[displayCategory].items[key][year] = value;
            });
          });

          // Process Sub Totals
          const organizedTotals = organizeDataByCategories(subTotals, {
            sub_totals: BalanceSheet.sub_totals,
          });
          Object.entries(organizedTotals).forEach(([, items]) => {
            if (!data['Sub Totals']) {
              data['Sub Totals'] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data['Sub Totals'].items[key]) {
                data['Sub Totals'].items[key] = {};
              }
              data['Sub Totals'].items[key][year] = value;
            });
          });
        } else if (selectedTab === 'cash_flow' && financialData.cash_flow_statement) {
          // Process cash flow without categorization
          const organizedCashFlow = organizeDataByCategories(financialData.cash_flow_statement, {
            cash_flow: Object.keys(financialData.cash_flow_statement),
          });

          Object.entries(organizedCashFlow).forEach(([, items]) => {
            if (!data['Cash Flow Statement']) {
              data['Cash Flow Statement'] = { items: {} };
            }
            Object.entries(items).forEach(([key, value]) => {
              if (!data['Cash Flow Statement'].items[key]) {
                data['Cash Flow Statement'].items[key] = {};
              }
              data['Cash Flow Statement'].items[key][year] = value;
            });
          });
        }
      }
    });

    return data;
  };

  const tableData = useMemo(() => getTableData(), [selectedTab, figureFormat, tableBody]);

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>(() =>
    Object.keys(tableData).reduce((acc, category) => ({ ...acc, [category]: true }), {}),
  );

  useEffect(() => {
    setExpandedRows(
      Object.keys(tableData).reduce((acc, category) => ({ ...acc, [category]: true }), {}),
    );
  }, [tableData]);

  const handleRowToggle = (category: string) => {
    // Track category expansion/collapse
    posthog.capture('financial_category_toggled', {
      category,
      action: expandedRows[category] ? 'collapse' : 'expand',
      tab: selectedTab,
      company_type: companyType,
    });

    setExpandedRows((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
  };

  // Calculate and format percentage difference between current and previous values
  const getDifference = (current: number, previous: number) => {
    if (!current || !previous || current === undefined || previous === undefined) return null;
    if (previous === 0) return <span className='ml-4 text-xs text-slate-400'>0.00%</span>;

    const diff = ((current - previous) / Math.abs(previous)) * 100;
    const arrow = !isNaN(diff) ? (
      diff > 0 ? (
        <Icon icon='GainIcon' size={16} />
      ) : (
        <Icon icon='LossIcon' size={16} />
      )
    ) : (
      ''
    );
    const color = diff > 0 ? 'text-green-500' : diff < 0 ? 'text-red-500' : 'text-slate-400';

    return (
      <span className={`ml-4 text-xs inline-flex items-center ${color}`}>
        {arrow} {isNaN(diff) ? '' : Math.abs(diff).toFixed(2)}%
      </span>
    );
  };

  // Configure and open chart modal for trend visualization
  const openModal = (particular: string, values: Record<string, number | null>) => {
    posthog.capture('financial_chart_viewed', {
      particular,
      tab: selectedTab,
      type: 'financial_statement',
      company_type: companyType,
      years: Object.keys(values).length,
    });

    const years = Object.keys(values).filter((year) => tableHeaders.includes(year));
    const chartData = years
      .map((year) => ({
        date: year,
        value: values[year] ?? 0,
      }))
      .reverse();

    setChartData({
      title: formatParticular(particular),
      data: chartData,
    });
    setIsModalOpen(true);
  };

  return (
    <>
      <div className='overflow-x-auto w-full border border-gray-200 rounded mt-3 min-h-[200px]'>
        <table className='min-w-max border-separate border-spacing-0 bg-surface-1'>
          <thead className='bg-gray-100/30 text-sm text-gray-600 sticky top-0 z-20'>
            <tr className='bg-surface-2'>
              {tableHeaders.map((header, index) => (
                <th
                  key={index}
                  className={classNames('text-md text-gray-600 p-2 pl-0 text-center', {
                    'sticky left-0 bg-gray-50 z-30 border-r-[0.5px]': index === 0,
                    'bg-neutral-100': index >= 1,
                  })}
                  style={{
                    width:
                      index === 0 ? '35rem' : `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                    minWidth: '18rem',
                  }}
                >
                  <div className='flex items-center justify-center'>
                    {index === 1 && <div className='w-1.5 h-1.5 mr-1 rounded-full bg-green-500' />}
                    {header}
                    {index !== 0 && (
                      <img
                        className='pl-0 scale-[0.5] cursor-pointer z-[1]'
                        src={pdfIcon}
                        onClick={() => {
                          const yearData = tableBody[index - 1];
                          setSelectedYearData(yearData);
                          setIsDocumentModalOpen(true);
                        }}
                      />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(tableData)
              .sort(([a], [b]) => {
                const orderA = categoryOrder[a] || 999;
                const orderB = categoryOrder[b] || 999;
                return orderA - orderB;
              })
              .map(([category, { items }]) => (
                <React.Fragment key={category}>
                  <tr className='bg-[#F7F4ED] text-tertiary'>
                    <td
                      className='text-sm flex items-center font-medium py-4 pl-8 sticky left-0 z-10 bg-[#F7F4ED] border-r-[0.5px] cursor-pointer'
                      style={{ width: '35rem' }}
                      onClick={() => handleRowToggle(category)}
                    >
                      <span>{category}</span>
                      <Button className='bg-transparent'>
                        <Icon
                          icon={expandedRows[category] ? 'MinusCircleIcon' : 'PlusCircleIcon'}
                          color='#c6c6c6'
                          size={22}
                          className='cursor-pointer'
                          variant='outline'
                        />
                      </Button>
                    </td>
                    {tableHeaders.slice(1).map((_, index) => (
                      <td
                        key={index}
                        className='text-sm py-4 px-4 pl-6 text-center bg-neutral-100/30'
                        style={{
                          width: `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                          minWidth: '18rem',
                        }}
                      />
                    ))}
                  </tr>
                  {expandedRows[category] &&
                    Object.entries(items).map(([particular, values], index) => (
                      <tr
                        key={particular}
                        className={classNames('border-y border-primary', {
                          'bg-gray-50': index % 2 === 0,
                        })}
                      >
                        <td
                          className='text-sm flex items-center justify-between font-medium py-5 px-16 sticky left-0 z-10 bg-surface-base bg-table-secondary'
                          style={{ width: '35rem' }}
                        >
                          <span>{formatParticular(particular)}</span>
                          {Object.values(values).some((value) => Number(value) !== 0) && (
                            <Button
                              className='bg-transparent ml-2'
                              onClick={() => openModal(particular, values)}
                            >
                              <Icon
                                icon='BarChartIcon'
                                color='#c6c6c6'
                                size={22}
                                className='cursor-pointer'
                              />
                            </Button>
                          )}
                        </td>
                        {tableHeaders.slice(1).map((header, headerIndex) => {
                          const nextYearHeader = tableHeaders[headerIndex + 2];
                          return (
                            <td
                              key={header}
                              className={classNames(
                                'text-sm font-medium py-5 px-4 text-center',
                                index % 2 === 0 ? 'bg-[#F5F2EB]' : 'bg-table-secondary',
                              )}
                              style={{
                                width: `calc((90vw - 26rem) / ${tableHeaders.length - 1})`,
                                minWidth: '18rem',
                              }}
                            >
                              {formatCurrency(Number(values[header]), figureFormat)}
                              {nextYearHeader &&
                                getDifference(
                                  Number(values[header]),
                                  Number(values[nextYearHeader]),
                                )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                </React.Fragment>
              ))}
          </tbody>
        </table>
      </div>
      <Modal
        className='w-[40%] max-w-[90vw]'
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={chartData.title}
      >
        <FinanceChart data={chartData.data} title={chartData.title} figureFormat={figureFormat} />
      </Modal>
      <DocumentModal
        isOpen={isDocumentModalOpen}
        onClose={() => {
          setIsDocumentModalOpen(false);
        }}
        documentData={selectedYearData}
      />
    </>
  );
};
