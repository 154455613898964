import React from 'react';
import { PreviousAddress as PreviousAddressType } from '@app/types/overview';
import { formatDate } from '@app/components/utils/commonUtils';
import { Tooltip } from '@app/components/Tooltip/Tooltip';
import { AddressTooltip } from './AddressTooltip';

interface PreviousAddressProps {
  addresses: PreviousAddressType[] | null;
}

export const PreviousAddress: React.FC<PreviousAddressProps> = ({ addresses }) => {
  if (!addresses || addresses.length === 0) return null;

  const allAddresses = addresses
    .filter((addr) => addr.unprocessed_address)
    .sort((a, b) => {
      if (!a.effective_date) return 1;
      if (!b.effective_date) return -1;
      return new Date(b.effective_date).getTime() - new Date(a.effective_date).getTime();
    });

  if (allAddresses.length === 0) return null;

  const [firstAddress, ...remainingAddresses] = allAddresses;

  return (
    <div>
      <span>Other Addresses</span>
      <div className='font-semibold text-gray-800'>
        <div>
          {firstAddress.unprocessed_address}
          <div className='text-xs italic text-gray-500 my-1'>
            <p>Source: {firstAddress.document_name || 'N/A'}</p>
            <p>
              Filed on: {firstAddress.filing_date ? formatDate(firstAddress.filing_date) : 'N/A'}
            </p>
          </div>
          {remainingAddresses.length > 0 && (
            <Tooltip
              content={<AddressTooltip addresses={remainingAddresses} />}
              place='top-left'
              className='!bg-white !p-4 !text-gray-700 w-80 min-w-60 max-w-fit shadow-md mr-5'
            >
              <span className='cursor-pointer text-utility-blue-600 mt-1 w-fit hover:no-underline hover:text-utility-blue-800 rounded-md bg-gray-50 p-1 px-2 text-xs italic'>
                +{remainingAddresses.length} more{' '}
                {remainingAddresses.length === 1 ? 'address' : 'addresses'}
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};
