import { axiosBaseQuery } from '@app/store/axiosBase';
import { createApi } from '@reduxjs/toolkit/query/react';
import { GetByIdentifeirPayload, GetCompanyByIdentifierResponse, AdhocTaskPayload } from './types';
import { ApiResponse } from '@app/types';

const requestAdhocTasksApi = createApi({
  reducerPath: 'requestAdhocTasksApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['company-api'],
  endpoints: (builder) => ({
    searchCompanyByIdentifier: builder.query<
      ApiResponse<GetCompanyByIdentifierResponse[]>,
      GetByIdentifeirPayload
    >({
      query: (payload) => ({
        url: `/entity/get-all-by-identifier`,
        method: 'get',
        params: payload,
      }),
    }),
    requestAdhocTask: builder.mutation<void, AdhocTaskPayload>({
      query: (payload) => ({
        url: `/request_company/create`,
        method: 'post',
        data: {
          ...payload,
          adhoc_request_type: payload.adhoc_request_type,
          adhoc_metadata: {
            document_list: payload.adhoc_metadata?.document_list,
          },
        },
      }),
    }),
  }),
});

export const { useLazySearchCompanyByIdentifierQuery, useRequestAdhocTaskMutation } =
  requestAdhocTasksApi;

export default requestAdhocTasksApi;
