import { useEffect, useState } from 'react';

interface Section {
  id: string;
  show?: boolean;
}

interface UseIntersectionObserverProps {
  sections: Section[];
  onSectionChange: (sectionId: string) => void;
  options?: IntersectionObserverInit;
  debounceTime?: number;
}

const defaultOptions: IntersectionObserverInit = {
  threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
  rootMargin: '-80px 0px -20% 0px',
};

export const useIntersectionObserver = ({
  sections,
  onSectionChange,
  options = defaultOptions,
  debounceTime = 100,
}: UseIntersectionObserverProps) => {
  const [debouncedSection, setDebouncedSection] = useState<string | null>(null);

  // Handle debounced section changes
  useEffect(() => {
    if (debouncedSection === null) return;

    const timer = setTimeout(() => {
      onSectionChange(debouncedSection);
    }, debounceTime);

    return () => clearTimeout(timer);
  }, [debouncedSection, onSectionChange, debounceTime]);

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = (entries) => {
      let maxVisibility = 0;
      let mostVisibleSection: string | null = null;

      entries.forEach((entry) => {
        const visibleHeight =
          Math.min(entry.intersectionRect.bottom, window.innerHeight) -
          Math.max(entry.intersectionRect.top, 0);

        const visibilityRatio = visibleHeight / entry.target.getBoundingClientRect().height;

        if (visibilityRatio > maxVisibility) {
          maxVisibility = visibilityRatio;
          mostVisibleSection = entry.target.id;
        }

        if (
          entry.isIntersecting &&
          entry.boundingClientRect.bottom <= window.innerHeight &&
          entry.boundingClientRect.bottom > window.innerHeight * 0.5
        ) {
          mostVisibleSection = entry.target.id;
        }
      });

      if (mostVisibleSection) {
        setDebouncedSection(mostVisibleSection);
      }
    };

    const observer = new IntersectionObserver(observerCallback, options);

    sections
      .filter((section) => section.show !== false)
      .forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          observer.observe(element);
        }
      });

    return () => observer.disconnect();
  }, [sections, options]);
};
