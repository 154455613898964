import React, { useState } from 'react';
import Accordian from '@components/lib-components/accordian/Accordian';
import { EPFOOverview } from './EPFOOverview';
import { GSTOverview } from './GSTOverview';
import { YearlyDelayData } from '@app/types/highlights';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';

interface CompliancesOverviewProps {
  epfoData: YearlyDelayData[];
  gstData?: YearlyDelayData[];
}

export const CompliancesOverview: React.FC<CompliancesOverviewProps> = ({ epfoData, gstData }) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Accordian
      title='Compliances Overview'
      isOpen={isOpen}
      onToggle={() => setIsOpen(!isOpen)}
      className='mt-6'
    >
      <div>
        {epfoData && epfoData.length > 0 ? (
          <EPFOOverview data={epfoData} />
        ) : (
          <ErrorMessage message='EPFO data not available' />
        )}
        {gstData && gstData.length > 0 ? (
          <GSTOverview data={gstData} />
        ) : (
          <ErrorMessage message='GST data not available' />
        )}
      </div>
    </Accordian>
  );
};
