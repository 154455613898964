import React, { useState, useMemo, useCallback } from 'react';
import Accordion from '@components/lib-components/accordian/Accordian';
import {
  useGetUserTemplatesQuery,
  useGetOrgTemplatesQuery,
  useLazyGetTemplateDataQuery,
} from '@app/store/api/template/templateApi';
import { useParams } from 'react-router-dom';
import { FinancialHighlightItem } from './highlights/FinancialHighlightItem';
import { ComplianceHighlightItem } from './highlights/ComplianceHighlightItem';
import { TemplateSelector } from './highlights/TemplateSelector';
import { HighlightsOverviewProps } from './highlights/types';
import { BaseOptionType } from '@components/lib-components/drop-down/Dropdown';
import Button from '@components/lib-components/button/Button';
import classNames from 'classnames';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';
import { NatureType } from './financial-analysis/FinancialAnalysisTab';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';

interface ExtendedOptionType extends BaseOptionType {
  createdBy?: string;
}

const HighlightsOverview: React.FC<HighlightsOverviewProps> = ({
  sections,
  selectedNatureType,
  onNatureTypeChange,
  hasConsolidatedData,
  companyType,
}) => {
  const { id } = useParams<{ id: string }>();
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const [openSections, setOpenSections] = useState<Record<string, boolean>>(
    sections.reduce((acc, section) => ({ ...acc, [section.title]: true }), {}),
  );

  const { data: userTemplates } = useGetUserTemplatesQuery({
    skip: 0,
    limit: 1000,
    identifier_type: identifierType,
  });

  const { data: orgTemplates } = useGetOrgTemplatesQuery({
    skip: 0,
    limit: 1000,
    identifier_type: identifierType,
  });

  const templateOptions = useMemo(() => {
    const userTemplateOptions =
      userTemplates?.response_data?.map((template) => ({
        label: template.name,
        value: template.id,
      })) || [];

    const orgTemplateOptions =
      orgTemplates?.response_data?.map((template) => ({
        label: template.name,
        value: template.id,
        createdBy: template.created_by?.name,
      })) || [];

    return [
      {
        label: 'My Templates',
        options: userTemplateOptions,
      },
      {
        label: 'All Templates',
        options: orgTemplateOptions,
      },
    ];
  }, [userTemplates?.response_data, orgTemplates?.response_data]);

  const financialFieldName =
    selectedNatureType === 'STANDALONE' ? 'financials_standalone' : 'financials_consolidated';

  const [getTemplateData, { data: templateData, isLoading: isTemplateDataLoading, originalArgs }] =
    useLazyGetTemplateDataQuery();

  const handleTemplateChange = (selected: ExtendedOptionType | null) => {
    if (selected?.value) {
      getTemplateData({
        template_id: selected.value,
        identifier_value: id as string,
        field_name:
          companyType === COMPANY_TYPE.LimitedLiabilityPartnership
            ? 'financials'
            : financialFieldName,
      });
    }
  };

  const toggleSection = useCallback((title: string) => {
    setOpenSections((prev) => ({ ...prev, [title]: !prev[title] }));
  }, []);

  const handleTemplateDataChange = (natureType: NatureType) => {
    onNatureTypeChange(natureType);
    if (originalArgs?.template_id) {
      getTemplateData({
        template_id: originalArgs?.template_id,
        identifier_value: id as string,
        field_name:
          natureType === 'STANDALONE' ? 'financials_standalone' : 'financials_consolidated',
      });
    }
  };

  return (
    <div className='space-y-4'>
      <div className='flex items-center justify-start mb-4'>
        <TemplateSelector
          templateOptions={templateOptions}
          onTemplateChange={handleTemplateChange}
          isLoading={isTemplateDataLoading}
          templateData={templateData?.response_data}
          companyType={companyType}
          selectedNatureType={selectedNatureType}
        />
      </div>
      {sections.map((section) => (
        <Accordion
          key={section.title}
          title={section.title}
          subtitle={section.subtitle}
          isOpen={openSections[section.title]}
          onToggle={() => toggleSection(section.title)}
          iconFlag={section.flags}
        >
          {section.title === 'Financial Overview' &&
            hasConsolidatedData &&
            companyType !== COMPANY_TYPE.LimitedLiabilityPartnership && (
              <div className='flex justify-start mb-4'>
                <div className='inline-flex rounded-md shadow-sm'>
                  {['STANDALONE', 'CONSOLIDATED'].map((type) => (
                    <Button
                      key={type}
                      text={type.charAt(0) + type.slice(1).toLowerCase()}
                      variant='text'
                      className={classNames(
                        'whitespace-nowrap font-bold py-2 px-3 border-2 text-sm text-gray-700 transition-all duration-200 no-underline hover:no-underline',
                        {
                          'bg-blue-500 text-white border-blue-500 hover:bg-blue-600':
                            selectedNatureType === type,
                          'bg-gray-100 border-gray-300 hover:bg-gray-200':
                            selectedNatureType !== type,
                        },
                        type === 'STANDALONE' ? 'rounded-l-lg' : 'rounded-r-lg border-l-0',
                      )}
                      onClick={() => handleTemplateDataChange(type as NatureType)}
                    />
                  ))}
                </div>
              </div>
            )}
          <div
            className={`grid ${section.title === 'Financial Overview' ? 'grid-cols-2 md:grid-cols-4 lg:grid-cols-4' : 'grid-cols-1 md:grid-cols-3'} gap-4`}
          >
            {section.items.map((item, index) => (
              <React.Fragment key={index}>
                {section.title === 'Financial Overview' ? (
                  <FinancialHighlightItem item={item} />
                ) : (
                  <ComplianceHighlightItem item={item} />
                )}
              </React.Fragment>
            ))}
          </div>
        </Accordion>
      ))}
    </div>
  );
};

export default HighlightsOverview;
