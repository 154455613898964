import { MONTHS } from '@components/utils/constants';
import { CourtCases, CourtType } from './legal';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';

export type FinancialMetrics = {
  total_assets: number | null;
  reserves_and_surplus: number | null;
  shareholders_fund_capital: number | null;
  net_worth: number | null;
  debt_to_equity_ratio: number | null;
  inventory_turnover_ratio: number | null;
  net_sales: number | null;
  cash_conversion_cycle: number | null;
  trade_receivables: number | null;
};

export type YearlyFinancials = {
  standalone?: FinancialMetrics;
  consolidated?: FinancialMetrics;
};

export enum RiskCategory {
  LOW = 'LOW_RISK_FLAG',
  NEUTRAL = 'NEUTRAL_AVERAGE_RISK_FLAG',
  HIGH = 'HIGH_RISK_FLAG',
}

export type FinancialOverview = {
  data: {
    [year: string]: YearlyFinancials;
  };
  metadata: {
    risk_category: RiskCategory;
  };
};

export interface MonthlyDelayData {
  month: (typeof MONTHS)[number];
  delay_days: number;
  risk_category: RiskCategory;
}

// Common type for yearly data structure
export interface YearlyDelayData {
  year: number;
  month_wise_delay_days: MonthlyDelayData[];
}

export interface GSTOverview {
  data: YearlyDelayData[];
  metadata: null;
}

export interface EPFOOverview {
  data: YearlyDelayData[];
}

export interface HighlightsData {
  highlights: {
    data: {
      financial_overview: {
        data: Record<
          string,
          {
            standalone: FinancialMetrics;
            consolidated: FinancialMetrics;
          }
        >;
        metadata: {
          risk_category: RiskCategory;
        };
      };
      legal_history_overview: {
        data: Record<CourtType, CourtCases[]>;
      };
      epfo_overview: EPFOOverview;
      gst_overview?: GSTOverview;
    };
  };
  status: ReportStatusEnum;
}
