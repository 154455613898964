import React, { useState, useMemo, useCallback } from 'react';
import { YearlyDelayData, RiskCategory } from '@app/types/highlights';
import Accordian from '@components/lib-components/accordian/Accordian';
import { MonthlyDelayGrid } from './MonthlyDelayGrid';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { RiskBadgeGroup } from './shared/RiskBadgeGroup';

interface GSTOverviewProps {
  data: YearlyDelayData[];
}

const getYearTitle = (yearData: YearlyDelayData) => {
  const avgDelayDays = Math.round(
    yearData.month_wise_delay_days.reduce((acc, curr) => acc + curr.delay_days, 0) /
      yearData.month_wise_delay_days.length,
  );

  return (
    <div className='flex items-center gap-4'>
      <span>Year {yearData.year}</span>
      <span>Avg Delay: {avgDelayDays} days</span>
    </div>
  );
};

export const GSTOverview: React.FC<GSTOverviewProps> = ({ data }) => {
  const [isMainAccordionOpen, setIsMainAccordionOpen] = useState(false);

  const sortedData = useMemo(() => [...data].sort((a, b) => b.year - a.year), [data]);

  const [openAccordions, setOpenAccordions] = useState<Record<number, boolean>>(() => {
    const initial: Record<number, boolean> = {};
    if (sortedData.length > 0) {
      initial[sortedData[0].year] = true;
    }
    return initial;
  });

  const toggleAccordion = useCallback((year: number) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [year]: !prev[year],
    }));
  }, []);

  const totalRiskCounts = useMemo(() => {
    const allMonths = data
      .flatMap((year) => year.month_wise_delay_days || [])
      .filter((month): month is NonNullable<typeof month> => month != null);
    if (allMonths.length === 0) {
      return null;
    }
    return {
      low: allMonths.filter((m) => m.risk_category === RiskCategory.LOW).length,
      medium: allMonths.filter((m) => m.risk_category === RiskCategory.NEUTRAL).length,
      high: allMonths.filter((m) => m.risk_category === RiskCategory.HIGH).length,
    };
  }, [data]);

  return totalRiskCounts &&
    (totalRiskCounts.low > 0 || totalRiskCounts.medium > 0 || totalRiskCounts.high > 0) ? (
    <Accordian
      title='GST Filing Overview'
      isOpen={isMainAccordionOpen}
      onToggle={() => setIsMainAccordionOpen(!isMainAccordionOpen)}
      className='mt-4'
      count={data.reduce((acc, year) => acc + year.month_wise_delay_days.length, 0)}
      iconFlag={<RiskBadgeGroup monthData={data.flatMap((year) => year.month_wise_delay_days)} />}
    >
      <div className='space-y-4'>
        {sortedData.map((yearData) => (
          <Accordian
            key={yearData.year}
            title={getYearTitle(yearData)}
            isOpen={!!openAccordions[yearData.year]}
            onToggle={() => toggleAccordion(yearData.year)}
            count={yearData.month_wise_delay_days.length}
            iconFlag={<RiskBadgeGroup monthData={yearData.month_wise_delay_days} />}
          >
            <div className='py-4'>
              <MonthlyDelayGrid monthData={yearData.month_wise_delay_days} />
            </div>
          </Accordian>
        ))}
      </div>
    </Accordian>
  ) : (
    <ErrorMessage message='GST data not available' />
  );
};
