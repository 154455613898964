import { useGetCompanyData } from '@app/store/api/companyApi';
import { ApiResponse } from '@app/types';
import Loading from '@components/lib-components/loading/Loading';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { DocumentIconButton } from '@components/lib-components/DocumentIconButton';
import { LatestDocumentsResponse } from '@app/types/documents';

interface LatestDocumentsSectionProps {
  cinId: string;
}

const LatestDocumentsSection: React.FC<LatestDocumentsSectionProps> = ({ cinId }) => {
  const identifierType = useSelector((state: RootState) => state.util.identifierType);

  const getCompanyOverviewData = useGetCompanyData<ApiResponse<LatestDocumentsResponse>>();

  const {
    data: responseData,
    isLoading,
    isError,
  } = getCompanyOverviewData({
    cinId,
    field_name: 'latest_documents',
    identifierType,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (isError || !responseData?.response_data?.latest_documents) {
    return <ErrorMessage message='Latest documents data not available' />;
  }

  const latestDocuments = responseData.response_data.latest_documents;

  return (
    <div className='mb-2 w-fit shadow-md rounded-md p-4 pl-5 pr-8'>
      <h4 className='text-lg font-semibold mb-4 ml-4'>Latest Documents</h4>
      <div className='flex flex-wrap gap-4'>
        {latestDocuments.map((doc) => (
          <DocumentIconButton
            key={doc.id}
            metadata={doc}
            documentData={{
              metadata: doc,
              data: null,
            }}
            height={80}
            width={80}
            showName={true}
          />
        ))}
      </div>
    </div>
  );
};

export default LatestDocumentsSection;
