import React from 'react';
import { PreviousAddress as PreviousAddressType } from '@app/types/overview';
import { formatDate } from '@app/components/utils/commonUtils';

interface AddressTooltipProps {
  addresses: PreviousAddressType[];
}

export const AddressTooltip: React.FC<AddressTooltipProps> = React.memo(({ addresses }) => {
  return (
    <div className='space-y-4'>
      {addresses.map((addr) => (
        <div key={addr.id} className='border-b border-gray-100 pb-2 last:border-0'>
          <p className='font-medium'>{addr.unprocessed_address}</p>
          <div className='text-xs italic text-gray-500 mt-1'>
            <p>Source: {addr.document_name || 'N/A'}</p>
            <p>Filed on: {addr.filing_date ? formatDate(addr.filing_date) : 'N/A'}</p>
          </div>
        </div>
      ))}
    </div>
  );
});
