import React from 'react';
import { Dropdown } from '@components/lib-components/drop-down/Dropdown';
import { HighlightsTemplateProcessor } from './HighlightsTemplateProcessor';
import { COMPANY_TYPE } from '@app/store/slices/utilSlice';
import { TemplateDataResponse } from '@app/store/api/template/template.type';
import { BaseOptionType } from '@components/lib-components/drop-down/Dropdown';
import { GroupBase, components, OptionProps } from 'react-select';

interface ExtendedOptionType extends BaseOptionType {
  createdBy?: string;
}

interface TemplateSelectorProps {
  templateOptions: Array<GroupBase<ExtendedOptionType>>;
  onTemplateChange: (selected: ExtendedOptionType | null) => void;
  isLoading: boolean;
  templateData: TemplateDataResponse | undefined;
  companyType: COMPANY_TYPE;
  selectedNatureType?: 'STANDALONE' | 'CONSOLIDATED';
}

const CustomOption = (props: OptionProps<BaseOptionType, boolean, GroupBase<BaseOptionType>>) => {
  const { data } = props;
  const extendedData = data as ExtendedOptionType;

  return (
    <components.Option {...props}>
      <div className='flex justify-between items-center w-full gap-2'>
        <span className='truncate'>{extendedData.label}</span>
        {extendedData.createdBy && (
          <span className='text-xs text-gray-500 italic whitespace-nowrap'>
            by {extendedData.createdBy}
          </span>
        )}
      </div>
    </components.Option>
  );
};

export const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  templateOptions,
  onTemplateChange,
  isLoading,
  templateData,
  companyType,
  selectedNatureType,
}) => {
  return (
    <div className='flex items-center gap-2 mb-3'>
      <div className='relative'>
        <Dropdown
          options={templateOptions}
          onChange={(value) => onTemplateChange(value as ExtendedOptionType | null)}
          className='w-80 min-w-80'
          label='Select Template to copy financials'
          placeholder='Select Template'
          isDisabled={isLoading}
          components={{
            Option: CustomOption,
          }}
          styles={{
            option: (baseStyles) => ({
              ...baseStyles,
              padding: '8px 12px',
              whiteSpace: 'normal',
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              zIndex: 50,
            }),
            group: (baseStyles) => ({
              ...baseStyles,
              paddingTop: 8,
              paddingBottom: 8,
            }),
            groupHeading: (baseStyles) => ({
              ...baseStyles,
              color: '#6B7280',
              fontWeight: 600,
              fontSize: '0.875rem',
              textTransform: 'uppercase',
              paddingLeft: 12,
              paddingRight: 12,
              marginBottom: 4,
            }),
          }}
        />
      </div>
      {templateData && (
        <HighlightsTemplateProcessor
          data={templateData}
          companyType={companyType}
          type={selectedNatureType || 'STANDALONE'}
        />
      )}
    </div>
  );
};
