export const AssetsAndLiabilities = {
  assets: [
    'gross_fixed_assets',
    'depreciation_and_amortization',
    'investments',
    'loans_and_advances',
    'inventories',
    'trade_receivables',
    'cash_and_cash_equivalents',
    'other_assets',
    'net_fixed_assets',
  ],
  sub_totals: ['given_assets_total', 'given_liabilities_total'],
  liabilities: [
    'contributions_received',
    'reserves_and_surplus',
    'secured_loans',
    'unsecured_loans',
    'short_term_borrowings',
    'trade_payables',
    'other_liabilities',
    'provisions_for_taxation',
    'provisions_for_contingencies',
    'provisions_for_insurance',
    'other_provisions',
  ],
};

export const BalanceSheet = {
  non_current_assets: [
    'tangible_assets',
    'intangible_assets',
    'tangible_assets_capital_work_in_progress',
    'intangible_assets_under_development',
    'noncurrent_investments',
    'deferred_tax_assets_net',
    'long_term_loans_and_advances',
    'other_noncurrent_assets',
  ],
  current_assets: [
    'current_investments',
    'inventories',
    'trade_receivables',
    'cash_and_bank_balances',
    'short_term_loans_and_advances',
    'other_current_assets',
  ],
  extra_assets: [
    'foreign_curr_monetary_item_trans_diff_asset_account',
    'producing_properties',
    'preproducing_properties',
  ],
  liabilities: {
    equity: [
      'share_capital',
      'reserves_and_surplus',
      'money_received_against_share_warrants',
      'share_application_money_pending_allotment',
    ],
    non_current_liabilities: [
      'long_term_borrowings',
      'deferred_tax_liabilities_net',
      'long_term_provisions',
      'other_long_term_liabilities',
    ],
    current_liabilities: ['short_term_borrowings', 'trade_payables', 'short_term_provisions'],
  },
  sub_totals: [
    'net_fixed_assets',
    'total_current_assets',
    'total_other_non_current_assets',
    'difference_value_of_assets',
    'total_equity',
    'total_non_current_liabilities',
    'total_current_liabilities',
    'total_debt',
    'difference_value_of_liabilities',
  ],
};

export const ProfitAndLoss = {
  line_items: [
    'operating_profit',
    'depreciation',
    'profit_before_interest_and_tax',
    'interest',
    'profit_before_exceptional_items_and_tax',
    'exceptional_items_before_tax',
    'profit_before_tax',
    'income_tax',
    'profit_after_tax',
    'profit_for_period_from_continuing_operations',
    'profit_from_discontinuing_operation_after_tax',
    'minority_interest_and_profit_from_associates_and_joint_ventures',
  ],
  revenue: ['other_income', 'net_revenue'],
  expenses: [
    'total_cost_of_materials_consumed',
    'total_purchases_of_stock_in_trade',
    'total_changes_in_inventories_or_finished_goods',
    'total_employee_benefit_expense',
    'other_expenses',
    'remuneration_for_key_managerial_personnel',
    'payment_to_auditors',
    'total_other_expenses',
    'total_expenses',
  ],
  revenue_breakup: [
    'revenue_from_operations',
    'revenue_from_sale_of_products',
    'revenue_from_sale_of_services',
  ],
};

export const IncomeAndExpenditure = {
  line_items: [
    'net_revenue',
    'total_cost_of_materials_consumed',
    'total_purchases_of_stock_in_trade',
    'total_changes_in_inventories_or_finished_goods',
    'total_employee_benefit_expense',
    'other_income',
    'depreciation',
    'interest',
    'income_tax',
    'consumptions_of_stores_and_spares',
    'other_expenses',
    'power_and_fuel',
    'administrative_expenses',
    'selling_expenses',
    'insurance_expenses',
    'payment_to_auditors',
    'total_income',
    'COMPUTED_profit_before_interest_and_tax',
    'COMPUTED_profit_before_tax_and_exceptional_items_before_tax',
    'COMPUTED_profit_before_tax',
    'COMPUTED_profit_after_tax',
    'COMPUTED_total_other_expenses',
    'COMPUTED_total_expenses',
    'COMPUTED_operating_profit',
  ],
  revenue_breakup: [
    'sales_of_goods_manufactured_domestic',
    'sales_of_goods_traded_domestic',
    'sales_or_supply_of_services_domestic',
    'sales_of_goods_manufactured_export',
    'sales_of_goods_traded_export',
    'sales_or_supply_of_services_export',
  ],
};

export const categoryOrder: Record<string, number> = {
  'Non-Current Assets': 1,
  'Current Assets': 2,
  'Other Assets': 3,
  Equity: 4,
  'Non-Current Liabilities': 5,
  'Current Liabilities': 6,
  'Sub Totals': 7,
  Others: 8,
  // For Income Statement
  'Line Items': 1,
  Revenue: 2,
  Expenses: 3,
  'Revenue Breakup': 4,
  // For LLP
  Assets: 1,
  Liabilities: 2,
  'Income and Expenditure': 1,
  // For Cash Flow
  'Cash Flow Statement': 1,
};
