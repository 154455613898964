import React from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { formatCurrency } from '@app/components/utils/commonUtils';
import { FigureFormat } from '@app/components/company/company-tabs/financials/FinancialTab';

interface DataPoint {
  date: string;
  value: number;
}

interface FinanceChartProps {
  data: DataPoint[];
  title: string;
  yAxisLabel?: string;
  figureFormat?: FigureFormat;
}

export const FinanceChart: React.FC<FinanceChartProps> = ({
  data,
  title,
  yAxisLabel,
  figureFormat,
}) => {
  const formatValue = (value: number) => {
    if (figureFormat) {
      return formatCurrency(value, figureFormat);
    }
    return value.toFixed(2);
  };

  return (
    <div className='bg-white'>
      {yAxisLabel && <p className='text-gray-600 text-sm mb-4'>{yAxisLabel}</p>}
      <div className='h-[300px] w-full'>
        <ResponsiveContainer width='100%' height='100%'>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 20,
            }}
          >
            <defs>
              <linearGradient id='colorValue' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#30B0C7' stopOpacity={0.4} />
                <stop offset='95%' stopColor='#30B0C7' stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray='3 3' vertical={false} stroke='#E5E7EB' />
            <XAxis
              dataKey='date'
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#6B7280', fontSize: 12 }}
              dy={10}
              padding={{ left: 30 }}
            />
            <YAxis
              axisLine={false}
              tickLine={false}
              tick={(props) => {
                const { x, y, payload } = props;
                const formattedValue = formatValue(payload.value);
                if (figureFormat) {
                  const [value, unit] = formattedValue.split(' ');
                  return (
                    <g transform={`translate(${x},${y})`}>
                      <text x={0} y={0} dy={-6} textAnchor='end' fill='#6B7280' fontSize={12}>
                        {value}
                      </text>
                      <text x={0} y={0} dy={12} textAnchor='end' fill='#6B7280' fontSize={12}>
                        {unit}
                      </text>
                    </g>
                  );
                }
                return (
                  <g transform={`translate(${x},${y})`}>
                    <text x={0} y={0} dy={4} textAnchor='end' fill='#6B7280' fontSize={12}>
                      {formattedValue}
                    </text>
                  </g>
                );
              }}
              width={80}
              padding={{ top: 20 }}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#fff',
                border: '1px solid #E5E7EB',
                borderRadius: '8px',
                boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
              }}
              labelStyle={{ color: '#374151' }}
              itemStyle={{ color: '#374151' }}
              formatter={(value: number) => [formatValue(value), title]}
            />
            <Area
              type='monotone'
              dataKey='value'
              stroke='#30B0C7'
              strokeWidth={2}
              fill='url(#colorValue)'
              dot={{
                r: 4,
                fill: '#30B0C7',
                stroke: '#FFFFFF',
                strokeWidth: 2,
              }}
              activeDot={{
                r: 6,
                fill: '#30B0C7',
                stroke: '#FFFFFF',
                strokeWidth: 2,
              }}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
