import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { ApiResponse } from '@app/types';
import {
  ShareholdingData,
  IndividualPartner,
  BodyCorporatePartner,
} from '../ShareholdingProps.types';
import { RootState } from '@app/store/store';
import Table from '@app/components/lib-components/table/table/Table';
import InfoCard from '@app/components/lib-components/info-card/InfoCard';
import Loading from '@components/lib-components/loading/Loading';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import {
  convertToTitleCase,
  formatCurrencyNumber,
  formatDate,
} from '@components/utils/commonUtils';
import { DocumentIconButton } from '@components/lib-components/DocumentIconButton';

const LLPShareholdingPattern: React.FC = () => {
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const { id } = useParams<string>();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentBodyCorpPage, setCurrentBodyCorpPage] = useState(1);
  const rowsPerPage = 10;

  const isReportFetching = useSelector((state: RootState) => state.util.isReportFetching);

  const getShareholdingData = useGetCompanyData<ApiResponse<ShareholdingData>>();
  const {
    data: shareholdingData,
    isLoading,
    isError,
  } = getShareholdingData({
    cinId: id as string,
    field_name: 'contribution_summary',
    identifierType: identifierType,
  });

  const contributionSummary = shareholdingData?.response_data?.contribution_summary;

  const partnerTableHeaders = [
    { name: 'DIN/PAN', value: 'partner_id', classes: 'p-4 text-sm' },
    { name: 'Name', value: 'name', classes: 'p-4 text-sm' },
    { name: 'Designation', value: 'designation', classes: 'p-4 text-sm' },
    { name: 'Obligation Contribution', value: 'obligation_contribution', classes: 'p-4 text-sm' },
    { name: 'Received Contribution', value: 'received_contribution', classes: 'p-4 text-sm' },
    { name: 'Resident Indian', value: 'resident_indian', classes: 'p-4 text-sm' },
  ];

  const bodyCorporateTableHeaders = [
    { name: 'CIN/FCRN', value: 'partner_id', classes: 'p-4 text-sm' },
    { name: 'Name', value: 'name', classes: 'p-4 text-sm' },
    { name: 'Designation', value: 'designation', classes: 'p-4 text-sm' },
    { name: 'Obligation Contribution', value: 'obligation_contribution', classes: 'p-4 text-sm' },
    { name: 'Received Contribution', value: 'received_contribution', classes: 'p-4 text-sm' },
    { name: 'Category', value: 'category', classes: 'p-4 text-sm' },
    { name: 'Resident Indian', value: 'resident_indian', classes: 'p-4 text-sm' },
  ];

  const formatPartnerData = (partner: IndividualPartner | BodyCorporatePartner) => ({
    partner_id: partner.partner_id || '-',
    name: partner.name || '-',
    designation: partner.designation || '-',
    obligation_contribution: partner.obligation_contribution
      ? formatCurrencyNumber(partner.obligation_contribution)
      : '-',
    received_contribution: partner.received_contribution
      ? formatCurrencyNumber(partner.received_contribution)
      : '-',
    resident_indian:
      partner.resident_indian === null ? '-' : partner.resident_indian ? 'Yes' : 'No',
  });

  const partnerTableRows = useMemo(() => {
    return contributionSummary?.individual_partners?.data?.map(formatPartnerData) || [];
  }, [contributionSummary]);

  const bodyCorporateTableRows = useMemo(() => {
    return contributionSummary?.body_corporate_partners?.data?.map(formatPartnerData) || [];
  }, [contributionSummary]);

  if (isLoading) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );
  }

  if (isReportFetching) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage
          message={'Kindly wait a few minutes, we are fetching the shareholdings data for you.'}
        />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage message='Error loading contribution summary' />;
  }

  if (!contributionSummary) {
    return <ErrorMessage message='No contribution summary available' />;
  }

  const summaryData = contributionSummary?.summary_designated_partners?.data?.[0];
  const summaryCorpData = contributionSummary?.summary_designated_partners?.data?.[1];

  return (
    <div className='py-4 flex flex-col gap-4'>
      <h3 className='text-md font-semibold text-heading'>
        LLP Contribution Summary{' '}
        {contributionSummary?.summary_designated_partners?.metadata?.financial_year &&
          `(as on ${formatDate(
            contributionSummary?.summary_designated_partners?.metadata?.financial_year || '',
          )})`}
      </h3>
      <InfoCard className='flex w-full justify-between'>
        <section className='flex flex-col gap-4 p-6 w-full'>
          <div className='flex flex-col gap-6'>
            <div className='grid grid-cols-4 gap-4'>
              <div>
                <span className='text-sm font-medium text-slate-600'>Category</span>
                <h1 className='text-slate-900 font-bold text-md'>
                  {convertToTitleCase(summaryData?.category) || '-'}
                </h1>
              </div>
              <div>
                <span className='text-sm font-medium text-slate-600'>
                  Total Individual Partners
                </span>
                <h1 className='text-slate-900 font-bold text-md'>
                  {summaryData?.number_of_partners ?? 0}
                </h1>
              </div>
              <div>
                <span className='text-sm font-medium text-slate-600'>
                  Indian Designated Partners
                </span>
                <h1 className='text-slate-900 font-bold text-md'>
                  {summaryData?.indian_designated_partners ?? 0}
                </h1>
              </div>
              <div>
                <span className='text-sm font-medium text-slate-600'>
                  Other Designated Partners
                </span>
                <h1 className='text-slate-900 font-bold text-md'>
                  {summaryData?.other_designated_partners ?? 0}
                </h1>
              </div>
            </div>
            <div className='grid grid-cols-4 gap-4'>
              <div>
                <span className='text-sm font-medium text-slate-600'>Category</span>
                <h1 className='text-slate-900 font-bold text-md'>
                  {convertToTitleCase(summaryCorpData?.category) || '-'}
                </h1>
              </div>
              <div>
                <span className='text-sm font-medium text-slate-600'>
                  Total Body Corporate Partners
                </span>
                <h1 className='text-slate-900 font-bold text-md'>
                  {summaryCorpData?.number_of_partners ?? 0}
                </h1>
              </div>
              <div>
                <span className='text-sm font-medium text-slate-600'>
                  Indian Designated Partners
                </span>
                <h1 className='text-slate-900 font-bold text-md'>
                  {summaryCorpData?.indian_designated_partners ?? 0}
                </h1>
              </div>
              <div>
                <span className='text-sm font-medium text-slate-600'>
                  Other Designated Partners
                </span>
                <h1 className='text-slate-900 font-bold text-md'>
                  {summaryCorpData?.other_designated_partners ?? 0}
                </h1>
              </div>
            </div>
          </div>
        </section>
      </InfoCard>

      <InfoCard className='w-full'>
        <div className='flex items-center justify-start my-4'>
          <h4 className='text-md font-semibold text-heading'>Individual Partners</h4>
          <DocumentIconButton
            metadata={contributionSummary.individual_partners.metadata}
            documentData={contributionSummary.individual_partners}
          />
        </div>
        <Table
          tableDivClassname='w-full border border-gray-200 !p-0 rounded'
          headers={partnerTableHeaders}
          rows={partnerTableRows.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)}
          pagination={{
            currentPage,
            maxLimit: partnerTableRows.length,
            onPageChange: setCurrentPage,
          }}
        />
      </InfoCard>
      {bodyCorporateTableRows.length > 0 && (
        <InfoCard className='w-full'>
          <div className='flex items-center justify-start my-4'>
            <h4 className='text-md font-semibold text-heading'>Body Corporate Partners</h4>
            <DocumentIconButton
              metadata={contributionSummary.body_corporate_partners.metadata}
              documentData={contributionSummary.body_corporate_partners}
            />
          </div>
          <Table
            tableDivClassname='w-full border border-gray-200 !p-0 rounded'
            headers={bodyCorporateTableHeaders}
            rows={bodyCorporateTableRows.slice(
              (currentBodyCorpPage - 1) * rowsPerPage,
              currentBodyCorpPage * rowsPerPage,
            )}
            pagination={{
              currentPage: currentBodyCorpPage,
              maxLimit: bodyCorporateTableRows.length,
              onPageChange: setCurrentBodyCorpPage,
            }}
          />
        </InfoCard>
      )}
    </div>
  );
};

export default LLPShareholdingPattern;
