import React, { useState } from 'react';
import pdfIcon from '@app/assets/pdfIcon.svg';
import { DocumentModal } from '@components/lib-components/modal/DocumentModal';
import {
  ShareholdingTypes,
  ContributionResponse,
  IndividualPartner,
  BodyCorporatePartner,
  SummaryDesignatedPartners,
} from '@components/company/company-tabs/ShareHoldingPattern/ShareholdingProps.types';
import { Metadata } from '@app/types/financial';
import { OutstandingDuesItem, PrincipleActivitiesItem } from '@app/types/compliances';
import { Tooltip } from '@app/components/Tooltip/Tooltip';

interface DocumentIconButtonProps {
  metadata: Metadata | null;
  documentData:
    | ShareholdingTypes
    | OutstandingDuesItem
    | PrincipleActivitiesItem
    | ContributionResponse<IndividualPartner[]>
    | ContributionResponse<BodyCorporatePartner[]>
    | ContributionResponse<SummaryDesignatedPartners[]>
    | null;
  height?: number;
  width?: number;
  showName?: boolean;
}

export const DocumentIconButton: React.FC<DocumentIconButtonProps> = ({
  metadata,
  documentData,
  height = 24,
  width = 24,
  showName = false,
}) => {
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);

  const handleDocumentClick = () => {
    setShowDocumentsModal(true);
  };

  const handleCloseModal = () => {
    setShowDocumentsModal(false);
  };

  if (!metadata) return null;

  return (
    <>
      <div className='flex flex-col items-center' onClick={handleDocumentClick}>
        <Tooltip className='cursor-pointer' content={metadata.file_name}>
          <img
            className='pl-0 ml-3 cursor-pointer'
            src={pdfIcon}
            width={width}
            height={height}
            alt='Document'
          />
          {showName && (
            <div className='mt-2 text-sm text-start pl-6 text-tertiary font-semibold cursor-pointer flex'>
              {metadata.file_name}
            </div>
          )}
        </Tooltip>
      </div>
      <DocumentModal
        isOpen={showDocumentsModal}
        onClose={handleCloseModal}
        documentData={documentData}
      />
    </>
  );
};
