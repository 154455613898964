import { useState, useMemo, useCallback } from 'react';
import { ChargeOverviewModal } from './ChargeOverviewModal';
import TableComponent from '@components/lib-components/table/table/TableComponent';
import {
  ApiResponse,
  ChargesResponse,
  ChargeDetailsModalProps,
  TableRow,
} from '@app/types/charges';
import { useGetCompanyData } from '@app/store/api/companyApi';
import { useParams } from 'react-router-dom';
import Loading from '@components/lib-components/loading/Loading';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { OpenCharges } from './OpenCharges';
import { TabsCommonPropType } from '@components/company/companyCard.types';
import { transformData } from './utils';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';
import { Dropdown } from '@app/components/lib-components/drop-down/Dropdown';
import { SingleValue } from 'react-select';
import { BaseOptionType } from '@app/components/lib-components/drop-down/Dropdown';

const headers = ['Charge ID', 'Date', 'Holder Name', 'Status', 'Amount', 'Total Holders', ''];

type FigureFormat = 'thousands' | 'lacs' | 'millions' | 'crores';

const formatOptions = [
  { value: 'thousands', label: 'in Thousands' },
  { value: 'lacs', label: 'in Lacs' },
  { value: 'millions', label: 'in Millions' },
  { value: 'crores', label: 'in Crores' },
] as const;

const Charges: React.FC<TabsCommonPropType> = () => {
  const { id } = useParams<string>();
  const identifierType = useSelector((state: RootState) => state.util.identifierType);

  // Separate pagination states for each table
  const [openChargesSkip, setOpenChargesSkip] = useState(0);
  const [allChargesSkip, setAllChargesSkip] = useState(0);
  const limit = 10;

  const [showChargesModal, setShowChargesModal] = useState<boolean>(false);
  const getOpenChargesData = useGetCompanyData<ApiResponse<ChargesResponse>>();
  const getAllChargesData = useGetCompanyData<ApiResponse<ChargesResponse>>();

  const openChargesQuery = getOpenChargesData({
    cinId: id as string,
    identifierType: identifierType,
    field_name: 'open_charges',
    skip: openChargesSkip,
    limit: limit,
  });

  const allChargesQuery = getAllChargesData({
    cinId: id as string,
    identifierType: identifierType,
    field_name: 'charge_sequence',
    skip: allChargesSkip,
    limit: limit,
  });

  // Destructure response data
  const openCharges = openChargesQuery?.data?.response_data?.open_charges;
  const openChargesTotal = openChargesQuery?.data?.response_data?.total || 0;
  const allChargesTotal = allChargesQuery?.data?.response_data?.total || 0;
  const chargeSequence = allChargesQuery?.data?.response_data?.charge_sequence;
  const reportStatus = allChargesQuery?.data?.response_data?.status;

  const formatAmount = (
    value: number | string | null | undefined,
    format: FigureFormat = 'thousands',
  ): string => {
    if (value === null || value === undefined) return '-';

    // Convert string to number if needed
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    if (isNaN(numValue) || numValue === 0) return '0.00';

    const formatValue = (val: number, format: FigureFormat): string => {
      switch (format) {
        case 'millions':
          return `₹ ${(val / 1000000).toFixed(2)} M`;
        case 'crores':
          return `₹ ${(val / 10000000).toFixed(2)} Cr`;
        case 'lacs':
          return `₹ ${(val / 100000).toFixed(2)} L`;
        case 'thousands':
        default:
          return `₹ ${(val / 1000).toFixed(2)} K`;
      }
    };

    return formatValue(numValue, format);
  };

  // Separate handlers for each table
  const handleOpenChargesPrevious = useCallback(() => {
    setOpenChargesSkip((prev) => Math.max(0, prev - limit));
  }, [limit]);

  const handleOpenChargesNext = useCallback(() => {
    setOpenChargesSkip((prev) => prev + limit);
  }, [limit]);

  const handleAllChargesPrevious = useCallback(() => {
    setAllChargesSkip((prev) => Math.max(0, prev - limit));
  }, [limit]);

  const handleAllChargesNext = useCallback(() => {
    setAllChargesSkip((prev) => prev + limit);
  }, [limit]);

  const [selectedChargeDetails, setSelectedChargeDetails] = useState<ChargeDetailsModalProps>();
  const [selectedFigureFormat, setSelectedFigureFormat] = useState<FigureFormat>('crores');

  const isReportFetching =
    reportStatus === ReportStatusEnum.INITIALISED ||
    reportStatus === ReportStatusEnum.IN_PROGRESS ||
    openChargesQuery.data?.response_data?.status === ReportStatusEnum.INITIALISED ||
    openChargesQuery.data?.response_data?.status === ReportStatusEnum.IN_PROGRESS;

  // Transform current page data
  const { currentPageData, chargeDetailsMap, hasPartialData } = useMemo(() => {
    if (chargeSequence) {
      const transformed = transformData(chargeSequence);
      const formattedData = transformed.tableData.map((charge) => ({
        ...charge,
        amount: formatAmount(charge.amount, selectedFigureFormat),
        subRows: charge.subRows?.map((subRow) => ({
          ...subRow,
          amount: formatAmount(subRow.amount, selectedFigureFormat),
        })),
      }));
      return {
        currentPageData: formattedData,
        chargeDetailsMap: transformed.chargeDetailsMap,
        hasPartialData: transformed.hasPartialData,
      };
    }
    return { currentPageData: [], chargeDetailsMap: {}, hasPartialData: false };
  }, [chargeSequence, selectedFigureFormat, allChargesSkip]);

  const handleViewClick = useCallback(
    (row: TableRow) => {
      if (row?.id) {
        const chargeDetails = chargeDetailsMap[row.id];
        setSelectedChargeDetails(chargeDetails);
        setShowChargesModal(true);
      }
    },
    [chargeDetailsMap],
  );

  const handleCloseModal = useCallback(() => {
    setShowChargesModal(false);
    setSelectedChargeDetails(undefined);
  }, []);

  const handleFigureFormatChange = (selectedOption: SingleValue<BaseOptionType>) => {
    const option = selectedOption as SingleValue<(typeof formatOptions)[number]>;
    if (option) {
      setSelectedFigureFormat(option.value);
    }
  };

  if (isReportFetching) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage
          message={'Kindly wait a few minutes, we are fetching the company charges data for you.'}
        />
      </div>
    );
  }
  if (allChargesQuery.isLoading)
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );

  if (allChargesQuery.isError)
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <ErrorMessage message='Error fetching open charges details, please try again later.' />
      </div>
    );

  return (
    <div className='w-full'>
      <div className='flex justify-between mt-4'>
        {openCharges && openCharges.length > 0 && (
          <h3 className='text-xl text-heading font-semibold my-auto'>Open Charges Summary</h3>
        )}
        {((openCharges && openCharges.length > 0) || currentPageData?.length > 0) && (
          <div className='flex justify-end mb-4'>
            <Dropdown
              options={formatOptions}
              value={formatOptions.find((option) => option.value === selectedFigureFormat)}
              onChange={(value) => {
                handleFigureFormatChange(value as SingleValue<BaseOptionType>);
              }}
              label='Show amount'
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: 'auto',
                  height: 'auto',
                  width: '12rem',
                }),
                valueContainer: (base) => ({
                  ...base,
                  flexWrap: 'nowrap',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  padding: '2px 5px 2px 4px',
                  maxWidth: '100%',
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                }),
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: '#374151',
                  width: '100%',
                }),
              }}
            />
          </div>
        )}
      </div>
      <OpenCharges
        data={openCharges}
        maxLimit={openChargesTotal}
        currentLimit={limit}
        skip={openChargesSkip}
        handleNext={handleOpenChargesNext}
        handlePrevious={handleOpenChargesPrevious}
        isLoading={openChargesQuery.isLoading}
        isError={openChargesQuery.isError}
        selectedFigureFormat={selectedFigureFormat}
      />
      {currentPageData?.length > 0 ? (
        <div className='my-8'>
          <div className='flex flex-row gap-4 justify-between'>
            <h3 className='text-xl text-heading font-semibold mb-4'>
              All detailed Charges{' '}
              <span className='text-sm text-gray-500'> (including Open/Closed)</span>
            </h3>
          </div>
          <TableComponent
            headers={headers}
            rows={currentPageData}
            onViewClick={handleViewClick}
            badgeStatus={true}
            maxLimit={allChargesTotal}
            currentLimit={limit}
            skip={allChargesSkip}
            handleNext={handleAllChargesNext}
            handlePrevious={handleAllChargesPrevious}
            isLoading={allChargesQuery.isLoading}
          />
          <ChargeOverviewModal
            isOpen={showChargesModal}
            chargeDetails={selectedChargeDetails}
            onClose={handleCloseModal}
            documents={selectedChargeDetails?.metadata}
            figureFormat={selectedFigureFormat}
          />
        </div>
      ) : (
        <ErrorMessage
          message={
            hasPartialData
              ? 'Charges data is not available for the company.'
              : 'All Charges data is not available for the company.'
          }
        />
      )}
    </div>
  );
};

export default Charges;
