import React from 'react';
import InfoCard from '@app/components/lib-components/info-card/InfoCard';
import Icon from '@app/components/lib-components/icon/Icon';
import { formatDate, capitalCalculation } from '@app/components/utils/commonUtils';
import { useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { ReportStatusEnum } from '@app/store/api/tokenLedger/types';
import { Company } from '@app/types/overview';
import { PreviousAddress } from '@app/components/lib-components/previous-address/PreviousAddress';
import { ContactDetailsTooltip } from './ContactDetailsTooltip';
import { Tooltip } from '@app/components/Tooltip/Tooltip';
import { CompanyOverviewProps } from '@components/company/companyCard.types';

export const PvtCompanyOverview: React.FC<CompanyOverviewProps> = ({
  companyOverview,
  contactDetails,
}) => {
  const reportStatus = useSelector((state: RootState) => state.util.reportStatus);

  // Destructure contact details
  const { email: contactEmails = [], phone_number: contactPhones = [] } = contactDetails || {};
  const [firstEmail] = contactEmails;
  const [firstPhone] = contactPhones;
  const firstEmailDoc = firstEmail?.documents?.[0];
  const firstPhoneDoc = firstPhone?.documents?.[0];

  // Function to determine the grid column class based on the available company overview data
  const getGridColumnClass = (companyOverview: Company) => {
    const hasClassification = Boolean(companyOverview?.classification);
    const hasStatus = Boolean(companyOverview?.status);
    const hasDirectors = Boolean(companyOverview?.number_of_directors);

    // Count the number of available items
    const totalItems = [hasClassification, hasStatus, hasDirectors].filter(Boolean).length;

    // Return the appropriate class based on the number of items
    switch (totalItems) {
      case 1:
        return 'col-span-2';
      case 2:
        return 'col-span-2 grid grid-cols-2 gap-4';
      case 3:
        return 'col-span-3 grid grid-cols-3 gap-4';
      default:
        return 'hidden';
    }
  };

  return (
    <div className='w-full h-[calc(60vh)] grid grid-cols-6 gap-4 mt-4'>
      <div className='col-span-4 grid grid-cols-2 gap-4 w-full'>
        <div className={getGridColumnClass(companyOverview)}>
          {Boolean(companyOverview?.classification) && (
            <InfoCard className='flex flex-col font-medium justify-around h-full'>
              <div className='p-2 shadow-sm rounded-md border !border-gray-200 w-fit'>
                <Icon icon='ArrowTrendingUpIcon' />
              </div>
              <div>
                <span className='text-sm text-gray-700'>Type of Entity</span>
                <h1 className='text-lg font-bold pb-4'>{companyOverview.classification}</h1>
              </div>
            </InfoCard>
          )}
          {Boolean(companyOverview?.status) && (
            <InfoCard className='flex flex-col font-medium justify-around h-full'>
              <div className='p-2 shadow-sm rounded-md border !border-gray-200 w-fit'>
                <Icon icon='ArrowTrendingUpIcon' />
              </div>
              <div>
                <span className='text-sm text-gray-700'>Status</span>
                <h1 className='text-lg font-bold pb-4'>{companyOverview.status}</h1>
              </div>
            </InfoCard>
          )}
          {Boolean(companyOverview?.number_of_directors) && (
            <InfoCard className='flex flex-col font-medium justify-around h-full'>
              <div className='p-2 shadow-sm rounded-md border !border-gray-200 w-fit'>
                <Icon icon='ArrowTrendingUpIcon' />
              </div>
              <div>
                <span className='text-sm text-gray-700'>Total Directors</span>
                <h1 className='text-lg font-bold pb-4'>{companyOverview.number_of_directors}</h1>
              </div>
            </InfoCard>
          )}
        </div>
        <div className='grid col-span-3 gap-4 grid-cols-3'>
          <InfoCard className='flex font-medium justify-around h-full flex-col'>
            <div className='p-2 shadow-sm rounded-md border !border-gray-200 w-fit'>
              <Icon icon='ArrowTrendingUpIcon' />
            </div>
            <div>
              <span className='text-sm text-gray-700'>Authorized Capital</span>
              <h1 className='text-2xl font-bold'>
                {companyOverview.authorized_capital || companyOverview.authorized_capital === 0
                  ? `₹${capitalCalculation(companyOverview.authorized_capital)}`
                  : '-'}
              </h1>
            </div>
          </InfoCard>
          <InfoCard className='flex font-medium justify-around h-full flex-col'>
            <div className='p-2 shadow-sm rounded-md border !border-gray-200 w-fit'>
              <Icon icon='ArrowTrendingUpIcon' />
            </div>
            <div>
              <span className='text-sm text-gray-700'>Sum Of Charges</span>
              <h1 className='text-2xl font-bold'>
                {companyOverview.sum_of_charges || companyOverview.sum_of_charges === 0
                  ? `₹${capitalCalculation(companyOverview.sum_of_charges)}`
                  : '-'}
              </h1>
            </div>
          </InfoCard>
          {
            <InfoCard className='flex font-medium justify-around h-full flex-col'>
              <div className='p-2 shadow-sm rounded-md border !border-gray-200 w-fit'>
                <Icon icon='ArrowTrendingUpIcon' />
              </div>
              <div>
                <span className='text-sm text-gray-700'>Paid up Capital</span>
                <h1 className='text-2xl font-bold'>
                  {companyOverview.paid_up_capital || companyOverview.paid_up_capital === 0
                    ? `₹${capitalCalculation(companyOverview.paid_up_capital)}`
                    : '-'}
                </h1>
              </div>
            </InfoCard>
          }
        </div>
        {/* <InfoCard className='col-span-3 bg-secondary'>
          <p className='text-gray-500 font-bold'>About</p>
          <div>
            {!isCompanyLocked && companyOverview?.website && (
              <p
                onClick={() => openWebsite(companyOverview.website ?? '')}
                className='text-gray-600 pb-4 cursor-pointer'
              >
                {companyOverview.website}
              </p>
            )}
          </div>
        </InfoCard> */}
      </div>
      <InfoCard className='col-span-2 grid grid-cols-1 gap-2 text-gray-600 text-sm font-medium h-full'>
        <div className='flex flex-col gap-4 my-4'>
          {Boolean(companyOverview?.incorporation_date) && (
            <div>
              <span>Date of Incorporation</span>
              <h1 className='font-semibold  text-gray-800'>
                {formatDate(companyOverview.incorporation_date)}
              </h1>
            </div>
          )}
          {Boolean(companyOverview?.business_address?.city) && (
            <div>
              <span>Registrar of Company</span>
              <h1 className='font-semibold  text-gray-800'>
                {companyOverview.business_address.city}
              </h1>
            </div>
          )}
          {Boolean(companyOverview?.registered_address?.full_address) && (
            <div>
              <span>Address</span>
              <h1 className='font-semibold text-gray-800'>
                {companyOverview.registered_address.full_address}
              </h1>
            </div>
          )}
          {Boolean(companyOverview?.last_agm_date) && (
            <div>
              <span>Last AGM</span>
              <h1 className='font-semibold  text-gray-800'>
                {formatDate(companyOverview.last_agm_date)}
              </h1>
            </div>
          )}
          {reportStatus !== ReportStatusEnum.LOCKED && (
            <div className='flex flex-col gap-4'>
              {Boolean(companyOverview?.email) && (
                <div>
                  <span>Email</span>
                  <h1 className='font-semibold text-gray-800'>
                    {companyOverview.email.toLowerCase()}
                  </h1>
                </div>
              )}
              {Boolean(companyOverview?.cirp_status) && (
                <div>
                  <span>CIRP Status</span>
                  <h1 className='font-semibold  text-gray-800'>{companyOverview.cirp_status}</h1>
                </div>
              )}
              {Boolean(companyOverview?.previous_address) && (
                <PreviousAddress addresses={companyOverview.previous_address} />
              )}
              {Boolean(companyOverview?.next_cin) && (
                <div>
                  <span>Next CIN</span>
                  <h1 className='font-semibold  text-gray-800'>{companyOverview.next_cin}</h1>
                </div>
              )}
            </div>
          )}
          {Boolean(reportStatus !== ReportStatusEnum.LOCKED && contactDetails) && (
            <div className='flex flex-col gap-4'>
              {contactEmails.length > 0 && (
                <div>
                  <span>Contact Email</span>
                  <div className='font-semibold text-gray-800'>
                    <p>{firstEmail.email_id}</p>
                    <div className='text-xs text-gray-500 italic'>
                      <div>Source: {firstEmailDoc?.document_name || 'N/A'}</div>
                      <div className='mb-2'>
                        Filed on:{' '}
                        {firstEmailDoc?.filing_date ? formatDate(firstEmailDoc.filing_date) : 'N/A'}
                      </div>
                      {contactEmails.length > 1 && (
                        <Tooltip
                          content={<ContactDetailsTooltip items={contactEmails} type='email' />}
                          place='top-left'
                          className='!bg-white !p-4 !text-gray-700 w-fit min-w-60 max-w-fit shadow-md'
                        >
                          <span className='cursor-pointer text-utility-blue-600 mt-1 w-fit hover:no-underline hover:text-utility-blue-800 rounded-md bg-gray-50 p-1 px-2'>
                            +{contactEmails.length - 1} more
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {Boolean(contactPhones.length > 0) && (
                <div>
                  <span>Contact Phone</span>
                  <div className='font-semibold text-gray-800'>
                    <p>{firstPhone.phone_number}</p>
                    <div className='text-xs text-gray-500 italic'>
                      <div>Source: {firstPhoneDoc?.document_name || 'N/A'}</div>
                      <div className='mb-2'>
                        Filed on:{' '}
                        {firstPhoneDoc?.filing_date ? formatDate(firstPhoneDoc.filing_date) : 'N/A'}
                      </div>
                      {contactPhones.length > 1 && (
                        <Tooltip
                          content={<ContactDetailsTooltip items={contactPhones} type='phone' />}
                          place='top-left'
                          className='!bg-white !p-4 !text-gray-700 w-fit min-w-60 max-w-fit shadow-md'
                        >
                          <span className='cursor-pointer text-utility-blue-600 mt-1 w-fit hover:no-underline hover:text-utility-blue-800 rounded-md bg-gray-50 p-1 px-2'>
                            +{contactPhones.length - 1} more
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </InfoCard>
    </div>
  );
};
