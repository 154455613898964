import React from 'react';
import Icon from '@components/lib-components/icon/Icon';
import { numDifferentiation } from '@components/utils/commonUtils';
import { HighlightItem } from './types';

interface FinancialHighlightItemProps {
  item: HighlightItem;
}

export const FinancialHighlightItem: React.FC<FinancialHighlightItemProps> = ({ item }) => {
  const formatValue = (value: number | string | undefined | null, title: string) => {
    if (value === null || value === undefined) return '-';

    if (title.toLowerCase().includes('ratio')) {
      return typeof value === 'number' ? value.toFixed(2) : value;
    }

    return typeof value === 'number' ? numDifferentiation(value, 'crores') : value;
  };

  return (
    <div className='bg-white p-4 rounded-lg shadow'>
      <h4 className='text-sm text-gray-600 mb-2 h-[40%]'>{item.title}</h4>
      <div className='flex justify-between h-[60%] items-baseline'>
        <span className='text-lg font-semibold'>
          {formatValue(item.value, item.title)}
          {item.unit && <span className='text-sm ml-1'>{item.unit}</span>}
        </span>
        {item.change !== undefined && (
          <div
            className={`flex items-center ${item.change >= 0 ? 'text-green-500' : 'text-red-500'}`}
          >
            <Icon icon={item.change >= 0 ? 'GainIcon' : 'LossIcon'} size={16} className='mr-1' />
            <span className='text-sm'>{Math.abs(item.change).toFixed(1)}%</span>
          </div>
        )}
      </div>
    </div>
  );
};
