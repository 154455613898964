import Modal from '@app/components/lib-components/modal/Modal';
import { InputV2 } from '@app/components/lib-components/input/InputV2';
import { useForm, FormProvider, Controller, SubmitHandler, useWatch } from 'react-hook-form';
import Logo from '@app/assets/logo.svg';
import Button from '@app/components/lib-components/button/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CompanyRequestSuccess } from './CompanyRequestSuccess';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@app/store/store';
import { toggleRequestCompanyModal } from '@app/store/slices/utilSlice';
import {
  Dropdown,
  BaseOptionType,
  OptionTypeWithIcon,
} from '@app/components/lib-components/drop-down';
import {
  useLazySearchCompanyByIdentifierQuery,
  useRequestAdhocTaskMutation,
} from '@app/store/api/AdhocTask';
import { IdentifierTypeEnum } from '@app/types';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Icon from '@components/lib-components/icon/Icon';
import { useFindCompaniesMutation } from '@app/store/api/companyApi';
import requestCompanyApi from '@app/store/api/AdhocTask/requestAdhocTasks';
import { AxiosCustomError } from '@app/store/axiosBase';
import tokenLedgerApi from '@app/store/api/tokenLedger/tokenLedgerApi';
import { usePostHog } from 'posthog-js/react';
import { AdhocRequestType } from '@app/store/api/tokenLedger/types';

const dropdownOptions: OptionTypeWithIcon[] = [
  { value: 'India', label: 'India', icon: 'IndianFlagIcon' },
  { value: 'Hong kong', label: 'Hong Kong', icon: 'HongKongIcon' },
];

const indiaIdentifierOptions: BaseOptionType[] = [
  ...Object.values(IdentifierTypeEnum).map((el) => ({
    label: el,
    value: el,
  })),
];

const hongKongIdentifierOptions: BaseOptionType[] = [
  {
    label: 'CR Number',
    value: 'CR Number',
  },
  {
    label: 'BR Number',
    value: 'BR Number',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

const optionSchema = yup.object({
  label: yup.string().required(),
  value: yup.string().required(),
});

const schema = yup.object({
  country: optionSchema.nullable(),
  identifier: optionSchema.required('Identifier is required'),
  identifierValue: yup.string().required('Identifier value is required'),
  companyName: yup.string().required('Company name is required'),
});

type RequestData = yup.InferType<typeof schema>;

export const RequestCompanyModal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const posthog = usePostHog();

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: dropdownOptions[0],
    },
  });

  const isRequestCompanyModalOpen = useSelector(
    (state: RootState) => state.util.isRequestNewCompanyModalOpen,
  );

  const [
    searchCompanies,
    {
      isSuccess: isCompanyNameSearchSuccess,
      data: searchCompaniesData,
      reset: resetSearchCompanies,
    },
  ] = useFindCompaniesMutation();

  const [
    getCompanyByIdentifeir,
    { data: companyIdentifierData, isError: isCompanyIdentifierError },
  ] = useLazySearchCompanyByIdentifierQuery();

  const [
    requestAdhocTask,
    { isSuccess: isCreateCompanyRequestSuccess, reset: resetCreateCompanyRequest, isLoading },
  ] = useRequestAdhocTaskMutation();

  const [identifierOptions, setIdentifierOptions] =
    useState<BaseOptionType[]>(indiaIdentifierOptions);

  const closeModal = () => {
    form.reset();
    dispatch(toggleRequestCompanyModal(false));
    resetCreateCompanyRequest();
    resetSearchCompanies();
    dispatch(requestCompanyApi.util.resetApiState());
  };

  const identifierValue = useWatch({
    control: form.control,
    name: 'identifierValue',
  });

  const identifierType = useWatch({
    control: form.control,
    name: 'identifier',
  });

  const companyName = useWatch({
    control: form.control,
    name: 'companyName',
  });

  const findCompanyById = async () => {
    if (identifierType && identifierValue) {
      resetSearchCompanies();
      toast.promise(
        getCompanyByIdentifeir({
          identifier_type: identifierType.value as IdentifierTypeEnum | 'OTHER',
          identifier_value: identifierValue,
        }).unwrap(),
        {
          loading: 'Searching for companies',
          success: 'Successfully found companies',
          error: () => {
            return 'Could not find any matching companies';
          },
        },
      );
    }
  };

  const findCompanyByName = async () => {
    if (companyName) {
      dispatch(requestCompanyApi.util.resetApiState());
      toast.promise(
        searchCompanies({
          name: companyName,
        }).unwrap(),
        {
          loading: 'Searching for companies',
          success: (res) => {
            if (res.response_data.length === 0) throw new Error();
            return 'Successfully found companies';
          },
          error: () => {
            dispatch(requestCompanyApi.util.resetApiState());
            return 'Could not find any matching companies';
          },
        },
      );
    }
  };

  const onSubmit: SubmitHandler<RequestData> = async (data) => {
    try {
      posthog.capture('request_company', {
        identifier_type: data.identifier.value as IdentifierTypeEnum | 'OTHER',
        identifier_value: data.identifierValue,
        company_name: data.companyName,
      });
      await requestAdhocTask({
        identifier_type: data.identifier.value as IdentifierTypeEnum | 'OTHER',
        identifier_value: data.identifierValue,
        company_name: data.companyName,
        adhoc_request_type: AdhocRequestType.REQUEST_NEW_COMPANY,
      }).unwrap();
      dispatch(tokenLedgerApi.util.invalidateTags(['company-request']));
      setTimeout(closeModal, 3000);
    } catch (error) {
      posthog.capture('error_request_company', {
        error,
      });
      const errorInfo = error as AxiosCustomError;
      if (errorInfo.statusCode === 400) {
        toast.error(errorInfo.data.detail || 'Company already requested');
      } else {
        toast.error('Could not create company request, please contact your admin.');
      }
    }
  };

  return (
    <Modal
      isOpen={isRequestCompanyModalOpen}
      onClose={closeModal}
      className='max-h-[70vh] w-full max-w-2xl'
    >
      <div className='px-8 md:px-12 lg:px-16'>
        {isCreateCompanyRequestSuccess ? (
          <CompanyRequestSuccess />
        ) : (
          <div className='py-8'>
            <div className='mb-8 flex flex-col items-center space-y-4'>
              <img src={Logo} alt='Credit Hive Logo' className='h-16 w-16' />
              <h1 className='text-2xl font-semibold text-utility-gray-400'>Request Company</h1>
            </div>

            <FormProvider {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className='flex flex-col space-y-6'>
                <Controller
                  control={form.control}
                  name='country'
                  render={({ field }) => (
                    <Dropdown
                      placeholder='Choose a country'
                      defaultValue={dropdownOptions[0]}
                      options={dropdownOptions}
                      isDisabled
                      value={dropdownOptions[0]}
                      className='w-full text-sm font-normal'
                      onChange={(data) => {
                        form.resetField('identifier');
                        form.resetField('identifierValue');
                        if (data && 'icon' in data) {
                          const selectedValue = data.icon as string;
                          selectedValue.toLowerCase() === 'hongkongicon'
                            ? setIdentifierOptions(hongKongIdentifierOptions)
                            : setIdentifierOptions(indiaIdentifierOptions);
                        }
                        if (data && 'label' in data && 'value' in data) {
                          field.onChange({
                            label: data.label,
                            value: data.value,
                          });
                        }
                      }}
                    />
                  )}
                />

                <div className='h-px bg-gray-200' />

                <Controller
                  control={form.control}
                  name='identifier'
                  render={({ field }) => (
                    <Dropdown
                      placeholder='Choose the identifier'
                      options={identifierOptions}
                      value={field?.value}
                      className='w-full text-sm font-normal'
                      onChange={(option) => field.onChange(option)}
                      menuPosition='fixed'
                    />
                  )}
                />

                {form.watch('identifier') && (
                  <InputV2
                    name='identifierValue'
                    placeholder={
                      form.watch('identifier.value') !== 'Other'
                        ? form.watch('identifier.value')
                        : 'Enter the identifier value'
                    }
                    suffixIcon='MagnifyingGlassIcon'
                    suffixIconStyle='text-gray-500'
                    suffixText='search'
                    suffixIconHandler={findCompanyById}
                    suffixIconSize={16}
                  />
                )}

                {companyIdentifierData && companyIdentifierData.response_data.length > 0 && (
                  <div>
                    <span className='text-sm text-utility-gray-400'>
                      Is this the company you want?
                    </span>
                    <div
                      className={classNames('transform transition-all duration-300 ease-in-out', {
                        'translate-y-0 opacity-100': companyIdentifierData,
                        'translate-y-4 opacity-0': !companyIdentifierData,
                      })}
                    >
                      {!isCompanyNameSearchSuccess && (
                        <div className='rounded-lg border border-gray-200 shadow-sm'>
                          {companyIdentifierData.response_data.map((el) => (
                            <div
                              key={el.id}
                              onClick={() => {
                                closeModal();
                                navigate(`/company/${el.entity_readable_id}/overview`);
                              }}
                              className='flex cursor-pointer items-center justify-between px-4 py-3 hover:bg-gray-50 first:rounded-t-lg last:rounded-b-lg'
                            >
                              <div className='flex items-center space-x-3'>
                                <Icon icon='SearchFlagIcon' />
                                <span className='text-sm'>{el.name}</span>
                              </div>
                              <span className='text-sm text-gray-500'>{el.entity_readable_id}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {isCompanyIdentifierError && (
                  <span className='text-sm text-utility-gray-400'>
                    If you cannot find the company, please search by company name
                  </span>
                )}

                <InputV2
                  type='text'
                  id='companyName'
                  placeholder='Company Name'
                  name='companyName'
                  suffixIcon='MagnifyingGlassIcon'
                  suffixIconStyle='text-gray-500'
                  suffixText='search'
                  suffixIconHandler={findCompanyByName}
                  suffixIconSize={16}
                />

                {searchCompaniesData && (
                  <div>
                    {searchCompaniesData.response_data.length > 0 ? (
                      <div>
                        <span className='text-sm text-utility-gray-400'>
                          Is this the company you want?
                        </span>

                        <div className='rounded-lg border border-gray-200 shadow-sm'>
                          {searchCompaniesData.response_data.map((el) => (
                            <div
                              key={el.id}
                              onClick={() => {
                                closeModal();
                                navigate(`/company/${el.entity_readable_id}/overview`);
                              }}
                              className='flex cursor-pointer items-center justify-between px-4 py-3 hover:bg-gray-50 first:rounded-t-lg last:rounded-b-lg'
                            >
                              <div className='flex items-center space-x-3'>
                                <Icon icon='SearchFlagIcon' />
                                <span className='text-sm'>{el.name}</span>
                              </div>
                              <span className='text-sm text-gray-500'>{el.entity_readable_id}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <span className='text-sm text-utility-gray-400'>
                        If still couldn&apos;t find, please click &apos;Submit Details&apos;
                      </span>
                    )}
                  </div>
                )}

                <Button
                  variant='base'
                  className='w-full py-2.5'
                  type='submit'
                  isLoading={isLoading}
                >
                  Submit Details
                </Button>
              </form>
            </FormProvider>
          </div>
        )}
      </div>
    </Modal>
  );
};
