import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '@components/lib-components/loading/Loading';
import { FinancialRatiosResponse } from '@app/types/financial';
import { useGetCompanyData } from '@app/store/api/companyApi';
import Button from '@components/lib-components/button/Button';
import classNames from 'classnames';
import ExpandableTable from '../../../lib-components/table/table/ExpandableTable';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import { ApiResponse } from '@app/types';
import Icon from '@components/lib-components/icon/Icon';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';
import { Dropdown, BaseOptionType } from '@app/components/lib-components/drop-down/Dropdown';
import { MultiValue } from 'react-select';

export type NatureType = 'STANDALONE' | 'CONSOLIDATED';

const FinancialAnalysisTab = () => {
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const { id } = useParams<{ id: string }>();
  const [selectedNatureType, setSelectedNatureType] = useState<NatureType>('STANDALONE');
  const [selectedYears, setSelectedYears] = useState<BaseOptionType[]>([]);

  const useFinancialData = (fieldName: string) => {
    const { data, isLoading, isError } = useGetCompanyData<ApiResponse<FinancialRatiosResponse>>()({
      cinId: id as string,
      field_name: fieldName,
      identifierType: identifierType,
    });
    return { data, isLoading, isError };
  };

  const {
    data: standaloneData,
    isLoading: isStandaloneLoading,
    isError: standaloneError,
  } = useFinancialData('financials_standalone_analysis');
  const {
    data: consolidatedData,
    isLoading: isConsolidatedLoading,
    isError: consolidatedError,
  } = useFinancialData('financials_consolidated_analysis');

  const isInProgress = (data?: { response_data?: { status?: string } }) =>
    ['INITIALISED', 'IN_PROGRESS'].includes(data?.response_data?.status || '');

  const isReportFetching = isInProgress(standaloneData) || isInProgress(consolidatedData);

  const hasConsolidatedData = useMemo(
    () =>
      Object.values(consolidatedData?.response_data?.financials_consolidated_analysis || {}).some(
        (value) => value !== null,
      ),
    [consolidatedData],
  );

  const hasStandaloneData = useMemo(
    () =>
      Object.values(standaloneData?.response_data?.financials_standalone_analysis || {}).some(
        (value) => value !== null,
      ),
    [standaloneData],
  );

  const yearOptions = useMemo(() => {
    const dataSource = selectedNatureType === 'STANDALONE' ? standaloneData : consolidatedData;
    const analysisData =
      selectedNatureType === 'STANDALONE'
        ? dataSource?.response_data?.financials_standalone_analysis
        : dataSource?.response_data?.financials_consolidated_analysis;

    if (!analysisData) return [];

    return Object.keys(analysisData)
      .filter((year) => analysisData[year] !== null)
      .map((year) => ({
        value: year,
        label: `${year}`,
      }))
      .sort((a, b) => parseInt(b.value) - parseInt(a.value));
  }, [standaloneData, consolidatedData, selectedNatureType]);

  const handleYearSelection = (selected: MultiValue<BaseOptionType>) => {
    setSelectedYears(selected as BaseOptionType[]);
  };

  const filteredFinancialsData = useMemo(() => {
    const dataSource = selectedNatureType === 'STANDALONE' ? standaloneData : consolidatedData;
    const analysisData =
      selectedNatureType === 'STANDALONE'
        ? dataSource?.response_data?.financials_standalone_analysis
        : dataSource?.response_data?.financials_consolidated_analysis;

    if (!analysisData) return {};

    if (selectedYears.length === 0) return analysisData;

    return Object.entries(analysisData).reduce(
      (acc, [year, data]) => {
        if (selectedYears.some((selected) => selected.value === year)) {
          acc[year] = data;
        }
        return acc;
      },
      {} as typeof analysisData,
    );
  }, [standaloneData, consolidatedData, selectedNatureType, selectedYears]);

  const buttonClasses = (isActive: boolean) =>
    classNames(
      'whitespace-nowrap font-bold py-3 px-4 border-2 text-gray-700 transition-all duration-200',
      {
        'bg-blue-500 text-white border-blue-500 hover:bg-blue-600': isActive,
        'bg-gray-100 border-gray-300 hover:bg-gray-200': !isActive,
      },
    );

  const handleNatureTypeChange = (type: NatureType) => {
    setSelectedNatureType(type);
    setSelectedYears([]);
  };

  if (isReportFetching) {
    return (
      <div className='w-full ml-2 h-[calc(60vh)] flex justify-center items-center whitespace-nowrap'>
        <Loading message='Kindly wait a few minutes, we are fetching the company financial analysis data for you.' />
      </div>
    );
  }

  if (isStandaloneLoading || isConsolidatedLoading) {
    return (
      <div className='w-full'>
        <Loading />
      </div>
    );
  }

  if (standaloneError && consolidatedError) {
    return <ErrorMessage message='Error fetching financial data. Please try again later.' />;
  }

  if (selectedNatureType === 'STANDALONE' && standaloneError) {
    return <ErrorMessage message='Standalone financial data is not available for this company.' />;
  }
  if (selectedNatureType === 'CONSOLIDATED' && consolidatedError) {
    return (
      <ErrorMessage message='Consolidated financial data is not available for this company.' />
    );
  }

  return (
    <div className='w-full py-4'>
      <h2 className='text-xl text-heading font-semibold my-3'>Financial Analysis</h2>
      <div className='flex justify-between items-start'>
        <div className='my-2 flex flex-col rounded-md shadow-sm h-[70%]' role='group'>
          <div className='flex flex-row'>
            {['STANDALONE', 'CONSOLIDATED'].map((type) => (
              <Button
                key={type}
                text={type.charAt(0) + type.slice(1).toLowerCase()}
                variant='text'
                className={classNames(
                  buttonClasses(selectedNatureType === type),
                  type === 'STANDALONE' ? 'rounded-l-lg' : 'rounded-r-lg border-l-0',
                  selectedNatureType === type ? 'hover:text-white' : '',
                )}
                onClick={() => handleNatureTypeChange(type as NatureType)}
                disabled={
                  (type === 'STANDALONE' && !hasStandaloneData) ||
                  (type === 'CONSOLIDATED' && !hasConsolidatedData)
                }
              />
            ))}
          </div>
          {(!hasStandaloneData || !hasConsolidatedData) && (
            <div className='flex text-sm text-gray-600 mb-4 justify-start mt-2'>
              <Icon icon='InformationCircleIcon' size={16} className='mr-2 text-blue-500' />
              <span>
                {!hasStandaloneData &&
                  !hasConsolidatedData &&
                  'Standalone and consolidated data not available for this company.'}
                {hasStandaloneData &&
                  !hasConsolidatedData &&
                  'Consolidated data not available for this company.'}
                {!hasStandaloneData &&
                  hasConsolidatedData &&
                  'Standalone data not available for this company.'}
              </span>
            </div>
          )}
        </div>

        <div className='flex justify-end mb-1'>
          <Dropdown
            isMulti={true}
            options={yearOptions}
            value={selectedYears}
            placeholder='Select years to compare'
            onChange={(value) => {
              handleYearSelection(value as MultiValue<BaseOptionType>);
            }}
            label='Compare Years'
            styles={{
              control: (base) => ({
                ...base,
                minHeight: 'auto',
                height: 'auto',
                minWidth: '18rem',
              }),
              multiValue: (base) => ({
                ...base,
                flexShrink: 0,
              }),
              menu: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
              }),
              valueContainer: (base) => ({
                ...base,
                flexWrap: 'nowrap',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                padding: '2px 30px 2px 10px',
                maxWidth: '100%',
                minWidth: '15rem',
              }),
            }}
          />
        </div>
      </div>

      {standaloneData || consolidatedData ? (
        <div className='w-full'>
          <ExpandableTable data={filteredFinancialsData} tableType='financial' />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default FinancialAnalysisTab;
