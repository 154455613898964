import Accordion from '@app/components/lib-components/accordian/Accordian';
import ErrorMessage from '@components/lib-components/ErrorMessage/ErrorMessage';
import Loading from '@components/lib-components/loading/Loading';
import { TabsCommonPropType } from '../../companyCard.types';
import { useParams } from 'react-router-dom';
import { useGetCompanyData } from '@app/store/api/companyApi';
import {
  AdhocRequestType,
  DocumentCategory,
  DocumentsData,
} from '@app/store/api/tokenLedger/types';
import { useGetDocumentRedirectionLinkMutation } from '@app/store/api/companyApi';
import { ApiResponse, PresignedUrlPayLoad } from '@app/types';
import { convertToTitleCase, removeUnderscores } from '@components/utils/commonUtils';
import { RootState } from '@app/store/store';
import { useSelector } from 'react-redux';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { Attachments, Metadata } from '@app/types/financial';
import { toast } from 'sonner';
import { DOCUMENT_CATEGORY_MAP } from '@app/store/api/tokenLedger/types';
import { useCallback, useState, useEffect, useMemo } from 'react';
import Button from '@components/lib-components/button/Button';
import posthog from 'posthog-js';
import classNames from 'classnames';
import Table from '@app/components/lib-components/table/table/Table';
import LatestDocumentsSection from './LatestDocumentsSection';
import { useRequestAdhocTaskMutation } from '@app/store/api/AdhocTask/requestAdhocTasks';
import { AdhocTaskPayload } from '@app/store/api/AdhocTask/types';
import { DocumentPreviewModal } from '@components/company/company-tabs/Compliances/DocumentPreviewModal';

interface RowData {
  checkbox?: string;
  file_name?: string;
  document_type?: string;
  document_name?: JSX.Element | string;
  attachments?: string | JSX.Element;
  s3_url?: string;
  id?: string;
}

interface PaginatedData {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
}

const ITEMS_PER_PAGE = 10;
const PRIMARY_DOC_LIMIT = 5;
const OTHER_DOC_LIMIT = 10;

const categorizeDocuments = (
  documents: DocumentsData,
): Record<'primary' | 'other', Record<DocumentCategory, Metadata[]>> => {
  const categorized: Record<'primary' | 'other', Record<DocumentCategory, Metadata[]>> = {
    primary: {} as Record<DocumentCategory, Metadata[]>,
    other: {} as Record<DocumentCategory, Metadata[]>,
  };

  // Initialize empty arrays for all categories in both primary and other
  Object.values(DocumentCategory).forEach((category) => {
    categorized.primary[category] = [];
    categorized.other[category] = [];
  });

  // Categorize documents
  Object.entries(documents.document_list || {}).forEach(([category, docs]) => {
    docs.forEach((doc) => {
      if (doc.is_primary) {
        if (!categorized.primary[category as DocumentCategory]) {
          categorized.primary[category as DocumentCategory] = [];
        }
        categorized.primary[category as DocumentCategory].push(doc);
      } else {
        if (!categorized.other[category as DocumentCategory]) {
          categorized.other[category as DocumentCategory] = [];
        }
        categorized.other[category as DocumentCategory].push(doc);
      }
    });
  });

  return categorized;
};

const isOtherDocument = (documentId: string) => {
  return documentId.startsWith('other-');
};

const DocumentsTab: React.FC<TabsCommonPropType> = ({ companyName }) => {
  const { id: cinId } = useParams<{ id: string }>();
  const identifierType = useSelector((state: RootState) => state.util.identifierType);
  const getCompanyOverviewData = useGetCompanyData<ApiResponse<DocumentsData>>();
  const [getDocumentUrl] = useGetDocumentRedirectionLinkMutation();
  const [requestAdhocTask, { isLoading: isRequesting }] = useRequestAdhocTaskMutation();

  const [isDocumentPreviewOpen, setIsDocumentPreviewOpen] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');

  const openPdfInNewTab = useCallback((url: string, flatten: boolean) => {
    const decodedUrl = decodeURIComponent(url);
    if (flatten) {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.location.href = `${window.location.origin}/pdf-viewer#${decodedUrl}`;
      }
    } else {
      setPreviewUrl(decodedUrl);
      setIsDocumentPreviewOpen(true);
    }
  }, []);

  const {
    data: responseData,
    isLoading,
    isError,
  } = getCompanyOverviewData({
    cinId: cinId as string,
    field_name: 'document_list',
    identifierType: identifierType,
  });

  const documentsData = (responseData?.response_data as DocumentsData) || {};

  const headers = [
    { name: '', value: 'checkbox', classes: 'p-4 text-sm text-gray-600 w-[5%]' },
    { name: 'Filing Date', value: 'filing_date', classes: 'p-4 text-sm text-gray-600 w-[15%]' },
    { name: 'Document Type', value: 'document_type', classes: 'p-4 text-sm text-gray-600 w-[20%]' },
    {
      name: 'Document Name',
      value: 'document_name',
      classes: 'p-4 text-sm text-gray-600 w-[40%] max-w-[40%]',
    },
    {
      name: 'Attachments',
      value: 'attachments',
      classes: 'p-4 text-sm text-gray-600 w-[20%]',
    },
  ];

  const handleDocumentClick = useCallback(
    async (item: Metadata, attachment?: Attachments) => {
      try {
        const s3Url = attachment ? attachment.s3_url : item.s3_url;
        if (!s3Url) {
          posthog.capture('document_request_error', {
            document_name: item.file_name,
          });
          toast.error('Please contact us to request this document.');
          return;
        }
        const payload: PresignedUrlPayLoad = {
          s3_url: s3Url,
          is_download: true,
          identifier_type: identifierType,
          identifier_value: cinId as string,
        };

        const response = await getDocumentUrl(payload).unwrap();
        if (response.pre_signed_url) {
          openPdfInNewTab(response.pre_signed_url, response.flatten);
        } else {
          posthog.capture('document_request_error', {
            document_name: item.file_name,
          });
          toast.error('Error fetching document, please contact us to request this document.');
          console.error('No pre-signed URL received');
        }
      } catch (error) {
        posthog.capture('document_request_error', {
          document_name: item.file_name,
        });
        toast.error('Error fetching document, please contact us to request this document.');
        console.error('Error fetching document URL:', error);
      }
    },
    [cinId, getDocumentUrl, identifierType, openPdfInNewTab],
  );

  const filteredDocumentList = Object.keys(documentsData.document_list || {}).filter(
    (key) =>
      Array.isArray(documentsData.document_list[key as DocumentCategory]) &&
      documentsData.document_list[key as DocumentCategory].length > 0,
  );

  const isReportFetching =
    responseData?.response_data?.status === 'INITIALISED' ||
    responseData?.response_data?.status === 'IN_PROGRESS';

  const getDisplayCategory = useCallback((key: string): string => {
    const category = Object.entries(DOCUMENT_CATEGORY_MAP).find(([, value]) => value === key);
    return category ? category[0] : convertToTitleCase(key);
  }, []);

  const categorizedDocuments = useMemo(() => {
    if (!documentsData?.document_list) return null;
    return categorizeDocuments(documentsData);
  }, [documentsData]);

  const categorizedAndFilteredDocuments = useMemo(() => {
    if (!categorizedDocuments) return null;

    return {
      primary: Object.entries(categorizedDocuments.primary).filter(([, docs]) => docs.length > 0),
      other: Object.entries(categorizedDocuments.other).filter(([, docs]) => docs.length > 0),
    };
  }, [categorizedDocuments]);

  const [paginationState, setPaginationState] = useState<Record<string, PaginatedData>>({});

  // Initialize pagination state when documents are loaded
  useEffect(() => {
    if (!documentsData?.document_list) return;

    const newPaginationState: Record<string, PaginatedData> = {};
    ['primary', 'other'].forEach((mainCategory) => {
      Object.keys(documentsData.document_list).forEach((category) => {
        const key = `${mainCategory}-${category}`;
        const docs =
          categorizedDocuments?.[mainCategory as keyof typeof categorizedDocuments]?.[
            category as DocumentCategory
          ] || [];
        newPaginationState[key] = {
          currentPage: 1,
          itemsPerPage: ITEMS_PER_PAGE,
          totalItems: docs.length,
        };
      });
    });
    setPaginationState(newPaginationState);
  }, [documentsData?.document_list, categorizedDocuments]);

  const handlePageChange = useCallback((category: string, page: number) => {
    setPaginationState((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        currentPage: page,
        itemsPerPage: ITEMS_PER_PAGE,
        totalItems: prev[category].totalItems,
      },
    }));
  }, []);

  const [selectedDocuments, setSelectedDocuments] = useState<RowData[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [selectedDocumentUrls, setSelectedDocumentUrls] = useState<Set<string>>(new Set());
  const [selectedDocumentIds, setSelectedDocumentIds] = useState<Set<string>>(new Set());
  const [openAccordions, setOpenAccordions] = useState<string[]>([]);

  const handleSelectRows = useCallback(
    (rows: RowData[], categoryKey: string) => {
      if (isDownloading) return;

      const newSelectedDocs = rows.filter((row) => row.s3_url);
      const otherDocs = selectedDocuments.filter((doc) => !doc.id?.startsWith(categoryKey));

      const hasOtherDocs = otherDocs.some((doc) => isOtherDocument(doc.id as string));
      const isSelectingOther = categoryKey.startsWith('other-');

      if (hasOtherDocs && !isSelectingOther) {
        toast.error('Cannot combine Primary and Other documents');
        return;
      }

      if (!hasOtherDocs && isSelectingOther && otherDocs.length > 0) {
        toast.error('Cannot combine Primary and Other documents');
        return;
      }

      const docLimit = isSelectingOther ? OTHER_DOC_LIMIT : PRIMARY_DOC_LIMIT;
      if (otherDocs.length + newSelectedDocs.length > docLimit) {
        toast.error(`Maximum ${docLimit} documents can be selected`);
        return;
      }

      const updatedDocs = [...otherDocs, ...newSelectedDocs];
      setSelectedDocuments(updatedDocs);
      setSelectedDocumentUrls(new Set(updatedDocs.map((doc) => doc.s3_url as string)));
      setSelectedDocumentIds(new Set(updatedDocs.map((doc) => doc.id as string)));
    },
    [isDownloading, selectedDocuments],
  );

  const handleDownloadSelected = async () => {
    if (!selectedDocuments.length) {
      toast.error('Please select documents to download');
      return;
    }

    if (selectedDocuments.length > 5) {
      toast.error('Maximum 5 documents can be downloaded at once');
      return;
    }

    setIsDownloading(true);
    try {
      for (const row of selectedDocuments) {
        try {
          const s3Url = row.s3_url;

          if (!s3Url) {
            toast.error(`Missing URL for document: ${row.document_name}`);
            continue;
          }

          const payload: PresignedUrlPayLoad = {
            s3_url: s3Url,
            is_download: true,
            identifier_type: identifierType,
            identifier_value: cinId as string,
          };

          const response = await getDocumentUrl(payload).unwrap();

          if (response.pre_signed_url) {
            // Getting the filename from the document name or URL
            let filename: string;
            if (typeof row.document_name === 'string') {
              filename = row.document_name;
            } else if (row.document_name && 'props' in row.document_name) {
              filename = row.document_name.props.content || 'document.pdf';
            } else {
              // Extracting the filename from the URL as fallback
              const decodedUrl = decodeURIComponent(response.pre_signed_url);
              const urlObj = new URL(decodedUrl);
              filename =
                urlObj.pathname.split('/').pop()?.split('?')[0].replace(/%20/g, ' ') ||
                'document.pdf';
            }

            // Ensure filename has proper extension
            if (!filename.toLowerCase().endsWith('.pdf')) {
              filename += '.pdf';
            }

            const fileResponse = await fetch(response.pre_signed_url);
            const blob = await fileResponse.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = decodeURIComponent(filename).replace(/%20/g, ' ');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setTimeout(() => {
              window.URL.revokeObjectURL(blobUrl);
            }, 100);

            toast.success(`Downloaded: ${decodeURIComponent(filename).replace(/%20/g, ' ')}`);
          } else {
            toast.error(`Failed to download document: ${row.document_name}`);
          }
        } catch (error) {
          console.error('Download error:', error);
          toast.error(`Error downloading document: ${row.document_name}`);
        }
      }
    } finally {
      setSelectedDocuments([]);
      setSelectedDocumentUrls(new Set());
      setSelectedDocumentIds(new Set());
      setIsDownloading(false);
    }
  };

  const generateAccordionIndex = (mainIndex: number, subIndex?: number) => {
    return subIndex !== undefined ? `${mainIndex}-${subIndex}` : `${mainIndex}`;
  };

  const toggleChildAccordion = (index: string) => {
    setOpenAccordions((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  useEffect(() => {
    const accordionRefs = document.querySelectorAll('table');
    accordionRefs.forEach((table) => {
      const checkboxes = table.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach((checkbox) => {
        if (checkbox instanceof HTMLInputElement) {
          const row = checkbox.closest('tr');
          if (row) {
            const s3Url = row.getAttribute('data-s3-url');
            if (s3Url) {
              checkbox.checked = selectedDocumentUrls.has(s3Url);
            }
          }
        }
      });
    });
  }, [selectedDocumentUrls]);

  const handleCheckboxChange = useCallback(
    (row: RowData, categoryKey: string) => {
      if (isDownloading) {
        toast.error('Please wait for current downloads to complete');
        return;
      }

      if (!row.id || typeof row.id === 'object') return;

      const isOtherDoc = categoryKey.startsWith('other-');

      // For primary documents, require s3_url
      if (!isOtherDoc && !row.s3_url) {
        toast.error('Error fetching document, please contact us to request this document');
        return;
      }

      // Check if trying to mix primary and other documents
      const hasOtherDocs = selectedDocuments.some((doc) => isOtherDocument(doc.id as string));
      const hasPrimaryDocs = selectedDocuments.some((doc) => !isOtherDocument(doc.id as string));

      if ((isOtherDoc && hasPrimaryDocs) || (!isOtherDoc && hasOtherDocs)) {
        toast.error('Cannot combine Primary and Other documents');
        return;
      }

      const rowId = row.id as string;
      const isSelected = selectedDocumentIds.has(rowId);

      const currentSelectedDocs = selectedDocuments;

      let newSelectedDocs: RowData[];
      if (isSelected) {
        newSelectedDocs = currentSelectedDocs.filter((d) => d.id !== rowId);
      } else {
        const docLimit = isOtherDoc ? OTHER_DOC_LIMIT : PRIMARY_DOC_LIMIT;
        if (currentSelectedDocs.length >= docLimit) {
          toast.error(`Maximum ${docLimit} documents can be selected`);
          return;
        }
        newSelectedDocs = [...currentSelectedDocs, row];
      }

      setSelectedDocuments(newSelectedDocs);
      setSelectedDocumentUrls(new Set(newSelectedDocs.map((d) => d.s3_url as string)));
      setSelectedDocumentIds(new Set(newSelectedDocs.map((d) => d.id as string)));
    },
    [isDownloading, selectedDocuments],
  );

  if (isLoading) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading />
      </div>
    );
  }

  if (isReportFetching) {
    return (
      <div className='w-[calc(100vw-7rem)] ml-2  h-[calc(60vh)] flex justify-center items-center'>
        <Loading
          message={'Kindly wait a few minutes, we are fetching the company documents data for you.'}
        />
      </div>
    );
  }
  return (
    <>
      <div className='space-y-4 w-full py-6'>
        <div className='flex justify-start items-center'>
          <h4 className='text-lg text-heading font-medium'>Documents</h4>
          <div className='pl-2 italic text-sm text-gray-600 font-medium mt-0.5'>
            (You can download up to {PRIMARY_DOC_LIMIT} primary and request up to {OTHER_DOC_LIMIT}{' '}
            other documents)
          </div>
        </div>

        {!isReportFetching && !isLoading && <LatestDocumentsSection cinId={cinId as string} />}

        {!isReportFetching && !isLoading && filteredDocumentList.length > 0 && (
          <div className='sticky top-12 z-10 bg-white shadow-sm'>
            <div className='flex justify-between mb-4 px-4 py-3 shadow-sm rounded-md mt-3'>
              <div className='text-sm text-gray-600'>
                {selectedDocuments.length > 0 && (
                  <div>
                    <div className='font-medium mb-2'>
                      Selected {selectedDocuments.length} document(s)
                      {selectedDocuments.some((doc) => isOtherDocument(doc.id as string))
                        ? selectedDocuments.length >= OTHER_DOC_LIMIT && (
                            <span className='text-red-500 ml-2'>(Maximum limit reached)</span>
                          )
                        : selectedDocuments.length >= PRIMARY_DOC_LIMIT && (
                            <span className='text-red-500 ml-2'>(Maximum limit reached)</span>
                          )}
                    </div>
                    <div className='flex flex-wrap gap-2'>
                      {selectedDocuments.map((doc, index) => {
                        let displayName = 'Unnamed Document';
                        if (typeof doc.document_name === 'string') {
                          displayName = doc.document_name;
                        } else if (doc.document_name && 'props' in doc.document_name) {
                          const tooltipContent = doc.document_name.props.content;
                          if (tooltipContent) {
                            displayName = tooltipContent;
                          }
                        }

                        return (
                          <span
                            key={index}
                            className='inline-flex items-center px-2 py-1 bg-gray-100 rounded-md text-sm'
                          >
                            {displayName}
                            <button
                              disabled={isDownloading}
                              onClick={() => {
                                const docToRemove = doc;
                                const newSelectedDocs = selectedDocuments.filter(
                                  (d) => d.id !== docToRemove.id,
                                );

                                // Update selected documents
                                setSelectedDocuments(newSelectedDocs);

                                // Update URLs and IDs separately
                                const newUrls = new Set(
                                  newSelectedDocs.map((d) => d.s3_url as string),
                                );
                                const newIds = new Set(newSelectedDocs.map((d) => d.id as string));

                                setSelectedDocumentUrls(newUrls);
                                setSelectedDocumentIds(newIds);
                              }}
                              className='ml-2 text-gray-500 hover:text-red-500 mt-0'
                            >
                              ×
                            </button>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {selectedDocuments.length > 0 &&
                (selectedDocuments.some((doc) => isOtherDocument(doc.id as string)) ? (
                  <Button
                    onClick={async () => {
                      try {
                        const documentList = selectedDocuments.map((doc) => {
                          // Use the document name from the selected document
                          if (typeof doc.document_name === 'string') {
                            return doc.document_name;
                          } else if (doc.document_name && 'props' in doc.document_name) {
                            // Handle JSX.Element case where name is in tooltip content
                            return doc.document_name.props.content;
                          }
                          return doc.file_name || 'Unnamed Document';
                        });

                        const payload: AdhocTaskPayload = {
                          company_name: companyName as string,
                          identifier_type: identifierType,
                          identifier_value: cinId as string,
                          adhoc_metadata: {
                            document_list: documentList,
                          },
                          adhoc_request_type: AdhocRequestType.REQUEST_DOCUMENTS,
                        };

                        await requestAdhocTask(payload).unwrap();

                        toast.success('Documents requested successfully');
                      } catch (error) {
                        console.error('Error requesting documents:', error);
                        toast.error('Failed to request documents');
                      } finally {
                        // Clear selection after successful request
                        setSelectedDocuments([]);
                        setSelectedDocumentUrls(new Set());
                        setSelectedDocumentIds(new Set());
                      }
                    }}
                    isLoading={isRequesting}
                    loadingText='Requesting...'
                    className='px-4 py-2 text-sm !font-bold text-white border border-utility-blue-600 whitespace-pre max-h-fit'
                  >
                    Request Documents ({selectedDocuments.length})
                  </Button>
                ) : (
                  <Button
                    onClick={handleDownloadSelected}
                    isLoading={isDownloading}
                    loadingText='Downloading...'
                    className='px-4 py-2 text-sm !font-bold text-white border border-utility-blue-600 whitespace-pre max-h-fit'
                  >
                    Download Selected ({selectedDocuments.length})
                  </Button>
                ))}
            </div>
          </div>
        )}
        {!isReportFetching && !isLoading && documentsData?.document_list ? (
          <>
            {['primary', 'other'].map((mainCategory, mainIndex) => {
              const nonEmptyCategories =
                categorizedAndFilteredDocuments?.[
                  mainCategory as keyof typeof categorizedAndFilteredDocuments
                ] || [];

              if (nonEmptyCategories.length === 0) return null;

              return (
                <Accordion
                  key={`${mainCategory}-${mainIndex}`}
                  title={mainCategory === 'primary' ? 'Primary Documents' : 'Other Documents'}
                  isOpen={openAccordions.includes(generateAccordionIndex(mainIndex))}
                  onToggle={() => toggleChildAccordion(generateAccordionIndex(mainIndex))}
                  count={nonEmptyCategories.reduce((acc, [, docs]) => acc + docs.length, 0)}
                  titleClassName='text-lg !font-bold'
                >
                  <div className='space-y-4'>
                    {nonEmptyCategories.map(([category, documents], categoryIndex) => {
                      const categoryPagination = paginationState[`${mainCategory}-${category}`] || {
                        currentPage: 1,
                        itemsPerPage: ITEMS_PER_PAGE,
                        totalItems: documents.length,
                      };
                      const startIndex = (categoryPagination.currentPage - 1) * ITEMS_PER_PAGE;
                      const endIndex = startIndex + ITEMS_PER_PAGE;
                      const paginatedDocuments = documents.slice(startIndex, endIndex);

                      return (
                        <Accordion
                          key={`${mainCategory}-${category}-${categoryPagination.currentPage}-${categoryIndex}`}
                          title={getDisplayCategory(category)}
                          isOpen={openAccordions.includes(
                            generateAccordionIndex(mainIndex, categoryIndex),
                          )}
                          onToggle={() =>
                            toggleChildAccordion(generateAccordionIndex(mainIndex, categoryIndex))
                          }
                          count={categoryPagination.totalItems}
                        >
                          <div className='flex flex-col'>
                            <Table
                              tableDivClassname='w-full border border-gray-200 !p-0 rounded-lg'
                              headers={headers}
                              rows={paginatedDocuments.map((item: Metadata) => ({
                                id: `${mainCategory}-${category}-${item.file_name}`,
                                checkbox: '-',
                                filing_date: item.filing_date || '-',
                                document_type:
                                  item.document_type &&
                                  item.document_type.toLowerCase() === 'unknown'
                                    ? '-'
                                    : item.document_type,
                                document_name: (
                                  <Tooltip
                                    className='!bg-slate-700 shadow-md rounded-md bg-blend-saturation'
                                    content={item.file_name}
                                  >
                                    <div className='border-2 rounded-md border-gray-200 w-fit'>
                                      <span
                                        onClick={() => handleDocumentClick(item)}
                                        className='bg-transparent block px-1 py-1 text-start text-sm cursor-pointer hover:bg-gray-50 transition-colors duration-150 break-words whitespace-normal'
                                      >
                                        {removeUnderscores(item.file_name)}
                                      </span>
                                    </div>
                                  </Tooltip>
                                ),
                                s3_url: item.s3_url,
                                file_name: item.file_name,
                                attachments: (
                                  <div className='flex flex-col space-y-2'>
                                    {item.attachments && item.attachments.length > 0
                                      ? item.attachments?.map((attachment, i) => (
                                          <Tooltip
                                            className='!bg-slate-700 shadow-md rounded-md bg-blend-saturation'
                                            key={i}
                                            content={attachment.file_name || ''}
                                          >
                                            <span
                                              onClick={() => handleDocumentClick(item, attachment)}
                                              className='bg-transparent border-2 me-1 p-1 rounded-md w-fit cursor-pointer text-xs'
                                            >
                                              {attachment.file_name &&
                                              attachment.file_name.length > 20
                                                ? `${attachment.file_name?.slice(0, 32)}...`
                                                : attachment.file_name}
                                            </span>
                                          </Tooltip>
                                        ))
                                      : '-'}
                                  </div>
                                ),
                                isSelected: selectedDocumentIds.has(
                                  `${mainCategory}-${category}-${item.file_name}`,
                                ),
                              }))}
                              onSelectRows={(rows) =>
                                handleSelectRows(rows as RowData[], `${mainCategory}-${category}`)
                              }
                              totalSelectedCount={selectedDocuments.length}
                              selectedUrls={Array.from(selectedDocumentIds)}
                              isDownloading={isDownloading}
                              maxSelectLimit={
                                mainCategory === 'other' ? OTHER_DOC_LIMIT : PRIMARY_DOC_LIMIT
                              }
                              onCheckboxChange={(row, categoryKey) =>
                                handleCheckboxChange(row as RowData, categoryKey)
                              }
                              categoryKey={`${mainCategory}-${category}`}
                            />
                            <div className='flex justify-between items-center py-3 px-6 text-sm border-t mt-4'>
                              <div>
                                <span>
                                  {categoryPagination.totalItems > 0 ? (
                                    <>
                                      Showing{' '}
                                      {(categoryPagination.currentPage - 1) *
                                        categoryPagination.itemsPerPage +
                                        1}{' '}
                                      -{' '}
                                      {Math.min(
                                        categoryPagination.currentPage *
                                          categoryPagination.itemsPerPage,
                                        categoryPagination.totalItems,
                                      )}{' '}
                                      of {categoryPagination.totalItems}
                                    </>
                                  ) : (
                                    'No items to show'
                                  )}
                                </span>

                                <span className='text-sm underline underline-offset-4 text-gray-500 ml-3'>
                                  {mainCategory === 'other'
                                    ? 'Max 10 documents can be requested at a time'
                                    : 'Max 5 downloads available at a time'}
                                </span>
                              </div>
                              <div>
                                <button
                                  onClick={() =>
                                    handlePageChange(
                                      `${mainCategory}-${category}`,
                                      categoryPagination.currentPage - 1,
                                    )
                                  }
                                  className={classNames(
                                    'mx-1 px-4 py-2 text-sm font-medium rounded-md border border-gray-300 focus:outline-none',
                                    {
                                      'bg-gray-100 text-gray-400 cursor-not-allowed':
                                        categoryPagination.currentPage === 1,
                                      'bg-white text-gray-700 hover:bg-gray-50':
                                        categoryPagination.currentPage > 1,
                                    },
                                  )}
                                  disabled={categoryPagination.currentPage === 1}
                                >
                                  Previous
                                </button>
                                <button
                                  onClick={() =>
                                    handlePageChange(
                                      `${mainCategory}-${category}`,
                                      categoryPagination.currentPage + 1,
                                    )
                                  }
                                  className={classNames(
                                    'mx-1 px-4 py-2 text-sm font-medium rounded-md border border-gray-300 focus:outline-none',
                                    {
                                      'bg-gray-100 text-gray-400 cursor-not-allowed':
                                        categoryPagination.currentPage >=
                                        Math.ceil(
                                          categoryPagination.totalItems /
                                            categoryPagination.itemsPerPage,
                                        ),
                                      'bg-white text-gray-700 hover:bg-gray-50':
                                        categoryPagination.currentPage <
                                        Math.ceil(
                                          categoryPagination.totalItems /
                                            categoryPagination.itemsPerPage,
                                        ),
                                    },
                                  )}
                                  disabled={
                                    categoryPagination.currentPage >=
                                    Math.ceil(
                                      categoryPagination.totalItems /
                                        categoryPagination.itemsPerPage,
                                    )
                                  }
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </Accordion>
                      );
                    })}
                  </div>
                </Accordion>
              );
            })}
          </>
        ) : isLoading ? (
          <div className='w-[93.8vw]'>
            <Loading />
          </div>
        ) : isError ? (
          <div className='w-[93.8vw] mx-[1px] '>
            <ErrorMessage message='Documents data not available for the company' />
          </div>
        ) : null}
      </div>
      <DocumentPreviewModal
        isOpen={isDocumentPreviewOpen}
        onClose={() => setIsDocumentPreviewOpen(false)}
        url={previewUrl}
        title='Document Preview'
      />
    </>
  );
};

export default DocumentsTab;
